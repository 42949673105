import { Component, OnInit, Input } from '@angular/core';
import { DemurrageCalculation } from '../../_models/demurragecalculation';
import { Export } from '../../_models/export';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: "app-demurrage-worksheet-modal",
  templateUrl: "./demurrage-worksheet-modal.component.html",
  styleUrls: ["./demurrage-worksheet-modal.component.css"]
})
export class DemurrageWorksheetModalComponent implements OnInit {
  @Input() returnedDemurrageModel: any;
  @Input() export: Export ;

  demurrageObject: DemurrageCalculation;
  exportObject: Export;
  
  constructor() {}

  ngOnInit() {
    this.demurrageObject = this.returnedDemurrageModel.result;
    this.exportObject = this.returnedDemurrageModel.result.export;
    console.log(this.demurrageObject);
    // console.log(this.export);
  }

  public captureScreen() {
      const data = document.getElementById('contentToConvert');
      html2canvas(data).then(canvas => {
      // Few necessary setting options
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      const pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      const position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      pdf.save('MYPdf.pdf'); // Generated PDF
    });
  }
}
