<div class="list-group">
  <a href="#" class="list-group-item list-group-item-action active">
  </a>

  <a *ngFor="let en of exportNodes" class="list-group-item list-group-item-action disabled">{{en['nodeElementId']}}: {{ en['nodeElements'].title}}
    <span class="badge"
    [class.badge-info] = "en['nodeStatus'].name == 'IN-PROGRESS'"
    [class.badge-success] = "en['nodeStatus'].name == 'COMPLETED'"
    [class.badge-danger] = "en['nodeStatus'].name == 'NOT STARTED'"
    [class.badge-primary] = "en['nodeStatus'].name == 'ON HOLD'"
    [class.badge-info] = "en['nodeStatus'].name == 'ENTERNET BOTTLENECK'"
    [class.badge-dark] = "en['nodeStatus'].name == 'INTERNAL BOTTLENECK'"
    > {{en['nodeStatus'].name}}</span>
    <small> {{ en['users'].username}}</small>
  </a>
</div>
