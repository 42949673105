<div>
  <div class="row">
    <div class="col-md-4">
      <label>Duration</label>
      <input type="text" class="form-control" name="duration" [(ngModel)]="duration" autofocus id="durationInput">
      <label>Forecast Production</label>
      <input type="text" class="form-control"[(ngModel)]="value" name="value" id="valueInput" (keyup.enter)="addToArray()">
      <button class="btn btn-primary mt-2" (click)="addToArray()">Add</button>
    </div>
    <div class="col-md-8">
      <div class="alert alert-dismissible alert-info" *ngFor="let forecast of forecastProductionArray">
        <button type="button" class="close" data-dismiss="alert">&times;</button>
        <strong>{{forecast.duration}}</strong> This
        <a href="#" class="alert-link">{{forecast.value | number}}</a>
      </div>
    </div>
  </div>
</div>
