import { Component, OnInit, TemplateRef, Output, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { ExportService } from '../_services/export.service';
import { AlertifyService } from '../_services/alertify.service';
import { Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { Lifter } from '../_models/lifter';
import { CargoType } from '../_models/cargotype';
import { Consignee } from '../_models/consignee';
import { Destination } from '../_models/destination';
import { Inspector } from '../_models/inspector';
import { UntypedFormGroup } from '@angular/forms';
import { Terminal } from '../_models/terminal';
import { Product } from '../_models/product';
import { VesselAgent } from '../_models/vesselagent';
import { Consignor } from '../_models/consignor';
import { Vessel } from '../_models/Vessel';
import { DateService } from '../_services/date.service';

@Component({
  selector: 'app-export-create',
  templateUrl: './export-create.component.html',
  styleUrls: ['./export-create.component.css']
})
export class ExportCreateComponent implements OnInit {

  @Output() closeCreateExportForm = new EventEmitter();

  modalRef: BsModalRef;
  addExportForm: UntypedFormGroup;

  terminals: Terminal[];
  products: Product[];
  productCombinations: Product[];
  lifters: Lifter[];
  cargotypes: CargoType[];
  consignees: Consignee[];
  // Data
  model: any = {};
  modelForExportData: any = {};
  vessel: any = {};
  inspector: any = {};
  userId: number;
  vessels: any[];
  vesselList: string[] = ['None'];
  destinationList: any = {};
  InspectorList: any = {};
  vesselAgentList: any = {};
  consignorsList: any = {};
  // vesselagents: any = {};
  selectedValue: string;
  selectedOption: any;

  selectedDestinationValue: string;
  selectedDestinationOption: any;

  selectedInspectorValue: string;
  selectedInspectorOption: any;

  hasDestination = false;
  hasVessel = false;
  hasInspector = false;

  isCreateMode = false;

  // Vessel Model
  public vesselToAddModel = {
    name: null,
    dwt: null,
    flag: null,
    IMONumber:null
  };
  public inspeectorToAddModel = {
    name: null
  };
  public vesselAgentToAddModel = {
    name: null
  };
  public consigneeToAddModel = {
    name: null,
    terminalId: null
  };
  consignorToAddModel: any = {};
  destinationToAddModel: any = {};

  productForSelectedTerminal: Product[];
  //Feature-1
  showNomintationInfo: boolean;
  showProductCom : Boolean;

  constructor(
    private modalService: BsModalService,
    private exportService: ExportService,
    private alertify: AlertifyService,
    private route: Router,
    private authService: AuthService,
    private dateService: DateService
  ) { }

  ngOnInit() {
    this.loadTerminals();
    this.loadProducts();
    this.loadProductCombination();
    this.loadLifters();
    this.loadCargoTypes();
    this.loadConsignees();
    this.loadVessels();
    this.loadDestinations();
    this.loadInspectors();
    this.loadVesselAgents();
    this.loadConsignor();
  }
  createModeToggle() {
    this.isCreateMode = !this.isCreateMode;
  }
  loadTerminals() {
    this.exportService.getTerminals().subscribe(
      (terminals: Terminal[]) => {
        this.terminals = terminals;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadProducts() {
    this.exportService.getProducts().subscribe(
      (products: Product[]) => {
        this.products = products;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadProductCombination() {
    this.exportService.getProductCombination().subscribe(
      (products: Product[]) => {
      
        this.productCombinations = products;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadVessels() {
    this.exportService.getVessels().subscribe(
      (vessels: Vessel[]) => {
        this.vessels = vessels;

        for (let i = 0; i < this.vessels.length; i++) {
          this.vesselList.push(
            String(this.vessels[i].name) +
            ' - ' +
            String(this.vessels[i].dwt) +
            ' - ' +
            String(this.vessels[i].flag)
          );
        }
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadLifters() {
    this.exportService.getLifters().subscribe(
      (lifters: Lifter[]) => {
        this.lifters = lifters;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadLiftersForTerminal(id) {
    this.exportService.getLiftersForTerminal(id).subscribe(
      (lifters: Lifter[]) => {
        this.lifters = lifters;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadCargoTypes() {
    this.exportService.getCargoTypes().subscribe(
      (cargotypes: CargoType[]) => {
        this.cargotypes = cargotypes;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadCargoTypesForLifter(id) {
    this.exportService.getCargoTypeForLifer(id).subscribe(
      (cargotypes: CargoType[]) => {
        this.cargotypes = cargotypes;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadConsignees() {
    this.exportService.getConsignee().subscribe(
      (consignees: Consignee[]) => {
        this.consignees = consignees;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadDestinations() {
    this.exportService.getDestinations().subscribe(
      (destinations: Destination[]) => {
        this.destinationList = destinations;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadInspectors() {
    this.exportService.getInspectors().subscribe(
      (inspectors: Inspector[]) => {
        this.InspectorList = inspectors;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadVesselAgents() {
    this.exportService.getVesselAgents().subscribe(
      (vesselAgent: VesselAgent[]) => {
        this.vesselAgentList = vesselAgent;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadConsignor() {
    this.exportService.getConsignor().subscribe(
      (consignors: Consignor[]) => {
        this.consignorsList = consignors;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  closeModal() {
    this.modalRef.hide();
    this.modalRef = null;
  }
  // Method on the confirm modal to confirm if export data should be added
  confirmSave() {
    this.userId = this.authService.decodedToken.nameid;

    this.alertify.confirm('Are You sure', () => {
      this.tranformExportData(); // Trasform the export data for api

  //VALIDATION: 
  // let isMatch = this.model.nominatedVolume.value.includes('.');//new RegExp('(?:^|\\s)'+'.', 'i').test(this.model.nominatedVolume);
  // console.log("nominatedVal",isMatch);
    if(/\./.test(this.model.nominatedVolume)) 
    {
     if(this.model.terminalID == 5 && this.model.productID == 7 || this.model.productID ==8)
     {
     
     }else{
       this.alertify.error('Nomination volmue does not accept decimal points for the selected terminal.');
      return;
     }
    }


console.log("respo",this.modelForExportData);
      this.exportService
        .createExport(this.modelForExportData, this.userId)
        .subscribe(
          () => {
            this.alertify.success('Export Successfully Created');
          },
          error => {
            this.alertify.error('Server Error Unable to Create Export');
          }
        );

      // this.route.navigate(["/dash-board"]);
    });
  }
  // Method called on clicking the submit button of the addExporForm
  addExport() {
    this.confirmSave();
  }
  tranformExportData() {
    this.modelForExportData.cargoNumber = this.model.cargoNumber;
    this.modelForExportData.terminalsId = this.model.terminalID;
    this.modelForExportData.productsId = this.model.productID;
    this.modelForExportData.liftersId = this.model.lifterID;
    this.modelForExportData.norminatedCargo = this.model.nominatedVolume;

    this.modelForExportData.productCombinationId = this.model.productCombinationId;
    this.modelForExportData.transmixVolume = this.model.transmixVolume;

    // this.modelForExportData.destinationid = 1; // Set the Desination id to detail of TBD
    this.modelForExportData.vesselAgentid = 1; // Set the vessel Agent to TBN
    this.modelForExportData.comment = this.model.comment;
    // this.modelForExportData.vessels = this.model.vesselName;
    // this.modelForExportData.vessels.flag = this.model.flagOfVessel;
    // this.modelForExportData.vessels.dwt = this.model.DWTofVessel;
    this.modelForExportData.cargoTypeID = this.model.cargoTypeID;
    // this.modelForExportData.dateRangeStart = this.model.dateRange[0];
    this.modelForExportData.dateRangeStart = this.dateService.urlDate(this.model.dateRange[0]);
    // this.modelForExportData.dateRangeEnd = this.model.dateRange[1];
    this.modelForExportData.dateRangeEnd = this.dateService.urlDate(this.model.dateRange[1]);
    // this.modelForExportData.eta = this.model.ETA;
    this.modelForExportData.eta = this.dateService.urlDate(this.model.ETA);
    this.modelForExportData.consigneeId = this.model.consigneeID;
    this.modelForExportData.usersId = this.authService.decodedToken.nameid;
    // this.modelForExportData.inspectorId = this.model.inspectorId;
    // this.modelForExportData.consigneeId = this.model.consigneeId;
    // if (!this.hasDestination) {
    //   this.modelForExportData.destination = this.model.destination;
    // }
    this.modelForExportData.consigneeID = this.model.consigneeID;

    // if (!this.hasVessel) {
    //   this.vessel.name = this.model.vesselName;
    //   this.vessel.flag = this.model.flagOfVessel;
    //   this.vessel.dwt = this.model.DWTofVessel;
    //   this.modelForExportData.vessel = this.vessel;
    // }

    // if (!this.hasInspector) {
    //   this.inspector.name = this.model.inspector;
    //   // this.vesselagent.name = this.model.vesselagent;
    //   this.modelForExportData.inspector = this.inspector;
    // }

    // this.vesselagents.name = this.model.vesselAgents; // Get details from form field assign to as object
    // this.modelForExportData.vesselagent = this.vesselagents; // Add Object to form to be sent to API

  }

  onSelectVessel(event: TypeaheadMatch): void {
    this.selectedOption = event.item;
    this.vessel = this.selectedOption;
    this.modelForExportData.vesselId = this.vessel.id;
    this.model.flagOfVessel = this.vessel.flag;
    this.model.DWTofVessel = this.vessel.dwt;
    this.hasVessel = true;
  }
  onSelectDestination(event: TypeaheadMatch): void {
    this.modelForExportData.destinationid = event.item.id;
    this.hasDestination = true;
  }
  onSelectInspector(event: TypeaheadMatch): void {
    this.modelForExportData.inspectorId = event.item.id;
    this.hasInspector = true;
  }
  onSelectConsignor(event: TypeaheadMatch): void {
    this.modelForExportData.consignorId = event.item.id;
  }

  onSelectProduct(e) {
    this.onProductChange();
    if (e == 3) {
      this.model.cargoTypeID = 1;
    }
    // Diesel Export
    if (e == 4) {
      this.model.nominatedVolume = 310000;
    }
    if (e == 5) {
      this.model.nominatedVolume = 420000;
    }
    if (e == 6) {
      this.model.nominatedVolume = 250000;
    }
  }
//Feature -1
onProductChange()
{
    this.showNomintationInfo = this.model.productID == 7 || this.model.productID  == 8 ? true : false;
}
  onTerminalChange(e) {

    this.loadProductForTerminal(this.model.terminalID);
    this.showNomintationInfo =false; //FEATURE 1
    this.showProductCom =false; //FEATURE 2
    if (this.model.terminalID == 2) {
      this.model.cargoNumber = 'AGB-';
      this.model.nominatedVolume = 975000;
      this.model.productID = 1;
      this.model.lifterID = 1;
      this.model.flagOfVessel = "TBA";
      this.model.DWTofVessel = "TBA";
      // this.model.cargoTypeID = 2;
      this.loadCargoTypesForLifter(this.model.lifterID);
    }
    if (this.model.terminalID == 3) {
      this.model.cargoNumber = "ESC-";
      this.model.nominatedVolume = 9500000;
      this.model.productID = 2;
      this.model.lifterID = 1;
      // this.model.cargoTypeID = 1;
      this.loadCargoTypesForLifter(this.model.lifterID);
    }
    if (this.model.terminalID == 4) {
      this.model.cargoNumber = "EP-";
      this.model.nominatedVolume = 440000;
      // this.model.cargoTypeID = 8;
      this.model.lifterID = 11;
      this.model.productID = 4;
      this.loadCargoTypesForLifter(this.model.lifterID);
      this.showProductCom =true;
    }
    if (this.model.terminalID == 1) {
      this.model.cargoNumber = "USAN-000";
      this.model.nominatedVolume = 1000000;
      // this.model.cargoTypeID = 16;
      this.model.lifterID = 10;
      this.model.productID = 3;
      this.loadCargoTypesForLifter(this.model.lifterID);
    }
    if (this.model.terminalID == 5) {
      this.model.cargoNumber = "LPG-";
      this.model.nominatedVolume = 30000;
      this.model.productID = 7;
      this.model.lifterID = 13;
      // this.model.cargoTypeID = 16;
      this.loadCargoTypesForLifter(this.model.lifterID);

      //FEATURE - 1
      this.showNomintationInfo = true;
    }
    this.loadLiftersForTerminal(e);
  }



  loadProductForTerminal(id) {
    this.exportService.getProductsForTerminal(id).subscribe(
      (products: Product[]) => {
        this.products = products;
      },
      error => {
        this.alertify.error('Unable to Load Products For Terminal');
      }
    );
  }
  onSelectLifter(e) {
    this.loadCargoTypesForLifter(e);
  }

  addNewVessel() {
    this.exportService.createVessel(this.vesselToAddModel).subscribe(
      () => {
        this.alertify.success('New Vessel Created');
        this.loadVessels();
        this.modalRef.hide();
      },
      error => {
        this.alertify.error('Unable to Create Vessel or Vessel already exist');
      }
    );
    // this.ngOnInit();
  }

  addNewInspector() {
    this.exportService.createInspector(this.inspeectorToAddModel).subscribe(
      () => {
        this.loadInspectors();
        this.alertify.success('New Inspector Created');
        this.modalRef.hide();
      },
      error => {
        this.alertify.error('Unable to Create Inspector or inspector already exist');
      }
    );
  }

  addNewVesselAgent() {
    this.exportService.createVesselAgent(this.vesselAgentToAddModel).subscribe(
      () => {
        this.loadVesselAgents();
        this.alertify.success('New Vessel Agent Created');
        this.modalRef.hide();
      },
      error => {
        this.alertify.error('Unable to Create Vessel Agent or vessel Agent already exist');
      }
    );
  }
  addNewConsignee() {
    this.exportService.createConsignee(this.consigneeToAddModel).subscribe(
      () => {
        this.loadConsignees();
        this.alertify.success('New Consignee Created');
        this.modalRef.hide();
      },
      error => {
        this.alertify.error('Unable to Create Consignee or Consignee already exist');
      }
    );
  }
  addNewConsignor() {
    this.exportService.createConsignor(this.consignorToAddModel).subscribe(
      () => {
        this.loadConsignor();
        this.alertify.success('New Consignor Created');
        this.modalRef.hide();
      },
      error => {
        this.alertify.error('Unable to Create Consignor or Consignor already exist');
      }
    );
  }
  addNewDestination() {
    this.exportService.createDestination(this.destinationToAddModel).subscribe(
      () => {
        this.loadDestinations();
        this.alertify.success('New Destinartion Created');
        this.modalRef.hide();
      },
      error => {
        this.alertify.error('Unable to Create Destination OR desination already exist');
      }
    );
  }

  cancel() {
    this.closeCreateExportForm.emit(false);
  }

  //Nomination volume valiations
 //Restrict input to numbers and a single decimal points 
  onNominationValPress(event: KeyboardEvent)
  {
    if(this.model.terminalID ==5 && this.model.productID == 7 || this.model.productID == 8)
    {
      this.DecimalPressValidations(event);
      
    }
    else if(this.model.terminalID ==4)
    {
      this.DecimalPressValidations(event);
    }
  }

//Restrict the number of decimal places to 3
  onNomibationInput(event: any)
  {
    if(this.model.terminalID ==5 &&  this.model.productID == 7 || this.model.productID == 8)
      {
       this.DecimalInputValidations(event);
      }
      else if(this.model.terminalID ==4)
      {
        this.DecimalInputValidations(event);
      }
  }
  //DECIMAL POINTS VALIDTIONS
  DecimalPressValidations(event)
{
  const allowedKeys = ['1','2','3','4','5','6','7','8','9','0','.'];
      if(!allowedKeys.includes(event.key))
      {
        event.preventDefault();
      }
      //prevent more then one decimal point
      const  inputElement = event.target as HTMLInputElement;
      if(event.key ==='.' && inputElement.value.includes('.'))
      {
        event.preventDefault();
      }
}
  DecimalInputValidations(event)
  {
    const  inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    //check if the num has more than 3 decimal places
    const decimalMatch = value.match(/^\d+(\.\d{0,3})?/);
    if(decimalMatch)
    {
      inputElement.value =decimalMatch[0]; //set value with only up to 3 decimal places
    }
  }
}
