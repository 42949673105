import { Component, OnInit, TemplateRef, Output, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Terminal } from '../_models/terminal';
import { ExportService } from '../_services/export.service';
import { AlertifyService } from '../_services/alertify.service';
import { Product } from '../_models/product';
import { Lifter } from '../_models/lifter';
import { CargoType } from '../_models/cargotype';
import { Consignee } from '../_models/consignee';
import { Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { Export } from '../_models/export';
import { AuthService } from '../_services/auth.service';
import { Vessel } from '../_models/Vessel';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { Destination } from '../_models/destination';
import { Inspector } from '../_models/inspector';


@Component({
  selector: "app-export-menu-nav",
  templateUrl: "./export-menu-nav.component.html",
  styleUrls: ["./export-menu-nav.component.css"]
})
export class ExportMenuNavComponent implements OnInit {

  @Output() enableCreate = new EventEmitter();

  modalRef: BsModalRef;
  addExportForm: UntypedFormGroup;

  terminals: Terminal[];
  products: Product[];
  lifters: Lifter[];
  cargotypes: CargoType[];
  consignees: Consignee[];
  // Data
  model: any = {};
  modelForExportData: any = {};
  vessel: any = {};
  inspector: any = {};
  userId: number;
  vessels: any[];
  vesselList: string[] = ["None"];
  destinationList: any = {};
  InspectorList: any = {};
  // vesselagents: any = {};
  selectedValue: string;
  selectedOption: any;

  selectedDestinationValue: string;
  selectedDestinationOption: any;

  selectedInspectorValue: string;
  selectedInspectorOption: any;

  hasDestination = false;
  hasVessel = false;
  hasInspector = false;

  constructor(
    private modalService: BsModalService,
    private exportService: ExportService,
    private alertify: AlertifyService,
    private route: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.loadTerminals();
    this.loadProducts();
    this.loadLifters();
    this.loadCargoTypes();
    this.loadConsignees();
    this.loadVessels();
    this.loadDestinations();
    this.loadInspectors();
  }
  loadTerminals() {
    this.exportService.getTerminals().subscribe(
      (terminals: Terminal[]) => {
        this.terminals = terminals;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadProducts() {
    this.exportService.getProducts().subscribe(
      (products: Product[]) => {
        this.products = products;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadVessels() {
    this.exportService.getVessels().subscribe(
      (vessels: Vessel[]) => {
        this.vessels = vessels;

        for (let i = 0; i < this.vessels.length; i++) {
          this.vesselList.push(
            String(this.vessels[i].name) +
              " - " +
              String(this.vessels[i].dwt) +
              " - " +
              String(this.vessels[i].flag)
          );
        }
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadLifters() {
    this.exportService.getLifters().subscribe(
      (lifters: Lifter[]) => {
        this.lifters = lifters;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadCargoTypes() {
    this.exportService.getCargoTypes().subscribe(
      (cargotypes: CargoType[]) => {
        this.cargotypes = cargotypes;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadConsignees() {
    this.exportService.getConsignee().subscribe(
      (consignees: Consignee[]) => {
        this.consignees = consignees;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadDestinations() {
    this.exportService.getDestinations().subscribe(
      (destinations: Destination[]) => {
        this.destinationList = destinations;
        // console.log(this.destinationList);
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadInspectors() {
    this.exportService.getInspectors().subscribe(
      (inspectors: Inspector[]) => {
        this.InspectorList = inspectors;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  closeModal() {
    this.modalRef.hide();
    this.modalRef = null;
  }
  // Method on the confirm modal to confirm if export data should be added
  confirmSave() {
    this.userId = this.authService.decodedToken.nameid;

    this.alertify.confirm("Are You sure", () => {
      this.tranformExportData(); // Trasform the export data for api

      this.exportService
        .createExport(this.modelForExportData, this.userId)
        .subscribe(
          () => {
            this.alertify.success("Export Successfully Created");
          },
          error => {
            this.alertify.error("Server Error Unable to Create Export");
          }
        );

      // this.route.navigate(["/dash-board"]);
    });
  }
  // Method called on clicking the submit button of the addExporForm
  addExport() {
    this.confirmSave();
  }
  tranformExportData() {
    this.modelForExportData.cargoNumber = this.model.cargoNumber;
    this.modelForExportData.terminalsId = this.model.terminalID;
    this.modelForExportData.productsId = this.model.productID;
    this.modelForExportData.liftersId = this.model.lifterID;
    this.modelForExportData.norminatedCargo = this.model.nominatedVolume;
    // this.modelForExportData.destinationid = 1; // Set the Desination id to detail of TBD
    this.modelForExportData.vesselAgentid = 1; // Set the vessel Agent to TBN
    this.modelForExportData.comment = this.model.comment;
    // this.modelForExportData.vessels = this.model.vesselName;
    // this.modelForExportData.vessels.flag = this.model.flagOfVessel;
    // this.modelForExportData.vessels.dwt = this.model.DWTofVessel;
    this.modelForExportData.cargoTypeID = this.model.cargoTypeID;
    this.modelForExportData.dateRangeStart = this.model.dateRange[0];
    this.modelForExportData.dateRangeEnd = this.model.dateRange[1];
    this.modelForExportData.eta = this.model.ETA;
    if (!this.hasDestination) {
      this.modelForExportData.destination = this.model.destination;
    }
    this.modelForExportData.consigneeID = this.model.consigneeID;

    if (!this.hasVessel) {
      this.vessel.name = this.model.vesselName;
      this.vessel.flag = this.model.flagOfVessel;
      this.vessel.dwt = this.model.DWTofVessel;
      this.modelForExportData.vessel = this.vessel;
    }

    if (!this.hasInspector) {
      this.inspector.name = this.model.inspector;
      // this.vesselagent.name = this.model.vesselagent;
      this.modelForExportData.inspector = this.inspector;
    }


    // this.vesselagents.name = this.model.vesselAgents; // Get details from form field assign to as object
    // this.modelForExportData.vesselagent = this.vesselagents; // Add Object to form to be sent to API

    console.log(this.modelForExportData);
  }

  onSelectVessel(event: TypeaheadMatch): void {
    this.selectedOption = event.item;
    this.vessel = this.selectedOption;
    console.log(this.vessel);
    this.modelForExportData.vesselId = this.vessel.id;
    this.model.flagOfVessel = this.vessel.flag;
    this.model.DWTofVessel = this.vessel.dwt;
    this.hasVessel = true;
    // console.log(this.modelForExportData.vessel);
  }
  onSelectDestination(event: TypeaheadMatch): void {
    this.selectedDestinationOption = event.item;
    this.modelForExportData.destinationid = this.selectedDestinationOption.id;
    this.hasDestination = true;
    // console.log(this.modelForExportData.destinationid);
  }
  onSelectInspector(event: TypeaheadMatch): void {
    this.selectedInspectorOption = event.item;
    this.modelForExportData.inspectorId = this.selectedInspectorOption.id;
    this.hasInspector = true;
  }

  updateValues() {
    // console.log(this.model.terminalID);

    if (this.model.terminalID == 2) {
      this.model.cargoNumber = "AGB-";
      this.model.nominatedVolume = 975000;
      this.model.productID = 1;
      this.model.flagOfVessel = "TBA";
      this.model.DWTofVessel = "TBA";
    }
    if (this.model.terminalID == 3) {
      this.model.cargoNumber = "ESC-";
      this.model.nominatedVolume = 9500000;
    }
    if (this.model.terminalID == 4) {
      this.model.cargoNumber = "EP-";
      this.model.nominatedVolume = 440000;
    }
  }
}
