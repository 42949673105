<div class="row mt-2">
  <div *ngFor="let terminal of terminals" class="col-md-2">
    <div class="list-group">
      <button type="button" class="btn btn-outline-info mt-1" (click)="onSelectTerminal(terminal)">{{terminal.name | uppercase}}</button>
    </div>
  </div>
</div>
<hr>
<div ng-if="hasOML" class="row mt-2">
  <div *ngFor="let oml of OMLs" class="col-md-2">
    <div class="list-group">
      <button type="button" class="btn btn-outline-danger mt-1" (click)="onSelectOML(oml)">{{oml.name | uppercase}}</button>
    </div>
  </div>
</div>
<hr>
<div ng-if="hasOML" class="row mt-2">
  <div *ngFor="let lifter of lifters" class="col-md-2">
    <div class="list-group">
      <button type="button" class="btn btn-outline-primary mt-1" (click)="onSelectLifter(lifter)">{{lifter.name | uppercase}}</button>
    </div>
  </div>
</div>
<hr>

<div class="row mt-2">
  <!-- <div class="form-group col-md-4">
    <label for="inputLifter">Select Lifter</label>
    <select class="form-control" id="inputLifter" name="lifterID" [(ngModel)]="lifterID" required>
      <option *ngFor="let lifter of lifters" value="{{lifter.id}}">{{lifter.name}}</option>
    </select>
  </div> -->
  <div class="col-md-8">
    <div class="row">
      <div class="col-md-6">
        <label>Allocation Date</label>
        <input type="text" class="form-control" [(ngModel)]="allocationDate" [disabled]="!hasLifter" name="allocationDate" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}"
          bsDatepicker required>
      </div>
      <div class="col-md-6">
        <label>Allocation Percent</label>
        <input type="text" class="form-control" [(ngModel)]="allocationPercent" name="allocationPercent" [disabled]="!hasLifter">
      </div>
    </div>
    <div class="row mt-2 ml-1">
      <button [disabled]="!hasLifter" class="btn btn-outline-primary" (click)="saveAllocation()">Save</button>
    </div>
  </div>
  <div class="col-md-4">
    <ul class="list-group">
      <li *ngFor="let lifterEquity of omlLifterEquities" class="list-group-item d-flex justify-content-between align-items-center">
         {{ lifterEquity.allocationPercent}}
        <span class="badge badge-primary badge-pill">{{lifterEquity.allocationPeriod | date : 'MMM-yyy'}}</span>
      </li>
    </ul>
  </div>
</div>
