import { Component, OnInit, Input } from '@angular/core';
import { Export } from '../_models/export';
import { ExportService } from '../_services/export.service';
import { AlertifyService } from '../_services/alertify.service';
import { Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
@Component({
  selector: 'app-export-card',
  templateUrl: './export-card.component.html',
  styleUrls: ['./export-card.component.css']
})
export class ExportCardComponent implements OnInit {
  @Input() export: Export;
  loginUserId: number;
  isAdmin: boolean;  

  constructor(
    private exportService: ExportService,
    private alertify: AlertifyService,
    private route: Router,
    private authService: AuthService,
   
  ) { }

  ngOnInit() {

    this.loginUserId = this.authService.decodedToken.nameid;
    this.isAdmin = this.authService.decodedToken.role === 'Admin' ? true : false;

  }
  OpenConfirm(id : number)
  {
    this.alertify.confirm('Are You sure you want to delete the selected export?', () => {
     
      console.log("test",id);
      this.exportService.deleteExport(id)
      .subscribe(
        () => {
          this.alertify.success('Export Deleted Successfully');
          this.route.navigate(["/export"]);
        },
        error => {
          this.alertify.error('Server Error Unable to Delete Export');
        }
      );

    });

    const okButton = document.querySelector('.ajs-ok');
    const cancelButton = document.querySelector('.ajs-cancel');
    if(okButton)
    {
      okButton.setAttribute('style','background-color:limegreen; color: white; border-radius:5px;')
      cancelButton.setAttribute('style','background-color:white; color: black;border-radius:5px;')
    }
  }
}
