import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ExportService } from '../../_services/export.service';
import { AlertifyService } from '../../_services/alertify.service';
import { Router } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import { Demurrage } from '../../_models/demurrage';
import { DemurrageStatus } from '../../_models/demurragestatus';
import { Terminal } from 'src/app/_models/terminal';
import { Product } from 'src/app/_models/product';
import { Lifter } from 'src/app/_models/lifter';
import { CargoType } from 'src/app/_models/cargotype';
import { Vessel } from 'src/app/_models/Vessel';
import { PaginatedResult, Pagination } from 'src/app/_models/pagination';

@Component({
  selector: "app-open-demurrage-list",
  templateUrl: "./open-demurrage-list.component.html",
  styleUrls: ["./open-demurrage-list.component.css"]
})
export class OpenDemurrageListComponent implements OnInit {
  openDemurrages: any[];
  filteredOpenDemurrages: any[];
  today = Date.now();
  modalRef: BsModalRef;
  demurrageStatus: any[];
  selectedStatusId: any;
  selectedDemurrage: any = {};
  statusId = 1;
  model: any;

  terminals: Terminal[];
  products: Product[];
  lifters: Lifter[];
  cargotypes: CargoType[];
  vesselNames: any[];
  dateRange: any;
  enableLoader = true;

  demurrageToUpdate: number;

  // Filter 
  filterModel: {
    statusId: number;
    productName: string;
    lifterName: string;
    terminalName: string;
    vesselName: string
  } = {
      vesselName: '',
      terminalName: '',
      lifterName: '',
      productName: '',
      statusId: 0

    };

  constructor(
    private modalService: BsModalService,
    private exportService: ExportService,
    private alertify: AlertifyService,
    private route: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.pageNumber = 1;
    this.pageSize = 10;
    this.loadOpenDemurrageClaims(this.pageNumber, this.pageSize);
    this.loadOpenDemurrageStatus();
    this.loadVessels();
    this.loadTerminals();
    this.loadLifters();
    this.loadProducts();
  }


  loadOpenDemurrageClaims(pn: any, ps: any) {
    this.exportService.getDemurrages(pn, ps).subscribe(
      (demurrages: PaginatedResult<Demurrage[]>) => {
        this.openDemurrages = demurrages.result;
        this.filteredOpenDemurrages = this.openDemurrages;
        this.pagination = demurrages.pagination;
        this.enableLoader = false;
      },
      (error) => {
        this.alertify.error("Something went wrong loading demurrage claims");
        this.enableLoader = false;
      }
    );
  }

  loadOpenDemurrageStatus() {
    this.exportService.getDemurrageStatus().subscribe(
      (demurrageStatus: DemurrageStatus[]) => {
        this.demurrageStatus = demurrageStatus;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }

  openModal(template: TemplateRef<any>, e: number) {
    this.demurrageToUpdate = e;
    this.modalRef = this.modalService.show(template);
  }
  updateDemurrageStatus() {
    this.exportService
      .updateDemurrageStatus(this.demurrageToUpdate, this.statusId, this.model)
      .subscribe(
        () => {
          this.alertify.success('Demurrage Status Updated');
          this.modalRef.hide();
          this.ngOnInit();
        },
        error => {
          this.alertify.error(error);
        }
      );
  }
  updateActivityStatus() {

  }

  loadVessels() {
    this.exportService.getVessels().subscribe(
      (vessels: Vessel[]) => {
        this.vesselNames = vessels;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  onSelect() {
  }
  loadCargoTypes() {
    this.exportService.getCargoTypes().subscribe(
      (cargotypes: CargoType[]) => {
        this.cargotypes = cargotypes;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadTerminals() {
    this.exportService.getTerminals().subscribe(
      (terminals: Terminal[]) => {
        this.terminals = terminals;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadProducts() {
    this.exportService.getProducts().subscribe(
      (products: Product[]) => {
        this.products = products;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadLifters() {
    this.exportService.getLifters().subscribe(
      (lifters: Lifter[]) => {
        this.lifters = lifters;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  runQuery() {   
    this.reloadOpenDemurragesClaims();
  }

  clearFilters() {

    this.filterModel.terminalName = null;
    this.filterModel.productName = null;
    this.filterModel.lifterName = null;
    this.filterModel.vesselName = null;
    this.filterModel.statusId = null;
    this.loadOpenDemurrageClaims(this.pageNumber, this.pageSize);
  }

  //Pagination Variables
  pagination: Pagination;
  pageNumber: number;
  pageSize: number;
  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this.reloadOpenDemurragesClaims();
  }

  reloadOpenDemurragesClaims() {
    this.exportService.getDemurrages(
      this.pagination.currentPage,
      this.pagination.itemsPerPage,
      this.filterModel.vesselName,
      this.filterModel.terminalName,
      this.filterModel.lifterName,
      this.filterModel.productName,
      this.filterModel.statusId
    ).subscribe(
      (demurrages: PaginatedResult<Demurrage[]>) => {
        this.openDemurrages = demurrages.result;
        this.filteredOpenDemurrages = this.openDemurrages;
        this.pagination = demurrages.pagination;
        this.enableLoader = false;
      },
      (error) => {
        this.alertify.error("Something went wrong loading demurrage open claims");
        this.enableLoader = false;
      }
    );
  }

  isDisabledClearFilter() {
    if ((this.filterModel.lifterName != null && this.filterModel.lifterName != "")
      || (this.filterModel.productName != null && this.filterModel.productName != "")
      || (this.filterModel.terminalName != null && this.filterModel.terminalName != "")
      || (this.filterModel.vesselName != null && this.filterModel.vesselName != "")
      || (this.filterModel.statusId != null && this.filterModel.statusId != 0)) {
      return false;
    }
    return true;
  }

}
