
  <div class="row">
      <div *ngIf="enableLoader">
          <div class="row">
            <div id="loader">
                <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="lading"></div>
            </div>
          </div>
      </div>
  </div>
  <div class="row">
    <div class="d-flex ml-2">
    <button (click)="pullDataFromEC(template)" class="btn btn-block w-100">Pull Data From Energy Component</button>
    </div>
  </div>
  <div class="row ml-2">
    <ul class="list-group list-group-flush">
      <li class="list-group-item">Vessel Name : {{export?.vessel?.name}}</li>
      <li class="list-group-item">IMO Number : {{export?.vessel?.imoNumber}}</li>
      <li class="list-group-item">Vessel Flag : {{export?.vessel?.flag}}</li>
      <li class="list-group-item">Vessel DWT :  {{export?.vessel?.dwt}}</li>
      <li class="list-group-item">Vessel Destination : {{ export?.destinations?.name}}</li>
    </ul>
  </div>

  <ng-template #template>
      <div class="modal-header">
        <h4 class="modal-title pull-left">Energy Component Data Preview</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <form #updateECDataForm="ngForm">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputEmail4">Cargo Number</label>
                  <input type="email" class="form-control" [(ngModel)]="ecDataForm.cargoNumber"
                  name="ecDataForm.cargoNumber" disabled>
                </div>
                <div class="form-group col-md-6">
                  <label >Vessel Name</label>
                  <input type="text" class="form-contro" [(ngModel)]="ecDataForm.carrierName" name="ecDataForm.carrierName" disabled>
                </div>
              </div>
            
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label >BOL Date</label>
                  <input type="text" class="form-control" [(ngModel)]="ecDataForm.blDate" name="ecDataForm.blDate" disabled>
                </div>
                <div class="form-group col-md-6">
                  <label >BOL Volume</label>
                  <input type="text" class="form-control" [(ngModel)]="ecDataForm.netVolumeBBLS" name="ecDataForm.netVolumeBBLS" disabled>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label >Consignee</label>
                  <input type="text" class="form-control" [(ngModel)]="ecDataForm.consigneeName" name="ecDataForm.consigneeName" disabled>
                </div>
                <div class="form-group col-md-12">
                  <label >Consignor</label>
                  <input type="text" class="form-control" [(ngModel)]="ecDataForm.consignorName" name="ecDataForm.consignorName" disabled>
                </div>
              </div>
            </form>
      </div>
      <div class="modal-footer">
          <button type="button" (click)="overwriteData()" class="btn btn-primary">Overwrite Data</button>
          <button type="button" (click)="closeModal()" class="btn btn-secondary">Cancel</button> 
        </div>
    </ng-template>
