<div class="card mt-3">
  <div class="card-header">
    Create New Document Template
  </div>
  <div class="card-body">
    <div class="row">
      <div class="form-group col-md-8">
        <label for="documentTemplateName">Document Name</label>
        <input type="text" [(ngModel)]="documentTemplateName" name="documentTemplateName" class="form-control" placeholder="Enter Document Name" required>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-4">
        <label for="inputETA">Document Template Category</label>
        <select class="form-control" (change)="getDC()" #model.id [(ngModel)]="model.id" name="model.id" required>
          <option *ngFor="let docCat of documentCategoryList" value="{{docCat?.id}}">{{docCat?.name}}</option>
        </select>
      </div>
      <div class="from-group col-md-2 mt-2">
        <button type="button" class="btn btn-outline-primary" (click)="openModal(template)"><i class="fa fa-plus-circle"></i></button>
      </div>
      <div class="form-group col-md-4">
        <label for="inputETA">Terminal</label>
        <select class="form-control" (change)="getDC()" #model.id [(ngModel)]="model.terminalId" name="model.terminalId"
          required>
          <option *ngFor="let terminal of terminals" value="{{terminal?.id}}">{{terminal?.name}}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-12">
        <label for="documentTemplateDescription">Document Template Short Description</label>
        <textarea class="form-control" (blur)="getModelReady()" [(ngModel)]="documentTemplateDescription" name="documentTemplateDescription" id="exampleFormControlTextarea1" rows="3" placeholder="Enter Details"></textarea>
      </div>
    </div>
  </div>

  <div class="row mt-3 mr-2 ml-2">
  
    <div class="col-md-4">
  
      <h6>Add files</h6>
  
      <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)" [uploader]="uploader"
        class="card bg-faded p-3 text-center mb-3 my-drop-zone">
        <i class="fa fa-upload fa-3x"></i>
        Drop Documents Here
      </div>
  
      <!-- Multiple
      <input type="file" ng2FileSelect [uploader]="uploader" multiple />
      <br /> Single
      <input type="file" ng2FileSelect [uploader]="uploader" /> -->
    </div>
  
    <div class="col-md-8" style="margin-bottom: 40px">
  
      <h6>Upload queue</h6>
      <p>Queue length: {{ uploader?.queue?.length }}</p>
  
      <table class="table">
        <thead>
          <tr>
            <th width="50%">Name</th>
            <th>Size</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of uploader.queue">
            <td>
              <strong>{{ item?.file?.name }}</strong>
            </td>
            <td *ngIf="uploader.options.isHTML5" nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
          </tr>
        </tbody>
      </table>
  
      <div>
        <div>
          Queue progress:
          <div class="progress mb-4">
            <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
          </div>
        </div>
        <button type="button" class="btn btn-success btn-s ml-1" (click)="uploader.uploadAll()" [disabled]="!uploader.getNotUploadedItems().length">
          <span class="fa fa-upload"></span> Upload
        </button>
        <button type="button" class="btn btn-warning btn-s ml-1" (click)="uploader.cancelAll()" [disabled]="!uploader.isUploading">
          <span class="fa fa-ban"></span> Cancel
        </button>
        <button type="button" class="btn btn-danger btn-s ml-1" (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length">
          <span class="fa fa-trash"></span> Remove
        </button>
      </div>
  
    </div>
  
  </div>

</div>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">New Document Template</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-row">
      <input type="text" class="form-control" placeholder="Template Name" [(ngModel)]="newDocumentTemplate" name="newDocumentTemplate">
    </div>
    <div class="form-row mt-2">
      <input type="text" class="form-control" placeholder="Template Label" [(ngModel)]="newDocumentTemplateLabel" name="newDocumentTemplateLabel">
    </div>
    <button type="button" class="btn btn-primary mt-2" (click)="addDocumentTemplate()">Save</button>
  </div>
</ng-template>




