import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ExportDetailUpdateComponent } from '../export-detail-update/export-detail-update.component';
@Injectable()
export class PreventUnsavedChangesGuard implements CanDeactivate<ExportDetailUpdateComponent> {
    canDeactivate(component: ExportDetailUpdateComponent) {
        if (component.editExportForm.dirty) {
            return confirm('Are you sure you want to continue. Any unsaved changes would be lost');
        }
        return true;
    }
}