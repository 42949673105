import { Component, OnInit, Input } from '@angular/core';
import { ExportService } from '../_services/export.service';
import { AlertifyService } from '../_services/alertify.service';
import { ActivatedRoute } from '@angular/router';
import { ExportNode } from '../_models/exportnode';
import { Status } from '../_models/status';
import { NodeComment } from '../_models/nodecomment';
import { NodeStatus } from '../_models/nodestatus';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: "app-export-update-activity-comment",
  templateUrl: "./export-update-activity-comment.component.html",
  styleUrls: ["./export-update-activity-comment.component.css"]
})
export class ExportUpdateActivityCommentComponent implements OnInit {
  @Input()
  exportnode: ExportNode;
  statuses: NodeStatus[];
  nodecomment: NodeComment[];
  nodeId: number;
  model: any = {};
  statusId: number;
  exportNodeUpdateModel: any = {};

  constructor(
    private exportService: ExportService,
    private alertify: AlertifyService,
    private route: ActivatedRoute,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.loadStatus();
    setTimeout(() => {
      this.loadNodeComment(this.exportnode.id);
    }, 2000);
  }

  loadStatus() {
    this.exportService.getNodeStatusList().subscribe(
      (statuses: NodeStatus[]) => {
        this.statuses = statuses;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }

  loadNodeComment(en) {
    this.exportService.getNodeComment(en).subscribe(
      (nodecomments: NodeComment[]) => {
        this.nodecomment = nodecomments;
        console.log(this.exportnode);
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  Checking() {
    this.model.userid = this.authService.decodedToken.nameid;
    this.model.exportNodeId = this.exportnode.id;
    this.exportService.createComment(this.model).subscribe(
      () => {
        this.alertify.success('Comment Added');
        this.ngOnInit();
        this.model.comment = '';
      },
      error => {
        this.alertify.error('Server Error Unable to Create Added');
      }
    );
  }

  updateActivityStatus() {
    this.exportNodeUpdateModel.id = this.exportnode.id;
    this.exportNodeUpdateModel.nodeStatusId = this.statusId;
    console.log(this.exportNodeUpdateModel);

    this.exportService
      .updateExportNodeStatus(this.exportNodeUpdateModel)
      .subscribe(
        () => {
          this.alertify.success('Status Successfully Updated');
        },
        error => {
          this.alertify.error('Unable to Activity Status');
        }
      );
  }
}
