import { Injectable } from '@angular/core';
import { Export } from '../_models/export';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { ExportService } from '../_services/export.service';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ExportDetailResolver implements Resolve<Export> {
    constructor(private exportService: ExportService,
        private router: Router, private alertifyService: AlertifyService) {}

    resolve(route: ActivatedRouteSnapshot): Observable <Export> {
        return this.exportService.getExport(route.params['id'])
        .pipe(catchError(error => {
            this.alertifyService.error(error);
            this.router.navigate(['/export']);
            return of(null);
        }));
    }
}