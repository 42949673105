<div class="container mt-5">
  <div *ngIf="!registerMode" style="text-align: center">
    <div class="jumbotron">
      <h3 class="display-3">Lifting Operations Management System</h3>
      <p class="lead">NMA Lifting and Export Opertions Management System</p>
      <hr class="my-4">
      <p>AGBAMI | ESCRAVOS | EGTL | PENNINGTON | LPG | EGP</p>
      <p class="lead">
        <a class="btn btn-outline-primary btn-lg" (click)="registerToggle()" role="button">New User</a>
      </p>
    </div>
  </div>

  <div *ngIf="registerMode" class="container">
    <div class="row justify-content-center">
      <div class="col-4">
        <app-register (cancelRegister)="cancelRegisterMode($event)"></app-register>
      </div>
    </div>
  </div>
</div>
