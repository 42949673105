import { Injectable } from '@angular/core';
import { Export } from '../_models/export';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { ExportService } from '../_services/export.service';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NodeComment } from '../_models/nodecomment';

@Injectable()
export class NodeCommentResolver implements Resolve<NodeComment> {
    constructor(private exportService: ExportService,
        private router: Router, private alertifyService: AlertifyService) {}

    resolve(route: ActivatedRouteSnapshot): Observable <NodeComment> {
        return this.exportService.getExportNode(route.params["id"], route.params["exportId"])
          .pipe(
            catchError(error => {
              this.alertifyService.error(error);
              this.router.navigate(["/export"]);
              return of(null);
            })
          );
    }
}