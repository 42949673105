<div class="container">
  <div class="row mt-3">
    <ul class="nav nav-pills">
      <li class="nav-item mr-2">
        <a class="nav-link active" (click)="openModal(addUser)">
          <i class="fa fa-plus"></i> Create New User</a>
      </li>
      <li class="nav-item mr-2">
        <a class="nav-link active" [routerLink]="['/export/list']">
          <i class="fa fa-list-ul"></i> View Exports as List Export</a>
      </li>
    </ul>
  </div>
  <div *ngIf="enableLoader" class="container">
    <div class="row">
      <div id="loader">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="lading"></div>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <table class="table table-hover" *ngIf="!enableLoader">
      <thead>
        <tr class="table-primary">
          <th scope="col">#</th>
          <th scope="col">User Name</th>
          <th scope="col">Role</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of usersList; index as i">
          <th scope="row">{{i + 1}}</th>
          <td>{{user?.username | uppercase}}</td>
          <td>{{user?.role | uppercase}}</td>
          <td>
            <span class="badge badge-danger badge-pill" (click)="openDeleteModal(confirmationModal, user.id)"><i class="fa fa-trash"></i> Delete</span>    
            <span class="badge badge-info badge-pill" (click)="openEditModal(editUser,user.id)"><i class="fa fa-user"></i> Edit</span>       
          </td>
        </tr>
      </tbody>
    </table>  
  </div>
  <div class="row" *ngIf="!enableLoader">
    <div class="col-xs-12 col-12 pagination-padding">
      <div class="d-flex justify-content-end">
        <pagination [boundaryLinks]="true" [totalItems]="pagination.totalItems" [itemsPerPage]="pagination.itemsPerPage"
          [(ngModel)]="pagination.currentPage" (pageChanged)="pageChanged($event)" previousText="&lsaquo;"
          nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;" [maxSize]="10">
        </pagination>
      </div>
    </div>
  </div>
</div>

<ng-template #addUser>
    <div class="modal-header">
      <h4 class="modal-title pull-left">ADD NEW USER</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form #addVesselForm="ngForm">
      <div class="modal-body">
        <div class="form-row">
          <input type="text" class="form-control" placeholder="UserName" [(ngModel)]="userForm.username"
           name="userForm.username" required>
        </div>
        <!-- <div class="form-row mt-2">
          <select class="form-control" id="inputRole" name="userForm.role" [(ngModel)]="userForm.role" required>
            <option>Select A Role</option>
            <option value="admin">Admin</option>
            <option value="user">User</option>
          </select>
         </div> -->

         <div class="form-row mt-2">
          <!-- <label for="inputProductStream">User Role</label> -->
          <select class="form-control" #userRoles.id="ngModel" name="userRoles.id"
            [(ngModel)]="userForm.role" required="Please select the role">
            <option value="" disabled >Select a role</option>
            <option *ngFor="let userRole of userRoles" value="{{userRole.id}}">{{userRole.name}}</option>
          </select>
        </div>

        <div class="form-row mt-2">
          <input type="password" class="form-control" placeholder="Password" [(ngModel)]="userForm.password"
           name="userForm.password" required>
        </div>
        <div class="form-row mt-2">
          <input type="password" class="form-control" placeholder="Confirm Password" [(ngModel)]="userForm.confirmPassword"
           name="userForm.confirmPassword" required>
        </div>
        <div class="card-footer">
          <div class="btn-group d-flex">
              <button type="button" [disabled]="!addVesselForm.valid" class="btn btn-primary w-100"
              (click)="submitAddUserForm()">Save</button>
              <button type="button"class="btn btn-danger w-100"
              (click)="closeAddUserForm()">Close</button>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #editUser>
    <div class="modal-header">
      <h4 class="modal-title pull-left">EDIT USER</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form #addVesselForm="ngForm">
      <div class="modal-body">
        <div class="form-row">
          <input type="text" class="form-control" placeholder="UserName" [(ngModel)]="userForm.username"
           name="userForm.username" required>
        </div>

         <div class="form-row mt-2">
          <!-- <label for="inputProductStream">User Role</label> -->
          <select class="form-control" #userRoles.id="ngModel" name="userRoles.id"
            [(ngModel)]="userForm.role" required="Please select the role">
            <option value="" disabled >Select a role</option>
            <option *ngFor="let userRole of userRoles" value="{{userRole.id}}">{{userRole.name}}</option>
          </select>
        </div>

        <div class="card-footer">
          <div class="btn-group d-flex">
              <button type="button" [disabled]="!addVesselForm.valid" class="btn btn-primary w-100"
              (click)="submitEditUserForm()">Save</button>
              <button type="button"class="btn btn-danger w-100"
              (click)="closeEditUserForm()">Close</button>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #confirmationModal>
      <div class="modal-header">
        <h4 class="modal-title pull-left">Confirm Delete Action</h4>
      </div>
      <div class="modal-body">
       Are you absolutely sure you want to delete this user<br>
        <button (click)="deleteUser()" type="button" class="btn btn-danger">Delete</button>
      </div>
    </ng-template>
