import { Component, OnInit } from '@angular/core';
import { Export } from '../_models/export';
import { ExportService } from '../_services/export.service';
import { AlertifyService } from '../_services/alertify.service';
import { ActivatedRoute } from '@angular/router';
import { Status } from '../_models/status';
import { ExportNode } from '../_models/exportnode';
import { NodeComment } from '../_models/nodecomment';
import { NodeStatus } from '../_models/nodestatus';

@Component({
  selector: "app-export-actions-list-update-view",
  templateUrl: "./export-actions-list-update-view.component.html",
  styleUrls: ["./export-actions-list-update-view.component.css"]
})
export class ExportActionsListUpdateViewComponent implements OnInit {
  export: Export;
  exportId: number;
  nodeElementId: number;
  nodeStatusId: number;
  exportnode: ExportNode;

  constructor(
    private exportService: ExportService,
    private alertify: AlertifyService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.exportId = this.route.params['_value'].exportId;
    this.nodeElementId = this.route.params['_value'].id;
    this.loadExport();
    this.loadExportNode(this.exportId, this.nodeElementId);
  }

  loadExport() {
    this.exportService.getExport(+this.route.snapshot.params['exportId']).subscribe((exports: Export) => {
      this.export = exports;
    }, error => {
      this.alertify.error(error);
    });
  }

  loadExportNode(eId, neId) {
    this.exportService.getExportNode(eId, neId).subscribe(
      (exportnodes: ExportNode) => {
        this.exportnode = exportnodes;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }

}
