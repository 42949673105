<div class="container">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dash-board']">Financial Home</a>
      </li>
    </ol>
    <ul class="nav nav-pills">
      <li class="nav-item mr-2">
        <a class="nav-link active" [routerLink]="['/financial']" routerLinkActive="router-link-active" >
          <i class="fa fa-plus"></i>Process NESS</a>
      </li>
      <li class="nav-item mr-2">
        <a class="nav-link active" [routerLink]="['/ness-payreq']" routerLinkActive="router-link-active">
          <i class="fa fa-plus"></i> Update PayReq #</a>
      </li>
      <li class="nav-item mr-2">
        <a class="nav-link active" [routerLink]="['/exchange-rate']" routerLinkActive="router-link-active">
          <i class="fa fa-plus"></i> Enter Exchange Rate</a>
      </li>
      <li class="nav-item mr-2">
        <a class="nav-link active" [routerLink]="['/completed-ness']" routerLinkActive="router-link-active" ><i class="fa fa-plus"></i> Completed NESS</a>
      </li>
    </ul>

    <div class="row mt-3">
      <div class="col-md-6">
        <form>
          <div class="form-group">
            <label for="formGroupExampleInput">Select Date</label>
            <input type="text" class="form-control" [(ngModel)]="model.dateCreated" name="model.dateCreated" placeholder="Example input" [bsConfig]="{ dateInputFormat: 'YYYY-MMM' }" bsDatepicker>
          </div>
          <div class="form-group">
            <label for="formGroupExampleInput2">Closing Exchange</label>
            <input type="text" class="form-control" [(ngModel)]="model.rate" name="model.rate" placeholder="305.345">
          </div>
          <button class="btn btn-outline-primary" (click)="addExchangeRate()">Add Exchange</button>
        </form>
      </div>
      <div class="col-md-6">
          <mat-progress-spinner *ngIf="isLoading" mode="indeterminate"></mat-progress-spinner>

        <table *ngIf="!isLoading" class="table table-hover">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Exchange</th>
              <!-- <th scope="col">User</th> -->
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let exchange of exchangeRateArray">
              <td>{{exchange.dateCreated | date:'MMM, yyy'}}</td>
              <td>{{exchange.rate}}</td>
              <!-- <td>{{exchange.user.username}}</td> -->
              <td>
                <button class="btn btn-danger btn-sm" (click)="deleteExchangeRate(exchange.id)"><i class="fa fa-bin"></i> Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
</div>
