import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Tax } from 'src/app/_models/tax';
import { ExportService } from 'src/app/_services/export.service';
import { AlertifyService } from 'src/app/_services/alertify.service';

@Component({
  selector: 'app-financial-home',
  templateUrl: './financial-home.component.html',
  styleUrls: ['./financial-home.component.css']
})
export class FinancialHomeComponent implements OnInit {

  nessMode = true;
  exchangeRateMode = true;
  modalRef: BsModalRef;
  taxArray: Tax[];

  public taxForm = {
    id: null,
    rate: null
  };

  constructor(private modalService: BsModalService,
    private exportService: ExportService,
    private alertify: AlertifyService) { }

  ngOnInit() {
    this.getTaxes();
  }
  toggleNessMode(){
    this.nessMode = !this.nessMode;
  }
  toggleExchangeRateMode(){
    this.exchangeRateMode = !this.exchangeRateMode;
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  getTaxes() {
    this.exportService.getTaxes().subscribe(
      (taxes: Tax[]) => {
        this.taxArray = taxes;
      }, error => {
        this.alertify.error('Unable to Load Tax Details');
      }
    );
  }

  updateTax() {
    this.exportService.updateTaxRate(this.taxForm).subscribe(
      () => {
        this.alertify.success('Tax Rate Updated Successfuly');
      }, error => {
        this.alertify.error('Unable to Update Tax Rate');
      }
    );
  }

}
