<div class="container mt-4">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dash-board']" >Home</a>
    </li>
    <li class="breadcrumb-item">
      <a [routerLink]="['/export']">Export</a>
    </li>
    <li class="breadcrumb-item active">{{export?.vessel['name']}}</li>
  </ol>
  <h1 class="mt-2">{{export?.vessel['name']}}</h1>
  <div class="row">
    <div class="col-sm-4">
        <div class="card">
          <img class="card-img-top img-thumbnail rounded" src="{{export.lifters.lifterPhotoUrl}}" alt="">
          <div class="card-body">
            <div>
                <h5 class="mr-2">{{export?.cargoNumber}}</h5>
            </div>
            <div>
              <strong>Date Range</strong>
              <p>{{export?.dateRangeStart | date:'mediumDate'}} - {{export?.dateRangeEnd | date:'mediumDate'}}</p>
            </div>
            <div>
              <strong>LIFTER</strong>
              <p>{{export?.lifters.name | uppercase}}</p>
            </div>
            <div>
              <strong>ETA</strong>
              <p>{{export?.eta | date:'mediumDate'}}</p>
            </div>
            <div>
            <strong>CURRENT STATUS</strong>
              <p>{{ export?.status.label }}</p>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="btn-group d-flex">
            <button class="btn btn-primary w-100" [routerLink]="['/export/update/',export.id]" [queryParams]="{tab:currentTab}" >Edit</button>
            <button class="btn btn-info w-100" (click)="openModal(confirmDuplicationModal,export?.id)">Duplicate</button>
            <button class="btn btn-danger w-100" [routerLink]="['/export']" >Close</button>
          </div>
        </div>
    </div>
    <div class="col-sm-8">
        <div class="tab-panel">
          <tabset class="member-tabset" #detailTab>
            <tab heading="Details">
              <div class="list-group mt-2">
                <a class="list-group-item list-group-item-action flex-column align-items-start active">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">List group item heading</h5>
                    <small>TERMINAL : {{export?.terminals.name | uppercase}}</small>
                  </div>
                  <p class="mb-1">LIFTER : {{export?.lifters.name}} | {{ export?.cargoTypes != null ? export?.cargoTypes['name'] :''}} | ETA : {{ export?.eta | date}}</p>
                  <small>Nominated Volume: {{export?.norminatedCargo | number}} BBLS</small>
                  <p class="mb-1">DESTINATION : {{export?.destinations.name | uppercase}}</p>
                </a>
              </div>
              <div class="row ml-2 mt-4">
                <h6>Cargo Number : </h6>
                <p class="ml-2">{{export?.cargoNumber}}</p>
                <h6 class="ml-4">Product : </h6>
                <p class="ml-2">{{export?.products.name}}</p>
                <h6 class="ml-4">Cargo Type : </h6>
                <p class="ml-2">{{export?.cargoTypes != null ? export?.cargoTypes['name'] :''}}</p>
              </div>
              <div class="row mt-2">
                <div class="col-sm-6">
                  <div class="card">
                    <div class="card-body">
                      <h6 class="card-title mt-2">Agent Details</h6>
                      <p class="card-text mb-1">{{export?.vesselAgents['name'] | uppercase}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="card">
                    <div class="card-body">
                      <h6 class="card-title mt-2">Inspectors</h6>
                      <p class="card-text mb-1">{{export?.inspector['name'] | uppercase}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ml-2 mt-4">
                  <div class="form-group row">
                      <label  class="col-sm-4 col-form-label">Bill Of Volume :</label>
                      <div class="col-sm-8">
                        <input type="text" readonly class="form-control" value="{{export?.actualCargo | number}} BBL.">
                      </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Bill Of Lading Date :</label>
                        <div class="col-sm-8">
                          <input type="text" readonly class="form-control" value="{{export?.bolDate | date}}">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">NXP Number:</label>
                        <div class="col-sm-8">
                          <input type="text" readonly class="form-control" value="{{export?.nxpNumber}}">
                        </div>
                    </div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-12">
                  <div class="card">
                    <div class="card-body">
                      <h6 class="card-title mt-2">CONSIGNOR</h6>
                      <p class="card-text mb-1">{{export?.consignor.name | uppercase}}</p>
                      <h6 class="card-title mt-2">CONSIGNEE</h6>
                      <p class="card-text mb-1">{{export?.consignees.name | uppercase}}</p>
                      
                    </div>
                  </div>
                </div>
              </div>
              <div class="alert alert-dismissible alert-warning">
                  <button type="button" class="close" data-dismiss="alert">&times;</button>
                  <h5 class="alert-heading">Export Comment</h5>
                  <p class="mb-0">{{export?.comment}}.</p>
              </div>
            </tab>
            <tab heading="Action" (select)="setCurrentTab(1)">
              <app-export-detail-action [export]="export"></app-export-detail-action>
            </tab>
            <tab heading="Vessel Details" (select)="setCurrentTab(2)">
              <app-export-detail-vessel [export]="export"></app-export-detail-vessel>
            </tab>
            <tab heading="Documentation" (select)="setCurrentTab(3)">
               <app-export-detail-document [export]="export"></app-export-detail-document>
            </tab>
            <tab heading="Financial" (select)="setCurrentTab(4)">
              <app-export-detail-financial [export]="export"></app-export-detail-financial>        
            </tab>
            <!-- <tab heading="Others">
              <app-export-detail-analyst [export]="export"></app-export-detail-analyst>
            </tab> -->
          </tabset>
        </div>
        <div class="mt-4">
            <h3><i class="fa fa-file"></i> Generate Documents</h3>
            <p class="text-muted">Click on the button representing the document type you want to generate</p>
            <div class="btn-group d-flex mt-3">
                <button (click)="generateDocumentInstruction(export.lifters.id)"
                 class="btn btn-outline-primary w-100"><i class="fa fa-file"></i> Terminal DI</button>
                 <button (click)="generateDPRNominationDocument(export.lifters.id)"
                 class="btn btn-outline-primary w-100"><i class="fa fa-file"></i> DPR Letter</button>
                <button (click)="generateNominationDocument(export.lifters.id)"
                 class="btn btn-outline-primary w-100"><i class="fa fa-file"></i> DPR Attachement</button>
                 <button (click)="generateNNPCNominationDocument(export.lifters.id)"
                 class="btn btn-outline-primary w-100"><i class="fa fa-file"></i> NNPC Letter</button>
            </div>
            <div class="btn-group d-flex mt-3">
              <button (click)="generateRFIDocument(export.lifters.id)"
               class="btn btn-outline-primary w-100"><i class="fa fa-file"></i> RFI</button>
              <!-- <button class="btn btn-outline-primary w-100"><i class="fa fa-file"></i> NXP</button> -->
            </div>
        </div>
        <div *ngIf="hasFile" class="mt-4">
            <div class="list-group">
                <a class="list-group-item list-group-item-action">
                  <div class="row">
                      <div class="col-sm-2">
                          <img class="pdf-image" src="../../assets/docx.jpg" width="100" height="100" class="rounded">
                      </div>
                      <div class="col-sm-10">
                          <p class="text-muted">Document Instruction has been generated please click download button to download</p>
                          <div class="row">
                            <div class="col-sm-4">
                                <button (click)="downloadFile()" class="btn btn-outline-info w-100"><i class="fa fa-download"></i> Download</button>
                            </div>
                            <div class="col-sm-4">
                                <button class="btn btn-outline-info w-100" disabled><i class="fa fa-envelope"></i> Email Terminal</button>
                            </div>
                            <div class="col-sm-4">
                                <button class="btn btn-outline-info w-100" disabled><i class="fa fa-trash"></i> Delete</button>
                            </div>
                          </div>
                      </div>
                  </div>
                </a>
              </div>
        </div>
    </div>
  </div>
</div>

<ng-template #confirmDuplicationModal>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Action Confirmation</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        <p>Are you sure you want to create a duplicate of this export. By click yes a new export instance would be created with the same details and a ----NEW flag on the Cargo Number./p>
      </div>
      <button type="button" class="btn btn-outline-primary mt-2 ml-3 mr-3" (click)="duplicateExport()">Create Duplicate</button>
      <button type="button" class="btn btn-outline-danger mt-2 mr-3" (click)="closeConfirmModal()">Close</button>
    </div>
  </ng-template>
