import { Component, OnInit, Input } from '@angular/core';
import { Export } from '../../_models/export';
import { AlertifyService } from '../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportService } from '../../_services/export.service';
import { DocumentService } from '../../_services/document.service';
import { saveAs } from 'file-saver';
import { AuthService } from '../../_services/auth.service';

@Component({
  selector: 'app-excess-water-document',
  templateUrl: './excess-water-document.component.html',
  styleUrls: ['./excess-water-document.component.css']
})

export class ExcessWaterDocumentComponent implements OnInit {
  @Input() export: Export;
  @Input() exportId: number;
  userId: number;
  exportDocs: any;
  filterExportDocs: any;

  constructor(private exportService: ExportService, private alertify: AlertifyService,
    private route: ActivatedRoute, private nav: Router, private documentService: DocumentService, private authService: AuthService) { }

  ngOnInit() {
    this.loadExportDocs();
  }

  loadExportDocs() {
    this.exportService.getExportDocuments(this.export != undefined ? this.export?.id : this.exportId).subscribe((documents: Document[]) => {
      this.userId = this.authService.decodedToken.nameid;
      this.filterExportDocs = documents;
      if (this.filterExportDocs != null && this.filterExportDocs != undefined && this.userId != null) {
        this.exportDocs = this.filterExportDocs.filter(x => x.usersId == this.userId);
      }
    }, error => {
      this.alertify.error(error);
    });
  }

  downloadDocument(e: number, fileName: string) {
    this.documentService.download(e).subscribe(
      data => {
        // console.log(fileName);
        // console.log(data);
        // const FileSaver = require('file-saver');
        // FileSaver.saveAs(data, fileName);
        saveAs(data, fileName);
        this.alertify.success('Download Successful');
      },
      error => {
        this.alertify.error('Unable to Download Document');
      }
    );
  }
  downloadAll(documentArrayList: any[]) {
    for (let index = 0; index < documentArrayList.length; index++) {
      this.downloadDocument(documentArrayList[index].id, documentArrayList[index].fileName);
    }
  }

}
