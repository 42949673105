<ol class="breadcrumb">
  <li class="breadcrumb-item">
    <a [routerLink]="['/dash-board']">Home</a>
  </li>
</ol>
<ul class="nav nav-pills">
  <li class="nav-item mr-2">
    <a class="nav-link active" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed">
      <i class="fa fa-plus"></i> AGBAMI LP</a>
  </li>
  <li class="nav-item mr-2">
    <a class="nav-link active">
      <i class="fa fa-list-ul"></i>ESCRAVOS LP</a>
  </li>
</ul>

<hr>
<div id="collapseBasic" [collapse]="isCollapsed" class="card card-block card-body">
  <form #agbamiLPForm="ngForm">
    <div class="row mt-3">
      <div class="col-md-4">
        <div class="form-group">
          <label for="exampleInputPassword1">Average Daily Production</label>
          <input type="number" class="form-control" [(ngModel)]="model.dailyProduction" name="model.dailyProduction" #model.dailyProduction placeholder="Average Daily Production">
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <!-- <label for="exampleInputPassword1">STARDEEP OHB</label> -->
          <!-- <input type="number" class="form-control" id="exampleInputPassword1" placeholder="STARDEEP OHB"> -->
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <!-- <label for="exampleInputPassword1">FAMFA OHB</label>
          <input type="number" class="form-control" id="exampleInputPassword1" placeholder="FAMFA OHB"> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label for="exampleInputPassword1">STARDEEP BOH</label>
          <input type="number" class="form-control" [(ngModel)]="model.starDeepBOH" name="model.starDeepBOH" #model.starDeepBOH placeholder="STARDEEP BOH">
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="exampleInputPassword1">FAMFA BOH</label>
          <input type="number" class="form-control" [(ngModel)]="model.famfaBOH" name="model.famfaBOH" #model.famfaBOH placeholder="FAMFA BOH">
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="exampleInputPassword1">PETROBRAS BOH</label>
          <input type="number" class="form-control" [(ngModel)]="model.petroBrasBOH" name="model.petroBrasBOH" #model.petroBrasBOH placeholder="PETROBRAS BOH">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label for="exampleInputPassword1">OML 127 TAX BOH</label>
          <input type="number" class="form-control" [(ngModel)]="model.oML127TaxOilBOH" name="model.oML127TaxOilBOH" #model.oML127TaxOilBOH placeholder="OML 127 TAX OIL BOH">
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="exampleInputPassword1">STATOIL BOH</label>
          <input type="number" class="form-control" [(ngModel)]="model.statoilBOH" name="model.statoilBOH" #model.statoilBOH placeholder="STATOIL BOH">
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="exampleInputPassword1">TNOS BOH</label>
          <input type="number" class="form-control" [(ngModel)]="model.tnosBOH" name="model.tnosBOH" #model.tnosBOH placeholder="TNOS BOH">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label for="exampleInputPassword1">OML 128 NNPC BOH</label>
          <input type="number" class="form-control" [(ngModel)]="model.nNPCBOH" name="model.nNPCBOH" #model.nNPCBOH placeholder="OML 128 NNPC BOH">
        </div>
      </div>
    </div>
  
    
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label for="exampleInputPassword1">START DATE</label>
          <input type="text" placeholder="Datepicker" [(ngModel)]="model.startDate" name="model.startDate"
           #model.startDate class="form-control" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}" bsDatepicker>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="exampleInputPassword1">AGBAMI OPENING STOCK</label>
          <input type="number" class="form-control" [(ngModel)]="model.openingBalance" name="model.openingBalance" #model.openingBalance placeholder="Opening Stock">
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="exampleInputPassword1">Forecast Duration</label>
          <select class="form-control" [(ngModel)]="model.duration" name="model.duration" #model.duration>
            <option>30</option>
            <option>60</option>
            <option>90</option>
            <option>180</option>
            <option>365</option>
            <option>Others</option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="exampleFormControlTextarea1">Notes</label>
      <textarea class="form-control" [(ngModel)]="model.comment" name="model.comment" #model.comment>
      <option>30</option> rows="4"></textarea>
    </div>
    <div class="d-flex justify-content-end">
        <button type="submit" class="btn btn-outline-primary btn-lg mr-2"(click)="submit()" >Submit</button>
        <button type="submit" class="btn btn-outline-danger btn-lg ml-2">Reset</button>
    </div>
    
  </form>
</div>
<hr>
