import { Component, OnInit, TemplateRef } from '@angular/core';
import { Export } from '../_models/export';
import { ExportService } from '../_services/export.service';
import { AlertifyService } from '../_services/alertify.service';
import { Pagination, PaginatedResult } from '../_models/pagination';
import { Vessel } from '../_models/Vessel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '../_services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: "app-export-table",
  templateUrl: "./export-table.component.html",
  styleUrls: ["./export-table.component.css"]
})
export class ExportTableComponent implements OnInit {
  exports: Export[];
  pagination: Pagination;
  pageNumber: number;
  pageSize: number;
  customSelected: string;
  vesselNames: any[];
  isCreatMode = false;
  exportToDuplicateID: number ;

  modalRef: BsModalRef;

  constructor(
    private exportService: ExportService,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.pageNumber = 1;
    this.pageSize = 20;
    this.loadExports(this.pageNumber, this.pageSize);
    this.loadVessels();
  }

  loadExports(pn, ps) {
    this.exportService.getExports(pn, ps).subscribe(
      (exports: PaginatedResult<Export[]>) => {
        this.exports = exports.result;
        this.pagination = exports.pagination;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  reloadExports() {
    this.exportService
      .getExports(
        this.pagination.currentPage,
        this.pagination.itemsPerPage,
        this.customSelected
      )
      .subscribe(
        (res: PaginatedResult<Export[]>) => {
          this.exports = res.result;
          this.pagination = res.pagination;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }

  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this.reloadExports();
  }

  loadVessels() {
    this.exportService.getVessels().subscribe(
      (vessels: Vessel[]) => {
        this.vesselNames = vessels;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  onSelect() {
    this.exportService
      .getExports(
        this.pagination.currentPage,
        this.pagination.itemsPerPage,
        this.customSelected
      )
      .subscribe(
        (res: PaginatedResult<Export[]>) => {
          this.exports = res.result;
          this.pagination = res.pagination;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }

  isCreateModeToggele() {
    this.isCreatMode = !this.isCreatMode;
  }

  closeForm(e: boolean) {
    this.isCreatMode = e;
  }

  openModal(template: TemplateRef<any>, e: any) {
    // console.log(e);
    this.exportToDuplicateID = e;
    this.modalRef = this.modalService.show(template);
  }

  closeConfirmModal() {
    this.modalRef.hide();
  }

  duplicateExport() {
    console.log(this.exportToDuplicateID);
    this.exportService.duplicateExportWithID(this.exportToDuplicateID, this.authService.decodedToken.nameid).subscribe(
      (exports: Export) => {
        this.modalRef.hide();
        this.alertify.success('Export Successfully Duplicated');
        this.router.navigate(['/dash-board']);
      }, error => {
        this.alertify.error('Problem Duplicating Export');
      }
    );
  }
}
