<div class="row mt-2">
  <div class="form-group col-md-4">
    <label for="inputEmail4">Initial Claim Recieved</label>
    <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker 
      name="initialClaimRecievedDate" [(ngModel)]="initialClaimRecievedDate" >
  </div>
    <div class="col-md-4">
      <label>Claim USD Value</label>
      <input type="text" class="form-control" [(ngModel)]="revisedClaimValue" name="revisedClaimValue"> 
    </div>
</div>
<button class="btn btn-outline-info" (click)="saveInitialDemurrageCalculation()"><i class="fa fa-save"></i> Save</button>
