/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DateHttpInterceptor } from './date-http-interceptor';

import { HttpAuthInterceptor } from './http-auth.interceptor';


/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: DateHttpInterceptor, multi: true },
];