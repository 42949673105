import { Pipe, PipeTransform } from '@angular/core';
/*
 * Converts a .NET TimeSpan to Hour
 * Takes timeSpan values to convert.
 * Usage:
 *   value | timespanToHours
 * Example:
 *   {{ 2.22:11:00 | timespanToHours }}
 *   formats to: 70 hrs 11 mins
*/
@Pipe({ name: 'timespanToHours' })
export class TimespanToHoursPipe implements PipeTransform {

    dotNetTimespan = /^(\d+\.)?\d\d:\d\d:\d\d$/;

    transform(value: string): string {

        if (value === null || value === undefined) {
            return "";
        }

        if (!this.dotNetTimespan.test(value))
            return value;

        if (!value.includes(".")) {
            value = "0." + value
        }
        let dayAndTime = value.split(".")
        let dayPart = dayAndTime[0];
        let timePart = dayAndTime[1];

        let hourMinSec = timePart.split(":")
        let hours = hourMinSec[0]
        let mins = hourMinSec[1]
        let secs = hourMinSec[2]

        let totalHours = (parseInt(dayPart) * 24) + parseInt(hours);

        return `${totalHours} hrs ${mins} mins`
    }
}