<div class="container">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dash-board']">Financial Home</a>
    </li>
  </ol>
  <ul class="nav nav-pills">
    <li class="nav-item mr-2">
      <a class="nav-link active" [routerLink]="['/financial']" routerLinkActive="router-link-active" ><i class="fa fa-plus"></i>Process NESS</a>
    </li>
    <li class="nav-item mr-2">
      <a class="nav-link active" [routerLink]="['/ness-payreq']" routerLinkActive="router-link-active"><i class="fa fa-plus"></i>
        Update PayReq #</a>
    </li>
    <li class="nav-item mr-2">
      <a class="nav-link active" [routerLink]="['/exchange-rate']" routerLinkActive="router-link-active"><i class="fa fa-plus"></i> Enter Exchange Rate</a>
    </li>
  </ul>
  <div class="mt-3" id="contentToConvert">
    <h5>NESS Payment Summary</h5>
    <table class="table table-hover mt-2">
      <thead>
        <tr class="table-primary">
          <th scope="col">Vessel</th>
          <th scope="col">BOL Detail</th>
          <!-- <th scope="col">BOL Date</th> -->
          <th scope="col">Volume Price Details</th>
          <!-- <th scope="col">Fiscal Price</th> -->
          <th scope="col">FX Rate Total Value</th>
          <th scope="col">NESS Payable</th>
          <!-- <th scope="col">Total($)</th> -->
          <!-- <th scope="col">Total(N)</th> -->
          <!-- <th scope="col">Ness Payable(N)</th> -->
          <th scope="col">PayReq</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let ness of nessArray">
          <th scope="row">{{ness.export.cargoNumber}}
            <p>{{ness.export.vessel.name}}</p>
          </th>
          <td>{{ness.export.terminals.name}} | {{ness.export.lifters.name}} | <p>{{ness.export.bolDate | date:'MMM dd, yyyy'}}</p></td>
          <!-- <td>{{ness.export.bolDate | date:'MMM dd, yyyy'}}</td> -->
          <td>{{ness.export.actualCargo | number}} @{{ness.export.fiscalPrice | currency}}<p>={{ness.totalCargoValueUSD | currency}}</p></td>
          <!-- <td>{{ness.export.fiscalPrice | currency}}</td> -->
          <td>@{{ness.exchangeRate.rate | currency: 'N'}} = {{ness.totalCargoValueNGN | currency: 'N'}}</td>
          <td>@{{ness.tax.rate}}% ={{ness.nessPayable | currency: 'N'}}</td>
          <!-- <td>{{ness.totalCargoValueUSD | currency}}</td> -->
          <!-- <td>{{ness.totalCargoValueNGN | currency: 'N'}}</td> -->
          <!-- <td>{{ness.nessPayable | currency: 'N'}}</td> -->
          <td>{{ness.payReqNumber}} <p>NXP: {{ness.export.nxpNumber}}</p></td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-end">Total NESS Payable : <span class="font-weight-bold">{{totalNessValue | currency: 'NGN '}}</span></div>
  </div>
  <div>
    <button type="button" class="btn btn-outline-primary" (click)="captureScreen()"><i class="fa fa-download"></i>Download pdf</button>
    <!-- <button type="button" class="btn btn-outline-danger">Danger</button> -->
  </div>
</div>


