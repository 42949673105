import { Component, OnInit, Input } from '@angular/core';
import { Export } from '../_models/export';
import { AlertifyService } from '../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportService } from '../_services/export.service';
import { DocumentService } from '../_services/document.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-export-detail-document',
  templateUrl: './export-detail-document.component.html',
  styleUrls: ['./export-detail-document.component.css']
})

export class ExportDetailDocumentComponent implements OnInit {
  @Input() export: Export;

  exportDocs: any;

  constructor(private exportService: ExportService, private alertify: AlertifyService,
    private route: ActivatedRoute, private nav: Router, private documentService: DocumentService) { }

  ngOnInit() {
    this.loadExportDocs();
  }

  loadExportDocs() {
    this.exportService.getExportDocuments(this.export?.id).subscribe((documents: Document[]) => {
      this.exportDocs = documents;
    }, error => {
      this.alertify.error(error);
    });
  }

  downloadDocument(e: number, fileName: string) {
    this.documentService.download(e).subscribe(
      data => {
        // console.log(fileName);
        // console.log(data);
        // const FileSaver = require('file-saver');
        // FileSaver.saveAs(data, fileName);
        saveAs(data, fileName);
        this.alertify.success('Download Successful');
      },
      error => {
        this.alertify.error('Unable to Download Document');
      }
    );
  }
  downloadAll(documentArrayList: any[]) {
    for (let index = 0; index < documentArrayList.length; index++) {
      this.downloadDocument(documentArrayList[index].id, documentArrayList[index].fileName);
    }
  }

}
