import { Component, OnInit, TemplateRef } from '@angular/core';
import { TypeaheadMatch } from "ngx-bootstrap/typeahead/typeahead-match.class";
import { ExportService } from '../../_services/export.service';
import { AlertifyService } from '../../_services/alertify.service';
import { Export } from '../../_models/export';
import { DemurrageCalculation } from '../../_models/demurragecalculation';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Router } from "@angular/router";
import { FormGroup } from "@angular/forms";
import { AuthService } from '../../_services/auth.service';
import { Demurrage } from '../../_models/demurrage';




@Component({
  selector: "app-demurrage-home",
  templateUrl: "./demurrage-home.component.html",
  styleUrls: ["./demurrage-home.component.css"]
})
export class DemurrageHomeComponent implements OnInit {
  modalRef: BsModalRef;

  notificationOfArrivalTime: Date = new Date();
  notificationOfArrivalDate: Date;

  portAuthorityOnBoardTime: Date = new Date();
  portAuthorityOnBoardDate: Date;

  freePratigueOnBoardTime: Date = new Date();
  freePratiqueOnBoardDate: Date;

  pilotOnBoardTime: Date = new Date();
  pilotOnBoardDate: Date;

  completedMooringTime: Date = new Date();
  completedMooringDate: Date;

  completedDisconnectionOfHoseTime: Date = new Date();
  completedDisconnectionOfHoseDate: Date;

  laycanTime: Date = new Date();
  laycanDate: Date;

  cargoPaperDocStartTime: Date = new Date();
  cargoPaperDocStartDate: Date;

  cargoPaperDocEndTime: Date = new Date();
  cargoPaperDocEndDate: Date;

  totalVolumeLoaded: number;

  layTimeStartTime: Date = new Date();
  layTimeStartDate: Date;

  equipmentBreakDownStartTime: Date = new Date();
  equipmentBreakDownStartDate: Date;

  equipmentBreakDownEndsTime: Date = new Date();
  equipmentBreakDownEndsDate: Date;

  weatherDelayStartTime: Date = new Date();
  weatherDelayStartDate: Date;

  weatherDelayEndTime: Date = new Date();
  weatherDelayEndDate: Date;

  hasEquipmentBreakDown = false;
  hasWeatherDelay = false;

  selectedValue: string;
  selectedOption: any;
  vesselName: any;
  BOLDate: any;
  lifter: any;
  terminalName: any;
  vesselArrived: any;
  exportID = 1;
  lifterImage: any;

  afraValue: number;
  wsValue: number;
  shipRate: number;

  exports: Export[];
  export: any;

  model: any = {};

  calculationAvailable = false;

  returnedDemurrageModel: any = {};

  hasExporRecord = false;

  createdActivated = false;


  constructor(
    private modalService: BsModalService,
    private exportService: ExportService,
    private alertify: AlertifyService,
    private route: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.loadExports();
  }

  toggelCreatedActivated() {
    this.createdActivated = !this.createdActivated;
  }
  toggelHasEquipmentFailure() {
    this.hasEquipmentBreakDown = !this.hasEquipmentBreakDown;
  }
  toggleHasWeatherDelays() {
    this.hasWeatherDelay = !this.hasWeatherDelay;
  }

  onNotificationOfArrivalValueChange(value: Date): void {
    this.notificationOfArrivalTime = value;
  }
  onPortAthourityOnBoardValueChange(value: Date): void {
    this.portAuthorityOnBoardTime = value;
  }
  onfreePratigueOnBoardValueChange(value: Date): void {
    this.freePratigueOnBoardTime = value;
  }
  onPilotOnBoardValueChange(value: Date): void {
    this.pilotOnBoardTime = value;
  }
  onCompletedMooringValueChange(value: Date): void {
    this.completedMooringTime = value;
  }
  onCompletedDisconnectionOfHoseValueChange(value: Date): void {
    this.completedDisconnectionOfHoseTime = value;
  }
  onLaycanValueChange(value: Date): void {
    this.laycanTime = value;
  }
  onLayTimeStartValueChange(value: Date): void {
    this.layTimeStartTime = value;
  }
  onEquipmentBreakDownStartValueChange(value: Date): void {
    this.equipmentBreakDownStartTime = value;
  }
  onEquipmentBreakEndValueChange(value: Date): void {
    this.equipmentBreakDownEndsTime = value;
  }
  onWeatherDelayStartValueChange(value: Date): void {
    this.weatherDelayStartTime = value;
  }
  onWeatherDelayEndValueChange(value: Date): void {
    this.weatherDelayEndTime = value;
  }

  onCargoPaperDocStartValueChange(value: Date): void {
    this.cargoPaperDocStartTime = value;
  }
  onCargoPaperDocEndValueChange(value: Date): void {
    this.cargoPaperDocEndTime = value;
  }

  onSelect(event: TypeaheadMatch): void {
    this.selectedOption = event.item;
    this.export = event.item;
    // console.log(this.selectedOption.lifters.terminals.name);
    this.exportID = this.selectedOption.id;
    this.vesselName = this.selectedOption.vessel.name;
    this.BOLDate = this.selectedOption.bolDate;
    this.lifter = this.selectedOption.lifters.name;
    this.terminalName = this.selectedOption.lifters.terminals.name;
    this.vesselArrived = this.selectedOption.eta;
    this.lifterImage = '../' + this.selectedOption.lifters.lifterPhotoUrl;

    this.hasExporRecord = true;
  }

  

  calculateDemurrage() {

    this.model.exportId = this.exportID;
    this.model.firstDayOfLaycan = this.laycanTime;
    this.model.totalVolumeLoaded = this.totalVolumeLoaded;
    this.model.notificationOfArrival = this.notificationOfArrivalTime;
    this.model.hoseConnected = this.layTimeStartTime;
    this.model.layTimeStart = this.layTimeStartTime;
    this.model.portAuthorityOnBoard = this.portAuthorityOnBoardTime;
    this.model.freePratiqueGranted = this.freePratigueOnBoardTime;
    this.model.pilotOnBoard = this.pilotOnBoardTime;
    this.model.completeDisconnectingHose = this.completedDisconnectionOfHoseTime;
    this.model.completedMooring = this.completedMooringTime;
        
    this.model.cargoPaperStart = this.cargoPaperDocStartTime ;
    this.model.cargoPaperCompleted = this.cargoPaperDocEndTime ;

    this.model.afraRate = this.afraValue;
    this.model.wsRate = this.wsValue;
    this.model.shipRate = this.shipRate;
    this.model.hasEquipmentBreakDown = this.hasEquipmentBreakDown;
    this.model.hasWeatherDelay = this.hasWeatherDelay;
    this.model.equipmentBreakDownStart = this.equipmentBreakDownStartTime;
    this.model.equipmentBreakDownEnds = this.equipmentBreakDownEndsTime;
    this.model.WeatherDelayStart = this.weatherDelayStartTime;
    this.model.WeatherDelayEnd = this.weatherDelayEndTime;

    // console.log(this.model);

    this.exportService.createDemmurageCulculation(this.model).subscribe(
      (demurrageData: any) => {
        this.returnedDemurrageModel = demurrageData;
        console.log(this.returnedDemurrageModel);
        this.alertify.success("Demurrage Instance Successfully Created");
        this.calculationAvailable = true;
      },
      error => {
        this.alertify.error("Error Posting Demmurage");
      }
    );
  }

  loadExports() {
    this.exportService.getAllExports().subscribe(
      (exports: Export[]) => {
        this.exports = exports;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
}
