<div class="container">
  <h3 class="mt-2">Terminal Top Exports</h3>
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dash-board']">Home</a>
    </li>
  </ol>
  <ul class="nav nav-pills">
    <li class="nav-item mr-2">
      <a class="nav-link active" (click)="isCreateModeToggele()">
        <i class="fa fa-plus"></i> Create Export</a>
    </li>
    <li class="nav-item mr-2">
      <a class="nav-link active" [routerLink]="['/export/list']">
        <i class="fa fa-list-ul"></i> List Export</a>
    </li>
  </ul>
  <app-export-create *ngIf="isCreatMode" (closeCreateExportForm)="closeForm($event)"></app-export-create>
  <div>
    <!-- <pre class="card card-block card-header mb-3">Search For: {{customSelected | json}}</pre> -->
    <div *ngIf="!isCreatMode" class="row">
      <div class="col-md-4 ml-2">
        <label>{{customSelected | json}} </label>
        <input [(ngModel)]="customSelected" [typeahead]="vesselNames" typeaheadOptionField="name" class="form-control" (typeaheadOnSelect)="onSelect()">
      </div>
    </div>
  </div>
  <div *ngIf="!isCreatMode" class="mt-4">
    <table class="table table-hover">
      <thead>
        <tr class="table-active">
          <th>Terminal</th>
          <th scope="col">Cargo#</th>
          <th scope="col">Vessel</th>
          <th scope="col">Lifter</th>
          <th scope="col">Laycan</th>
          <th scope="col">BOL Date</th>
          <th scope="col">BOL Figure</th>
          <th scope="col">ETA</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let export of exports">
          <td [routerLink]="['/export-detail',export.id]">{{export?.terminals.name}}</td>
          <td [routerLink]="['/export-detail',export.id]">{{export?.cargoNumber}}</td>
          <td>{{export?.vessel.name}}</td>
          <td>{{export?.lifters.name}}</td>
          <td>{{export?.dateRangeStart | date:'MMM dd'}} - {{export?.dateRangeEnd | date:'MMM dd, yyy'}}</td>
          <td>{{export?.bolDate | date}}</td>
          <td>{{export?.actualCargo | number}}</td>
          <td>{{export?.eta | date}}</td>
          <td>
            <div class="btn-group" dropdown>
              <button id="button-basic" dropdownToggle type="button" class="btn btn-outline-danger btn-sn dropdown-toggle" aria-controls="dropdown-basic">
                Action <span class="caret"></span>
              </button>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                <li role="menuitem"><a class="dropdown-item" (click)="openModal(confirmDuplicationModal,export?.id)" >Duplicate</a></li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-end" *ngIf="!isCreatMode">
    <pagination [boundaryLinks]="true" [totalItems]="pagination.totalItems" [itemsPerPage]="pagination.itemsPerPage" [(ngModel)]="pagination.currentPage"
      (pageChanged)="pageChanged($event)" previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
    </pagination>
  </div>
</div>

<ng-template #confirmDuplicationModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Action Confirmation</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <p>Are you sure you want to create a duplicate of this export. By click yes a new export instance would be created with the same details and a ----NEW flag on the Cargo Number.</p>
    </div>
    <button type="button" class="btn btn-outline-primary mt-2 ml-3 mr-3" (click)="duplicateExport()">Create Duplicate</button>
    <button type="button" class="btn btn-outline-danger mt-2 mr-3" (click)="closeConfirmModal()">Close</button>
  </div>
</ng-template>