<div class="form-row">
  <div class="form-group col-md-8">
    <label for="inputETA">Document Category</label>
    <select class="form-control" (change)="getDC()" #model.id [(ngModel)]="model.id" name="model.id" required>
      <option *ngFor="let docCat of documentCategoryList" value="{{docCat?.id}}">{{docCat?.name}}</option>
    </select>
  </div>
</div>

<div class="row mt-3">

  <div class="col-md-4">

    <h6>Drag Files Here</h6>

    <div ng2FileDrop 
      [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" 
      (fileOver)="fileOverBase($event)" 
      [uploader]="uploader"
      class="card bg-faded p-3 text-center mb-3 my-drop-zone">
      <i class="fa fa-upload fa-3x"></i>
      Drop Documents Here
    </div>

    <!-- Multiple
    <input type="file" ng2FileSelect [uploader]="uploader" multiple />
    <br/> Single
    <input type="file" ng2FileSelect [uploader]="uploader" /> -->
  </div>
  
  <div class="col-md-8" style="margin-bottom: 40px">

    <h6>Upload queue</h6>
    <p>Queue length: {{ uploader?.queue?.length }}</p>

    <table class="table">
      <thead>
        <tr>
          <th width="50%">Name</th>
          <th>Size</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of uploader.queue">
          <td>
            <strong>{{ item?.file?.name }}</strong>
          </td>
          <td *ngIf="uploader.options.isHTML5" nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
        </tr>
      </tbody>
    </table>

    <div>
      <div>
        Queue progress:
        <div class="progress mb-4">
          <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
        </div>
      </div>
      <button type="button" class="btn btn-success btn-s ml-1" (click)="uploader.uploadAll()" [disabled]="!uploader.getNotUploadedItems().length">
        <span class="fa fa-upload"></span> Upload
      </button>
      <button type="button" class="btn btn-warning btn-s ml-1" (click)="uploader.cancelAll()" [disabled]="!uploader.isUploading">
        <span class="fa fa-ban"></span> Cancel
      </button>
      <button type="button" class="btn btn-danger btn-s ml-1" (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length">
        <span class="fa fa-trash"></span> Remove
      </button>
    </div>

  </div>

</div>