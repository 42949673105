<div class="card mb-3" [class.escravos-card]="export?.terminals?.name == 'Escravos'"
  [class.egtl-card]="export?.terminals?.name == 'EGTL'" [class.lpg-card]="export?.terminals?.name == 'LPG'"
  [class.usan-card]="export?.terminals?.name == 'USAN'" [class.agbami-card]="export?.terminals?.name == 'AGBAMI'"
  style="max-width: 20rem;" [routerLink]="['/export-detail',export.id]">
  <div class="card-header text-center">{{export?.vessel?.name}}</div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-4">
        <img src="{{export?.lifters?.lifterPhotoUrl}}" width="100" height="100">
      </div>
      <div class="col-md-8">
        <h6 class="card-title text-center">{{export?.lifters?.name}}</h6>
      </div>
    </div>
    <h6 class="card-text text-center">{{export?.dateRangeStart | date:'d'}} - {{export?.dateRangeEnd | date:'d MMMM,
      y'}}</h6>
  </div>
  <div class="list-group">
    <a class="list-group-item list-group-item-action text-center active">
      {{export?.terminals?.name | uppercase}} | {{export?.cargoNumber | uppercase}}
    </a>
  </div>
  <ul class="list-group">
    <li class="list-group-item d-flex justify-content-between align-items-center">
      BOL Date : {{ export?.bolDate | date}}
    </li>
    <li class="list-group-item d-flex justify-content-between align-items-center">
      BOL Volume: {{ export?.actualCargo | number}}
    </li>
    <li class="list-group-item d-flex align-items-center">
      <h6>{{ export?.status?.label}}</h6>
    </li>
  </ul>
</div>