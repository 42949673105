import { Component, OnInit, TemplateRef } from '@angular/core';
import { ExportService } from '../../_services/export.service';
import { AlertifyService } from '../../_services/alertify.service';
import { Router } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import { Terminal } from '../../_models/terminal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { Product } from 'src/app/_models/product';
import { Lifter } from 'src/app/_models/lifter';
import { CargoType } from 'src/app/_models/cargotype';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { OML } from 'src/app/_models/oml';

@Component({
  selector: "app-admin-terminal-tab",
  templateUrl: "./admin-terminal-tab.component.html",
  styleUrls: ["./admin-terminal-tab.component.css"]
})
export class AdminTerminalTabComponent implements OnInit {
  terminalCollection: any = [
    {
      name: "Agbami"
    },
    {
      name: "Escravos"
    }
  ];

  enteredTerminal: any;
  model: any = {};
  terminals: Terminal[];
  selectedLifter: string;
  selectedOption: any = {};
  cargoTypeName: string;
  selectedCargoType: any = {};
  lifters: any[];
  products: Product[];
  cargotypes: CargoType[];
  showLifter = false;
  showCargoType = false;
  terminalName: string;
  omls: any[];

  modalRef: BsModalRef;

  public lifterForm: any = {
    name: null,
    label: null,
    omlId: null,
    terminalId: null,
  };
  public cargoTypeForm: any = {
    name: null,
    description: null,
    lifterId: null,
  };

  constructor(
    private modalService: BsModalService,
    private exportService: ExportService,
    private alertify: AlertifyService,
    private route: Router,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.loadTerminals();
    this.loadCargoTypes();
    this.loadLifters();
    this.loadProducts();
    this.loadOMLs();
  }

  addNewTerminal(enteredTerminal) {
    if (enteredTerminal != null) {
      this.model.name = enteredTerminal;
      this.terminals.push(this.model);

      this.exportService.createTerminal(this.model).subscribe(
        () => {
          this.alertify.success("Terminal Successfully Created");
        },
        error => {
          this.alertify.error("Unable to Create Terminal");
        }
      );

      console.log(enteredTerminal);
      this.enteredTerminal = "";
    }
  }

  loadTerminals() {
    this.exportService.getTerminals().subscribe(
      (terminals: Terminal[]) => {
        this.terminals = terminals;
        console.log(this.terminals);
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadCargoTypes() {
    this.exportService.getCargoTypes().subscribe(
      (cargotypes: CargoType[]) => {
        this.cargotypes = cargotypes;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadProducts() {
    this.exportService.getProducts().subscribe(
      (products: Product[]) => {
        this.products = products;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadLifters() {
    this.exportService.getLifters().subscribe(
      (lifters: Lifter[]) => {
        this.lifters = lifters;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadOMLs() {
    this.exportService.getOMLs().subscribe(
      (omls: OML[]) => {
        this.omls = omls;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  onSelectLifter(event: TypeaheadMatch): void {
    this.selectedOption = event.item;
    this.lifterForm.id = this.selectedOption.id;
    this.lifterForm.name = this.selectedOption.name;
    this.lifterForm.label = this.selectedOption.label;
    this.lifterForm.omlId = this.selectedOption.omLs.id;
    this.lifterForm.terminalId = this.selectedOption.terminals.id;
    this.terminalName = this.selectedOption.terminals.name;
    this.showLifter = true;
    console.log(this.lifterForm);
  }
  onSelectCargoType(event: TypeaheadMatch): void {
    console.log(event.item);
    this.selectedCargoType = event.item;
    this.cargoTypeForm.name = this.selectedCargoType.name;
    this.cargoTypeForm.description = this.selectedCargoType.description;
    this.cargoTypeForm.lifterId = this.selectedCargoType.lifterId;
    console.log(this.cargoTypeForm);
    this.showCargoType = true;
  }
  closeLifterModal() {
    this.showLifter = false;
    this.selectedLifter = '';
  }
  openUpdateLifterModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  closeUpateLifterModal() {
    this.modalRef.hide();
  }
  updateLifterInformation() {
    console.log(this.lifterForm);
    this.exportService.updateLifter(this.lifterForm).subscribe(
      () => {
        this.alertify.success('Update Successful');
        this.modalRef.hide();
        this.loadLifters();
        this.showLifter = false;
        this.lifterForm.name = null;
      }, error => {
        this.alertify.error(error);
      }
    );
  }
  updateCargoTypeInformation() {
    console.log(this.cargoTypeForm);
  }
}
