import { Component, OnInit } from '@angular/core';
import { Export } from '../_models/export';
import { ExportService } from '../_services/export.service';
import { AlertifyService } from '../_services/alertify.service';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.css']
})
export class ExportComponent implements OnInit {
  exports: Export[];
  isCreatMode = false;
  loaderEnabled = true;
  enableLoader = true;

  constructor(private exportService: ExportService, private alertify: AlertifyService) { }

  ngOnInit() {
    this.loadExports();
  }

  loadExports() {
    this.exportService.getInQueueExports().subscribe((exports: Export[]) => {
      this.loaderEnabled = false;
      this.exports = exports;
      this.enableLoader = false;
      // console.log(this.exports);
    }, error => {
      this.alertify.error(error);
    });
  }

  isCreateModeToggele() {
    this.isCreatMode = !this.isCreatMode;
  }
  enableCreate(e: boolean) {
    // console.log(e);
    this.isCreatMode = e;
  }

  closeForm(e: boolean) {
    this.isCreatMode = e;
  }
}
