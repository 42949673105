import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { Export } from '../_models/export';
import { EnergycomponentService } from '../_services/energycomponent.service';
import { AlertifyService } from '../_services/alertify.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ExportService } from '../_services/export.service';

@Component({
  selector: 'app-export-detail-vessel',
  templateUrl: './export-detail-vessel.component.html',
  styleUrls: ['./export-detail-vessel.component.css']
})
export class ExportDetailVesselComponent implements OnInit {
  @Input() export: Export;
  modalRef: BsModalRef;
  enableLoader = false;
  public ecDataForm: any = {
    arrived: null,
    blDate: null,
    bsw: null,
    capacityMass: null,
    cargoNumber: null,
    carrierName: null,
    commencedConnectingHoses: null,
    commencedLoading: null,
    commencedMooring: null,
    commencedUnmooring: null,
    companyName: null,
    completedConnectingHoses: null,
    completedDisconnectingHoses: null,
    completedLoading: null,
    consigneeName: null,
    consignorName: null,
    customsOnBoard: null,
    documentOnBoard: null,
    grossVolumeBBLS: null,
    grsapi: null,
    nationality: null,
    netVolumeBBLS: null,
    nominationDate: null,
    norReceived: null,
    norTendered: null,
    nrt: null,
    pilotOff: null,
    pilotOnBoard: null,
    registeredPort: null,
    remarks: null,
    unloadingPortName: null,
    vesselSailDate: null
  };

  constructor(private ecService: EnergycomponentService, private alertify: AlertifyService
    , private modalService: BsModalService, private exportService: ExportService) { }

  ngOnInit() {
    // console.log(this.export);
  }
  pullDataFromEC(template: TemplateRef<any>) {
    console.log('Loading.....');
    this.enableLoader = true;
    this.ecService.getExportDataForID(12).subscribe(
      (ecData: any) => {
        this.ecDataForm = ecData;
        this.enableLoader = false;
        this.openModal(template);
      }, error => {
        this.alertify.error(error);
      }
    );
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  mergeData() {
    this.export.actualCargo = this.ecDataForm.netVolumeBBLS;
    this.export.shipFigures = this.ecDataForm.grossVolumeBBLS;
    this.export.api = this.ecDataForm.grsapi;
    this.export.bsw = this.ecDataForm.bsw;
    this.export.bolDate = this.ecDataForm.blDate;

    this.export.loadingStop = this.ecDataForm.completedLoading;
    this.export.loadingStart = this.ecDataForm.commencedLoading;
    this.export.eta = this.ecDataForm.arrived;

    this.export.comment = this.ecDataForm.remarks;

    // Map all Objects to their ID Values 
  }
  overwriteData() {
    this.mergeData();
    console.log(this.export);
    if (this.export.cargoNumber === this.ecDataForm.cargoNumber) {
      this.alertify.success('Same Carego Number');
    } else {
      this.alertify.error('Cargo Number Mismatch');
      this.exportService.updateExport(this.export.id, this.export).subscribe(
        () => {
          this.alertify.success('Export Data successfully Overwriten');
          this.modalRef.hide();
        }, error => {
          this.alertify.error('Unable to update write data');
        }
      );
    }
  }
  closeModal() {
    this.modalRef.hide();
  }

}
