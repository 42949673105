<div class="mt-3">
  <table class="table table-hover">
    <thead>
      <tr class="table-primary">
        <th scope="col">Name</th>
        <th scope="col">Terminal</th>
        <th scope="col">Document Category</th>
        <th scope="col">Category</th>
        <th scope="col">Added By</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr class="table" *ngFor="let template of documentTemplate">
        <th scope="row">
          <button type="button" class="btn btn-default" popover={{template.description}}>
            {{template.name}}
          </button>

        </th>
        <td>{{template.terminals?.name}}</td>
        <td>{{template.documentTemplateCategory.name}}</td>
        <td>{{template.updatedAt | timeAgo}}</td>
        <td><span class="badge badge-primary">{{template.users.username | uppercase}}</span></td>
        <td>
          <div class="btn-group" dropdown>
            <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
              Select Action <span class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem"><a class="dropdown-item" href="/{{template.path}}" download="File">Download</a></li>
              <!-- <li role="menuitem"><a class="dropdown-item" href="#">Email</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>
              <li class="divider dropdown-divider"></li>
              <li role="menuitem"><a class="dropdown-item" href="#">Separated link</a>
              </li> -->
            </ul>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>