import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forecast-generator',
  templateUrl: './forecast-generator.component.html',
  styleUrls: ['./forecast-generator.component.css']
})
export class ForecastGeneratorComponent implements OnInit {

  forecastProductionArray: any = [
    {
      duration: 0,
      value: 0
    }
  ];
  model: any = {};
  duration: number;
  value: number;

  constructor() { }

  ngOnInit() {
  }
  addToArray() {
    this.model.duration = this.duration;
    this.model.value = this.value;
    this.forecastProductionArray.push(this.model);
    const durationInputBox = document.getElementById('durationInput');
    const valueInputBox = document.getElementById('valueInput');
    durationInputBox.nodeValue = '';
    durationInputBox.focus();
    valueInputBox.nodeValue = '';
    console.log(this.forecastProductionArray);
  }
}
