<div class="container">
  <h3 class="mt-2">Terminal Top Exports</h3>
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dash-board']">Home</a>
    </li>
  </ol>
  <ul class="nav nav-pills">
    <li class="nav-item mr-2">
      <a class="nav-link active" (click)="isCreateModeToggele()">
        <i class="fa fa-plus"></i> Create New Blank Export</a>
    </li>
    <!-- <li class="nav-item mr-2">
      <a class="nav-link active">
        <i class="fa fa-copy"></i> Create from existing Export</a>
    </li> -->
    <li class="nav-item mr-2">
      <a class="nav-link active" [routerLink]="['/export/list']">
        <i class="fa fa-list-ul"></i> View Exports as List Export</a>
    </li>
  </ul>
  <div>
      <!-- <pre class="card card-block card-header mb-3">Search For: {{customSelected | json}}</pre> -->
      <!-- <div class="row breadcrumb mt-2">
          <div class="col-sm-3">
              <label>Vessel Name</label>
              <input [(ngModel)]="customSelected" [typeahead]="vesselNames" typeaheadOptionField="name" class="form-control"
                (typeaheadOnSelect)="onSelect()">
      </div> -->
  
    </div>
  <!-- <app-export-menu-nav></app-export-menu-nav> -->
  <app-export-create *ngIf="isCreatMode" (closeCreateExportForm)="closeForm($event)"></app-export-create>
  <div *ngIf="enableLoader" class="container">
    <div class="row">
      <div id="loader">
          <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="lading"></div>
      </div>
    </div>
  </div>
  <div *ngIf="!isCreatMode && !enableLoader" class="row mt-4">
    <div *ngFor="let export of exports" class="col-md-3 col-sm-6">
      <app-export-card [export]="export"></app-export-card>
    </div>
  </div>
</div>
<app-add-export-modal></app-add-export-modal>


