import { Component, OnInit, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import html2canvas from "html2canvas";
import * as jspdf from "jspdf";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { CargoType } from "src/app/_models/cargotype";
import { ExchangeRate } from "src/app/_models/exchangerate";
import { Export } from "src/app/_models/export";
import { Lifter } from "src/app/_models/lifter";
import { PaginatedResult } from "src/app/_models/pagination";
import { Product } from "src/app/_models/product";
import { Tax } from "src/app/_models/tax";
import { Terminal } from "src/app/_models/terminal";
import { Vessel } from "src/app/_models/Vessel";
import { AlertifyService } from "src/app/_services/alertify.service";
import { AuthService } from "src/app/_services/auth.service";
import { DateService } from "src/app/_services/date.service";
import { ExportService } from "src/app/_services/export.service";

@Component({
  selector: "app-ness",
  templateUrl: "./ness.component.html",
  styleUrls: ["./ness.component.css"],
})
export class NessComponent implements OnInit {
  modalRef: BsModalRef;
  enableLoader = true;
  nessPayableArray: any = [
    {
      exportId: 1,
      cargoNumber: "AGB-905",
      terminalId: 1,
      terminal: {
        name: "Agbami",
      },
      productId: 1,
      product: {
        name: "Crude",
      },
      lifterId: 1,
      lifter: {
        name: "STARDEEP",
      },
      vessel: {
        name: "MT BIANCO",
      },
      bolFigure: 975000,
      bolDate: "2/2/2018",
      fiscalPrice: 82.56,
      exchangeRate: 305.45,
      nessRate: 0.0012,
      totalCargoValueUSD: 0.0,
      totalCargoValueNGN: 0.0,
      nessPayable: 0.0,
    },
  ];

  calculatedNESSArray: any = [
    {
      exportId: 2,
      cargoNumber: "",
      terminalId: 1,
      terminal: {
        name: "",
      },
      productId: 1,
      product: {
        name: "",
      },
      vessel: {
        name: "",
      },
      lifterId: 1,
      lifter: {
        name: "",
      },
      bolFigure: 0,
      bolDate: "",
      fiscalPrice: 0.0,
      exchangeRate: 0.0,
      nessRate: 0.0,
      totalCargoValueUSD: 0.0,
      totalCargoValueNGN: 0.0,
      nessPayable: 0.0,
      nxpNumber: "",
    },
  ];
  exportWithOpenNESSArray: any[];
  initalNessGenerated = false;
  nessTax: any = {};
  selectedExportRecord: any = {};
  selectedExportExchangeRate: any = {};
  model: any = {};
  modelArray: any = [
    {
      exportId: 1,
      exchangeRateId: 1,
      taxId: 1,
      totalCargoValueUSD: 789000,
      totalCargoValueNGN: 788009,
      nessPayable: 234898998,
      payReqNumber: 234545,
    },
  ];

  exportId: number;
  exportNumber: any;
  vesselName: any;
  fiscalPrice: number;
  bolFigure: number;
  bolDate: string;
  bolModel: any = {};
  selectedNXP: string;

  // Filtering Variables
  pagination: any = {};
  pageNumber: number;
  pageSize: number;
  customSelected: string;
  vesselNames: any[];
  terminals: Terminal[];
  products: Product[];
  lifters: Lifter[];
  cargotypes: CargoType[];
  terminalName: string;
  lifterName: string;
  productName: string;
  dateStart: any;
  dateEnd: any;
  dateStartToSend: any;
  dateEndToSend: any;
  userId: number;
  statusId: number;
  selectedCargoNumber: any;

  constructor(
    private modalService: BsModalService,
    private exportService: ExportService,
    private alertify: AlertifyService,
    private route: Router,
    private authService: AuthService,
    private dateService: DateService
  ) {}

  ngOnInit() {
    this.pageNumber = 1;
    this.pageSize = 10;

    this.loadOpenNESS(this.pageNumber, this.pageSize);
    this.getNessTaxRate();

    this.loadVessels();
    this.loadTerminals();
    this.loadLifters();
    this.loadProducts();
  }
  loadVessels() {
    this.exportService.getVessels().subscribe(
      (vessels: Vessel[]) => {
        this.vesselNames = vessels;
      },
      (error) => {
        this.alertify.error(error);
      }
    );
  }
  loadCargoTypes() {
    this.exportService.getCargoTypes().subscribe(
      (cargotypes: CargoType[]) => {
        this.cargotypes = cargotypes;
      },
      (error) => {
        this.alertify.error(error);
      }
    );
  }
  loadTerminals() {
    this.exportService.getTerminals().subscribe(
      (terminals: Terminal[]) => {
        this.terminals = terminals;
      },
      (error) => {
        this.alertify.error(error);
      }
    );
  }
  loadProducts() {
    this.exportService.getProducts().subscribe(
      (products: Product[]) => {
        this.products = products;
      },
      (error) => {
        this.alertify.error(error);
      }
    );
  }
  loadLifters() {
    this.exportService.getLifters().subscribe(
      (lifters: Lifter[]) => {
        this.lifters = lifters;
      },
      (error) => {
        this.alertify.error(error);
      }
    );
  }
  openModal(template: TemplateRef<any>, e: any) {
    // console.log(e);
    this.exportId = e.id;
    this.exportNumber = e.cargoNumber;
    this.vesselName = e.vessel.name;
    this.modalRef = this.modalService.show(template);
  }
  openNXPModal(template: TemplateRef<any>, e: any) {
    // console.log(e);
    this.exportId = e.id;
    this.exportNumber = e.cargoNumber;
    this.vesselName = e.vessel.name;
    this.modalRef = this.modalService.show(template);
  }
  openBOLModal(template: TemplateRef<any>, e: any) {
    this.exportId = e.id;
    this.bolDate = e.bolDate;
    this.bolFigure = e.bolFigure;

    this.bolModel.id = this.exportId;
    this.bolModel.bolFigure = e.bolFigure;
    this.bolModel.bolDate = e.bolDate;

    this.modalRef = this.modalService.show(template);
  }

  updateFiscalPrice() {
    this.exportService
      .updateFiscalPriceForExport(this.exportId, this.fiscalPrice)
      .subscribe(
        () => {
          this.alertify.success("Price Updated for Export");
          this.ngOnInit();
          this.modalRef.hide();
        },
        (error) => {
          this.alertify.error("Unable to update Price");
        }
      );
  }
  updateBOLFigures() {
    // console.log(this.bolModel);
    this.exportService.UpdateExportBOL(this.bolModel).subscribe(
      () => {
        this.alertify.success("BOL Dtails  Updated for Export");
        this.ngOnInit();
        this.modalRef.hide();
      },
      (error) => {
        this.alertify.error("Unable to update BOL Details");
      }
    );
  }

  updateNXPValue() {
    this.exportService
      .updateNXPForExport(this.exportId, this.selectedNXP)
      .subscribe(
        () => {
          this.alertify.success("NXP Number Succesfully Updated for Export");
          this.ngOnInit();
          this.modalRef.hide();
        },
        (error) => {
          this.alertify.error("Unable to update NXP Number");
        }
      );
    this.modalRef.hide();
  }

  GenerateNess(e) {
    // console.log(e);
    this.getExchangeRateForExport(this.dateService.urlDate(e.bolDate), e);
  }

  DeleteNessRow(e) {
    // console.log(e);
    for (let index = 0; index < this.calculatedNESSArray.length; index++) {
      const element = this.calculatedNESSArray[index];
      if (e === element) {
        this.calculatedNESSArray.splice(index, 1);
      }
    }
  }

  //

  loadOpenNESS(pn, ps) {
    this.exportService.getExportWithOpenNESS(pn, ps).subscribe(
      (exports: PaginatedResult<Export[]>) => {
        this.exportWithOpenNESSArray = exports.result;
        this.nessPayableArray = exports.result;
        this.pagination = exports.pagination;
        this.enableLoader = false;
        // console.log(this.exportWithOpenNESSArray);
      },
      (error) => {
        this.alertify.error("Something went wrong loading export");
      }
    );
  }
  reloadOpenNess() {
    this.exportService
      .getExports(
        this.pagination.currentPage,
        this.pagination.itemsPerPage,
        this.customSelected,
        this.terminalName,
        this.lifterName,
        this.productName,
        this.dateStartToSend,
        this.dateEndToSend,
        this.statusId,
        this.userId,
        this.selectedCargoNumber
      )
      .subscribe(
        (res: PaginatedResult<Export[]>) => {
          this.exportWithOpenNESSArray = res.result;
          this.nessPayableArray = res.result;
          this.pagination = res.pagination;
        },
        (error) => {
          this.alertify.error(error);
        }
      );
  }

  getNessTaxRate() {
    this.exportService.getNessTax().subscribe(
      (returnedTax: Tax) => {
        this.nessTax = returnedTax;
        // console.log(this.nessTax);
      },
      (error) => {
        this.alertify.error("Unable to load tax details");
      }
    );
  }

  getExchangeRateForExport(bolDate, e: any) {
    this.exportService.getExchangeRateForExport(bolDate).subscribe(
      (returnedExchangeRate: ExchangeRate) => {
        this.selectedExportExchangeRate = returnedExchangeRate;
        this.selectedExportRecord.exchangeRate =
          this.selectedExportExchangeRate.rate;

        let valueUSD = 0.0;
        let valueNGN = 0.0;

        valueUSD = e.fiscalPrice * e.actualCargo;
        valueNGN = valueUSD * this.selectedExportExchangeRate.rate;
        // console.log(valueNGN);
        this.selectedExportRecord.id = e.id;
        this.selectedExportRecord.exportId = e.id;
        this.selectedExportRecord.cargoNumber = e.cargoNumber;
        this.selectedExportRecord.bolFigure = e.actualCargo;
        this.selectedExportRecord.bolDate = e.bolDate;
        this.selectedExportRecord.nxpNumber = e.nxpNumber;
        this.selectedExportRecord.terminal = e.terminals;
        this.selectedExportRecord.terminalId = e.terminals.id;
        this.selectedExportRecord.lifter = e.lifters;
        this.selectedExportRecord.vessel = e.vessel;
        this.selectedExportRecord.fiscalPrice = e.fiscalPrice;
        this.selectedExportRecord.nessRate = this.nessTax.rate;
        this.selectedExportRecord.taxId = this.nessTax.id;
        this.selectedExportRecord.totalCargoValueUSD = valueUSD;
        this.selectedExportRecord.totalCargoValueNGN = valueNGN;
        this.selectedExportRecord.nessPayable =
          this.selectedExportRecord.totalCargoValueNGN *
          this.selectedExportRecord.nessRate;

        this.model.exportId = e.id;
        this.model.terminalId = e.terminals.id;
        this.model.exchangeRateId = this.selectedExportExchangeRate.id;
        this.model.taxId = this.nessTax.id;
        this.model.totalCargoValueUSD = valueUSD;
        this.model.totalCargoValueNGN = valueNGN;
        this.model.nessPayable =
          this.selectedExportRecord.totalCargoValueNGN *
          this.selectedExportRecord.nessRate;

        if (!this.initalNessGenerated) {
          this.calculatedNESSArray.unshift(this.selectedExportRecord);
          this.modelArray.unshift(this.model);
          this.calculatedNESSArray.pop();
          this.modelArray.pop();
          this.selectedExportRecord = {};
          this.model = {};
        } else {
          this.calculatedNESSArray.push(this.selectedExportRecord);
          this.modelArray.push(this.model);
          this.selectedExportRecord = {};
          this.selectedExportRecord = {};
        }
        this.initalNessGenerated = true;
      },
      (error) => {
        this.alertify.error("Unable To Get Exchange Rate");
      }
    );
  }

  processNESS(e) {
    console.log(this.modelArray);
    this.exportService.processNess(this.modelArray).subscribe(
      () => {
        this.alertify.success("Ness Processing Successful");

        while (this.calculatedNESSArray.length > 0) {
          this.calculatedNESSArray.pop();
        }
        while (this.modelArray.length > 0) {
          this.modelArray.pop();
        }
        this.ngOnInit();
        this.initalNessGenerated = false;
      },
      (error) => {
        this.alertify.error("Unable to Process Ness");
      }
    );
  }

  public captureScreen() {
    const data = document.getElementById("contentToConvert");
    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL("image/png");
      const pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
      const position = 0;
      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      pdf.save("NESS.pdf"); // Generated PDF
    });
  }
  runQuery() {
    this.reloadOpenNess();
  }

  clearFileter() {
    this.terminalName = null;
    this.productName = null;
    this.lifterName = null;
    this.productName = null;
    this.dateStartToSend = null;
    this.dateEndToSend = null;
    this.statusId = null;
    this.userId = null;
    this.selectedCargoNumber = null;
    this.reloadOpenNess();
  }

  onDateStartChange(value: Date): void {
    this.dateStartToSend = this.dateService.urlDate(value);
    const today = new Date(Date.now());
    this.dateEndToSend = this.dateService.urlDate(today);
  }
  onDateEndChange(value: Date): void {
    this.dateEndToSend = this.dateService.urlDate(value);
  }
  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this.reloadOpenNess();
  }
  onSelectCargoNumber(event: TypeaheadMatch): void {
    this.selectedCargoNumber = event.item.cargoNumber;
  }
}
