<div class="container mt-4">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dash-board']">Home</a>
    </li>
    <li class="breadcrumb-item">
      <a [routerLink]="['/excess-water']">Excess Water Details Update</a>
    </li>
    <li class="breadcrumb-item active">{{export?.vessel?.name}}</li>
  </ol>
  <div class="row">
    <div class="col-md 4">
      <h1 class="mt-2">{{export?.vessel?.name}}</h1>
    </div>
    <div class="col-md-8">
      <div *ngIf="editExcessWaterForm.dirty" class="alert alert-info">
        <strong>Information</strong>You have made some changes. Any unsaved changes would be lost
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4">
      <div class="card">
        <img class="card-img-top img-thumbnail rounded" src="{{export?.lifters?.lifterPhotoUrl}}" alt="">
        <div class="card-body">
          <div>
            <h5 class="mr-2">{{export?.cargoNumber}}</h5>
          </div>
          <div>
            <strong>Date Range</strong>
            <p>{{export?.dateRangeStart | date:'mediumDate'}} - {{export?.dateRangeEnd | date:'mediumDate'}}</p>
          </div>
          <div>
            <strong>LIFTER</strong>
            <p>{{export?.lifters.name | uppercase}}</p>
          </div>
          <div>
            <strong>ETA</strong>
            <p>{{export?.eta | date:'mediumDate'}}</p>
          </div>
          <div>
            <strong>CURRENT STATUS</strong>
            <p>{{ export?.status.label }}</p>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button [disabled]="!editExcessWaterForm.valid" form="editExcessWaterForm" type="submit"
          class="btn btn-success w-100" (click)="updateExcessWater()">Save Changes</button>
      </div>
    </div>
    <div class="col-sm-8">
      <form #editExcessWaterForm="ngForm" id="editExcessWaterForm">
        <div class="tab-panel">
          <tabset class="member-tabset" #detailTab>
            <tab heading="Details">
              <div class="list-group mt-2">
                <a class="list-group-item list-group-item-action flex-column align-items-start active">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">List group item heading</h5>
                    <small>TERMINAL : {{export?.terminals?.name | uppercase}}</small>
                  </div>
                  <p class="mb-1">LIFTER : {{export?.lifters?.name}} | {{ export?.cargoTypes != null ?
                    export?.cargoTypes?.name :''}} | ETA : {{ export?.eta | date}}</p>
                  <small>Nominated Volume: {{export?.norminatedCargo | number}} BBLS</small>
                  <p class="mb-1">DESTINATION : {{export?.destinations?.name | uppercase}}</p>
                </a>
              </div>
              <div class="row ml-2 mt-4">
                <h6>Cargo Number : </h6>
                <p class="ml-2">{{export?.cargoNumber}}</p>
                <h6 class="ml-4">Product : </h6>
                <p class="ml-2">{{export?.products?.name}}</p>
                <h6 class="ml-4">Cargo Type : </h6>
                <p class="ml-2">{{export?.cargoTypes != null ? export?.cargoTypes?.name :''}}</p>
              </div>
              <div class="row mt-2">
                <div class="col-sm-6">
                  <div class="card">
                    <div class="card-body">
                      <h6 class="card-title mt-2">Agent Details</h6>
                      <p class="card-text mb-1">{{export?.vesselAgents?.name | uppercase}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="card">
                    <div class="card-body">
                      <h6 class="card-title mt-2">Inspectors</h6>
                      <p class="card-text mb-1">{{export?.inspector?.name | uppercase}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ml-2 mt-4">
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Bill Of Volume :</label>
                  <div class="col-sm-8">
                    <input type="text" readonly class="form-control" value="{{export?.actualCargo | number}} BBL.">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Bill Of Lading Date :</label>
                  <div class="col-sm-8">
                    <input type="text" readonly class="form-control" value="{{export?.bolDate | date}}">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">NXP Number:</label>
                  <div class="col-sm-8">
                    <input type="text" readonly class="form-control" value="{{export?.nxpNumber}}">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Claim Received:</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" [(ngModel)]="claimReceived" name="claimReceivedValue"
                      (input)="decimalInputValidations($event,'ClaimReceived')"
                      (keypress)="decimalKeyPressValidations($event)">
                    <small class="text-primary">Enter upto 3 decimal points</small>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Vessel Experience Factor:</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" [(ngModel)]="vesselExperienceFactor"
                      name="vesselExperienceFactor" (keypress)="decimalKeyPressValidations($event)">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Free Water Measured On Arrival:</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" [(ngModel)]="freeWaterMeasuredOnArrival"
                      name="freeWaterMeasuredOnArrival"
                      (input)="decimalInputValidations($event,'FreeWaterMeasuredOnArrival')"
                      (keypress)="decimalKeyPressValidations($event)" required>
                    <small class="text-primary">Enter upto 3 decimal points</small>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Terminal BSMW:</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" [(ngModel)]="terminalBsmw" name="terminalBsmw"
                      (input)="decimalInputValidations($event,'TerminalBsmw')"
                      (keypress)="decimalKeyPressValidations($event)" required>
                    <small class="text-primary">Enter upto 3 decimal points</small>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Sediment Water on Arrival Ship Composite:</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" [(ngModel)]="sedimentWaterArrival"
                      name="sedimentWaterArrival" (input)="decimalInputValidations($event,'SedimentWaterArrival')"
                      (keypress)="decimalKeyPressValidations($event)" required>
                    <small class="text-primary">Enter upto 3 decimal points</small>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Total Excess Free Water:</label>
                  <div class="col-sm-8">
                    <input type="text" readonly class="form-control" value="{{calculateExcessFreeWaterValue()}} BBL.">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Unit Sales:</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" [(ngModel)]="unitSalesValue" name="unitSalesValue"
                      (keypress)="decimalKeyPressValidations($event)" required>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Total Loss:</label>
                  <div class="col-sm-8">
                    <input type="text" readonly class="form-control"
                      value="{{calTotalExcessFreeWaterLossValue() | currency}}">
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-12">
                  <div class="card">
                    <div class="card-body">
                      <h6 class="card-title mt-2">CONSIGNOR</h6>
                      <p class="card-text mb-1">{{export?.consignor?.name | uppercase}}</p>
                      <h6 class="card-title mt-2">CONSIGNEE</h6>
                      <p class="card-text mb-1">{{export?.consignees?.name | uppercase}}</p>

                    </div>
                  </div>
                </div>
              </div>
              <div class="alert alert-dismissible alert-warning">
                <button type="button" class="close" data-dismiss="alert">&times;</button>
                <h5 class="alert-heading">Export Comment</h5>
                <p class="mb-0">{{export?.comment}}.</p>
              </div>
            </tab>
            <tab heading="Vessel Details" (select)="setCurrentTab(1)">
              <app-export-detail-vessel [export]="export"></app-export-detail-vessel>
            </tab>
            <tab heading="Documentation" (select)="setCurrentTab(2)">
              <app-excess-water-document-upload [export]="export"
                [exportId]="exportId"></app-excess-water-document-upload>
            </tab>
          </tabset>
        </div>
      </form>
    </div>
  </div>
</div>