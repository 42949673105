<div class="breadcrumb">
  <div class="col-sm-3">
    <label>Vessel Name</label>
    <input [(ngModel)]="filterModel.vesselName" [typeahead]="vesselNames" typeaheadOptionField="name"
      class="form-control" (typeaheadOnSelect)="onSelect()" required>
  </div>
  <div class="col-sm-2">
    <label for="inputTerminal">Terminal</label>
    <select class="form-control" id="inputTerminal" name="terminalName" [(ngModel)]="filterModel.terminalName" required>
      <option *ngFor="let terminal of terminals" value="{{terminal.name}}">{{terminal.name}}</option>
    </select>
  </div>
  <div class="col-sm-2">
    <label for="inputLifter">Lifter</label>
    <select class="form-control" id="inputLifter" name="lifterName" [(ngModel)]="filterModel.lifterName" required>
      <option *ngFor="let lifter of lifters" value="{{lifter.name}}">{{lifter.name}}</option>
    </select>
  </div>
  <div class="col-sm-2">
    <label for="inputProductStream">Product</label>
    <select class="form-control" id="inputProductStream" name="productName" [(ngModel)]="filterModel.productName"
      required>
      <option *ngFor="let product of products" value="{{product.name}}">{{product.name}}</option>
    </select>
  </div>
  <div class="col-sm-3">
    <label for="productNames">Date Range</label>
    <input type="text" placeholder="Daterangepicker" class="form-control" [(ngModel)]="dateRange" name="dateRange"
      bsDaterangepicker required>
  </div>
</div>
<div class="mt-4 mb-4 d-flex justify-content-end">
  <button class="btn btn-outline-primary mr-2" (click)="runQuery()"><i class="fa fa-search"></i> Filter</button>
  <button class="btn btn-outline-info mr-2" (click)="clearFilters()" [disabled]="isDisabledClearFilter()"><i
      class="fa fa-search"></i> Clear</button>
</div>
<div *ngIf="enableLoader" class="container">
  <div class="row">
    <div id="loader">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="lading"></div>
    </div>
  </div>
</div>
<table class="table table-hover" *ngIf="!enableLoader">
  <thead>
    <tr class="table-primary">
      <th scope="col">#</th>
      <th scope="col">Cargo ID</th>
      <!-- <th scope="col">Recieved</th> -->
      <th scope="col">Claim Recieved</th>
      <th scope="col">Total Loss</th>
      <th scope="col">Total Excess Free Water (BBL)</th>
      <th scope="col">Terminal</th>
      <th scope="col">Lifter</th>
      <th scope="col">Open</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let excessWater of filteredOpenExcessWater; index as i">
      <th scope="row">{{i + 1}}</th>
      <td>{{excessWater.export?.cargoNumber}} {{excessWater.export?.vessel?.name | uppercase}}</td>
      <!-- <td>{{excessWater?.initialClaimRecievedDate | date}}</td> -->
      <td>{{excessWater?.claimReceived | currency}}</td>
      <td>{{excessWater?.totalValueLoss | currency}}</td>
      <td>{{excessWater?.totalExcessFreeWater}}</td>
      <td>{{excessWater.export?.terminals.name | uppercase}}</td>
      <td>{{excessWater.export?.lifters.name | uppercase}}</td>
      <td>{{excessWater.dateCreated | timeAgo}}</td>
      <td>
        <span class="badge badge-danger" (click)="openExcessWaterClaimModal(confirmationModalDelete, excessWater.id)"><i
            class="fa fa-trash"></i></span>
        <span class="badge badge-success"
          [routerLink]="['/excess-water-detail/',excessWater.id,excessWater.exportId]"><i
            class="fa fa-info-circle"></i></span>
      </td>
    </tr>
  </tbody>
</table>
<div class="row" *ngIf="!enableLoader">
  <div class="col-xs-12 col-12">
    <div class="d-flex justify-content-end">
      <pagination [boundaryLinks]="true" [totalItems]="pagination.totalItems" [itemsPerPage]="pagination.itemsPerPage"
        [(ngModel)]="pagination.currentPage" (pageChanged)="pageChanged($event)" previousText="&lsaquo;"
        nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;" [maxSize]="10">
      </pagination>
    </div>
  </div>
</div>
<ng-template #confirmationModalDelete>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Confirm Delete Action</h4>
  </div>
  <div class="modal-body">
    Are you absolutely sure you want to delete this excess water claim<br>
    <button (click)="deleteExcessWaterClaim()" type="button" class="btn btn-danger">Delete</button>
  </div>
</ng-template>