<div class="list-group mt-3">
    <a href="#" class="list-group-item list-group-item-action flex-column align-items-start active">
      <div class="d-flex w-100 justify-content-between">
      </div>
    </a>

    <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">Pricing Information</h5>
        <!-- <small class="text-muted">3 days ago</small> -->
      </div>
      <!-- <small class="text-muted">NNPC Fiscal Price : {{export?.fiscalPrice | currency}} |</small>
      <small class="text-muted">NMA Sales Price = {{export?.NMASalesPrice | currency}} |</small>
      <small class="text-muted">CPC Sales Price : {{export?.CPCSalesPrice | currency}} |</small> -->
      <p class="mb-1">NNPC Fiscal Price : <strong>{{export?.fiscalPrice | currency:'USD'}}</strong></p>
      <p class="mb-1">NMA Sales Price : <strong>{{export?.nmaSalesPrice | currency:'USD'}}</strong></p>
      <p class="mb-1">CPC Sales Price : <strong>{{export?.cpcSalesPrice | currency:'USD'}}</strong></p>
    </a>
    <!-- <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">Loss Claim Information</h5>
        <small class="text-muted">3 days ago</small>
      </div>
      <small class="text-muted">Total Allowable Lay time : 36.00hr |</small>
      <small class="text-muted">Total Actual Laytime = 48.7 hrs |</small>
      <small class="text-muted">Total Excess Laytime : 20.5 hrs |</small>
      <small class="text-muted">Vessel Demurrage Rate/Hr : $1,500.00 |</small>
      <p class="mb-1">Total Demurrage : <strong>{{export?.lossClaimCost | currency:'USD'}}</strong></p>
    </a>
    <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">Outturn Information</h5>
        <small class="text-muted">3 days ago</small>
      </div>
      <small class="text-muted">Total Allowable Lay time : 36.00hr |</small>
      <small class="text-muted">Total Actual Laytime = 48.7 hrs |</small>
      <small class="text-muted">Total Excess Laytime : 20.5 hrs |</small>
      <small class="text-muted">Vessel Demurrage Rate/Hr : $1,500.00 |</small>
      <p class="mb-1">Total Demurrage : <strong>{{export?.outterunCost | currency:'USD'}}</strong></p>
    </a> -->
  </div>

  <div class="mt-5">
    <ul class="list-group">
      <li class="list-group-item d-flex justify-content-between align-items-center">
        NXP Number : {{ export?.nxpNumber}}
      </li>
      <li class="list-group-item d-flex justify-content-between align-items-center">
        NESS PayReq ID : {{export?.nessNumber}}
      </li>
      <li class="list-group-item d-flex justify-content-between align-items-center">
        CCI Number : {{export?.cciNumber}}
      </li>
    </ul>
  </div>
