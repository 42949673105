import { Component, OnInit, Input } from '@angular/core';
import { ExportService } from '../_services/export.service';
import { AlertifyService } from '../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Export } from '../_models/export';
import { ExportNode } from '../_models/exportnode';

@Component({
  selector: 'app-export-detail-update-actions',
  templateUrl: './export-detail-update-actions.component.html',
  styleUrls: ['./export-detail-update-actions.component.css']
})
export class ExportDetailUpdateActionsComponent implements OnInit {
  @Input() export: Export;
  nodes: Node[];
  exportId: number;
  nodeElementId: number;
  nodeStatusId: number;
  exportnode: ExportNode[];

  constructor(private exportService: ExportService, private alertify: AlertifyService,
    private route: ActivatedRoute, private nav: Router) { }

  ngOnInit() {
    this.loadNodes();
    this.loadExportNode(this.export['id']);
  }

  loadNodes() {
    this.exportService.getNodes().subscribe((nodes: Node[]) => {
      this.nodes = nodes;
    }, error => {
      this.alertify.error(error);
    });
  }

  loadExportNode(eId) {
    this.exportService.getExportNodes(eId).subscribe(
      (exportnodes: ExportNode[]) => {
        this.exportnode = exportnodes;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }

}
