<footer class="row mt-5">
  <div class="col-xs-12 mt-5">
    <ul class="list-inline">
      <li>Last update: 3 March 2023</li>
      <li>2001 - 2023 Chevron Corporation</li>
      <li>Company Confidential</li>
      <li>OpCo : <a href="http://pgpa.chevron.com/">NMA Suppy Group</a></li>
    </ul>
    <ul class="list-inline">
      <li>Content contact: <a href="mailto:l9lek427@chevron.com">NMA Supply Team</a></li>
      <li>Technical contact: <a href="mailto:mhuj@chevron.com">AGBOGO Michael A</a></li>
    </ul>
  </div>
</footer>

