<div>
  <div id="contentToConvert">
      <div class="row">
          <div class="col-md-12 d-flex justify-content-center mt-3">
                <!-- <h3>{{export.terminals.name}}</h3> -->
                <h3>{{exportObject?.terminals.name}} TERMINAL</h3>
          </div>
          <div class="col-md-12 d-flex justify-content-center">
                <h6>LAYTIME AND DEMURRAGE CALCULATION WORK SHEET</h6>
          </div>
          <div class="col-md-12 d-flex justify-content-center mt-3">
            <h4>{{ export?.cargoNumber }} - {{ export?.lifters.label }}</h4>
          </div>
      </div>
        <hr>

      <div class="row">
            <div class="col-md-4">
              <div class="card">
                <img class="card-img-top img-thumbnail rounded" src="{{export.lifters.lifterPhotoUrl}}" height="150" width="170" alt="Card image cap">
                <div class="card-body">
                  <h5 class="card-title">{{export?.vessel['name'] | uppercase}} | {{export?.bolDate | date}}</h5>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">SDWT (MT) : {{export?.vessel['dwt'] | uppercase}}</li>
                  <li class="list-group-item">BOL NET : {{export.actualCargo | number}}</li>
                  <li class="list-group-item">LAYCAN: {{export?.dateRangeStart | date:' d MMM'}} - {{export?.dateRangeEnd | date:'d MMM, y'}}</li>
                  <li class="list-group-item">Allowed Laytime : {{ "1.12:00:00"  | timespanToHours}}</li>
                  <li class="list-group-item">WS : {{demurrageObject.wsRate | currency}}</li>
                  <li class="list-group-item">AFRA : {{demurrageObject.afraRate | currency}}</li>
                  <li class="list-group-item">Contract Rate : {{demurrageObject.shipRate | currency}}</li>
                  <!-- <li class="list-group-item">CLAIM VALUE : {{demurrageObject.wsRate | currency}}</li> -->
                </ul>
              </div>
            </div>
            <div class="col-md-8">
              <div class="col-md-12 d-flex justify-content-center">
                <h5>LAYTIME TIME CALCULATION</h5>
              </div>
              <div class="row">
                <div class="alert alert-primary col-md-5" role="alert">
                  Vessel Arrived
                </div>
                <div class="col-md-5 alert alert-secondary" role="alert">
                  {{export.eta | date:'medium'}}
                </div>
              </div>
              <div class="row">
                <div class="alert alert-primary col-md-5" role="alert">
                  Notification of Arrival Tendered (NOR)
                </div>
                <div class="col-md-5 alert alert-secondary" role="alert">
                  {{ demurrageObject.notificationOfArrival  | date:'medium'}}
                </div>
              </div>
              <!-- <div class="row">
                <div class="alert alert-primary col-md-6" role="alert">
                  All Fast in Berth
                </div>
                <div class="col-md-6 alert alert-secondary" role="alert">
                  2018-09-09 23:00
                </div>
              </div> -->
              <div class="row">
                <div class="alert alert-primary col-md-5" role="alert">
                  Laytime Begins
                </div>
                <div class="col-md-5 alert alert-secondary" role="alert">
                  {{demurrageObject.layTimeStart | date:'medium'}}
                </div>
              </div>
              <div class="row">
                <div class="alert alert-primary col-md-5" role="alert">
                  Laytime Ends
                </div>
                <div class="col-md-5 alert alert-secondary" role="alert">
                  {{ demurrageObject.completeDisconnectingHose | date:'medium'}}
                </div>
              </div>
              <div class="row mt-3">
                <div class="alert alert-info col-md-5" role="alert">
                  Total Lay Time Used
                </div>
                <div class="col-md-5 alert alert-secondary" role="alert">
                  {{demurrageObject.totalLaytimeUsed | timespanToHours}}
                </div>
              </div>
              <div class="col-md-12 d-flex justify-content-center">
                <h5>DEDUCTIONS FROM LAYTIME</h5>
              </div>
              <div class="row">
                <div class="alert alert-secondary col-md-4" role="alert">
                  
                </div>
                <div class="col-md-3 alert alert-secondary" role="alert">
                  From
                </div>
                <div class="col-md-3 alert alert-secondary" role="alert">
                  To
                </div>
                <div class="col-md-2 alert alert-secondary" role="alert">
                  
                </div>
              </div>
              <div class="row">
                <div class="alert alert-primary col-md-4" role="alert">
                  Pilot On Board - Completed Mooring
                </div>
                <div class="col-md-3 alert alert-secondary" role="alert">
                  {{ demurrageObject.pilotOnBoard | date:'medium'}}
                </div>
                <div class="col-md-3 alert alert-secondary" role="alert">
                  {{demurrageObject.completedMooring | date:'medium'}}
                </div>
                <div class="col-md-2 alert alert-secondary" role="alert">
                  {{demurrageObject.pilotOnBoardNetCompletedMooring | timespanToHours}}
                </div>
              </div>
              <div class="row">
                <div class="alert alert-primary col-md-4" role="alert">
                  Cargo Paper Start - Cargo Paper Completed
                </div>
                <div class="col-md-3 alert alert-secondary" role="alert">
                  {{ demurrageObject.cargoPaperStart | date:'medium'}}
                </div>
                <div class="col-md-3 alert alert-secondary" role="alert">
                  {{demurrageObject.cargoPaperCompleted | date:'medium'}}
                </div>
                <div class="col-md-2 alert alert-secondary" role="alert">
                  {{demurrageObject.cargoPaperDocumentation | timespanToHours}}
                </div>
              </div>
              <div class="row" *ngIf="demurrageObject.hasWeatherDelay">
                <div class="alert alert-primary col-md-4" role="alert">
                  Awaiting Weather
                </div>
                <div class="col-md-3 alert alert-secondary" role="alert">
                  {{demurrageObject.weatherDelayStart | date:'medium'}}
                </div>
                <div class="col-md-3 alert alert-secondary" role="alert">
                  {{demurrageObject.weatherDelayEnd | date:'medium'}}
                </div>
                <div class="col-md-2 alert alert-secondary" role="alert">
                  {{demurrageObject.weatherRelatedDeduction | timespanToHours}}
                </div>
              </div>
              
              <div class="row" *ngIf="demurrageObject.hasEquipmentBreakDown">
                <div class="alert alert-primary col-md-4" role="alert">
                  Equipment Break Down (50%)
                </div>
                <div class="col-md-3 alert alert-secondary" role="alert">
                  {{demurrageObject.equipmentBreakDownStart | date:'medium'}}
                </div>
                <div class="col-md-3 alert alert-secondary" role="alert">
                  {{demurrageObject.equipmentBreakDownEnds | date:'medium'}}
                </div>
                <div class="col-md-2 alert alert-secondary" role="alert">
                  {{demurrageObject.equipmentBreakDownDeduction | timespanToHours}}
                </div>
              </div>
              <div class="row">
                <div class="alert alert-primary col-md-4" role="alert">
                  Ports Authorities on Board - Free Pratique Granted
                </div>
                <div class="col-md-3 alert alert-secondary" role="alert">
                  {{demurrageObject.portAuthorityOnBoard | date:'medium'}}
                </div>
                <div class="col-md-3 alert alert-secondary" role="alert">
                  {{demurrageObject.freePratiqueGranted | date:'medium'}}
                </div>
                <div class="col-md-2 alert alert-secondary" role="alert">
                  {{demurrageObject.portAuthorityOnBoardLessFreePratiqueGranted | timespanToHours}}
                </div>
              </div>
              <div class="row mt-3">
                <div class="alert alert-info col-md-4" role="alert">
                  Total Deductions From Laytime : 
                </div>
                <div class="col-md-8 alert alert-secondary" role="alert">
                  {{demurrageObject.deductions | timespanToHours}}
                </div>
              </div>
              <div class="col-md-12 d-flex justify-content-center">
                <h5>SUMMARY</h5>
              </div>
              <div class="row">
                <div class="alert alert-primary col-md-6" role="alert">
                  Used Laytime
                </div>
                <div class="col-md-6 alert alert-secondary" role="alert">
                 {{demurrageObject.totalLaytimeUsed | timespanToHours}}
                </div>
              </div>
              <div class="row">
                <div class="alert alert-success col-md-6" role="alert">
                  Laytime Allowed
                </div>
                <div class="col-md-6 alert alert-secondary" role="alert">
                 {{ "1.12:00:00"  | timespanToHours}}
                </div>
              </div>
              <div class="row">
                <div class="alert alert-danger col-md-6" role="alert">
                  Total Excess Laytime Used : 
                </div>
                <div class="col-md-6 alert alert-secondary" role="alert">
                 {{demurrageObject.netDemurrage | timespanToHours}}
                </div>
              </div>
              <div class="row">
                <div class="alert alert-info col-md-6" role="alert">
                  Demurrage [Contract Rate*Excess Used Laytime (days)]:
                </div>
                <div class="col-md-6 alert alert-secondary" role="alert">
                 {{demurrageObject.totalDemurrageAmount | currency}}
                </div>
              </div>
            </div>
          </div>
          <div class="row d-flex justify-content-end">
              <button type="button" class="btn btn-outline-primary btn-lg mr-2" value="CPTURE" (click)="captureScreen()"><i class="fa fa-download"></i> Download</button>
              <!-- <button type="button" class="btn btn-outline-primary btn-lg"><i class="fa fa-print"></i> Print</button>
              <button type="button" class="btn btn-outline-primary btn-lg"><i class="fa fa-envelope"></i> Email</button> -->
          </div>
    </div>
</div>
