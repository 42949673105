import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/_services/auth.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ExportService } from 'src/app/_services/export.service';
import { User } from 'src/app/_models/user';
import { PaginatedResult, Pagination } from 'src/app/_models/pagination';
import { UserRole } from 'src/app/_models/UserRole';

@Component({
  selector: 'app-admin-user',
  templateUrl: './admin-user.component.html',
  styleUrls: ['./admin-user.component.css']
})
export class AdminUserComponent implements OnInit {

  modalRef: BsModalRef;
  userToDelete: number;
  usersList: User[];
  enableLoader =true;
  public userForm = {
    id :null,
    username: null,
    role: null,
    password: null,
    confirmPassword: null
  };
  userRoles: UserRole[];

  constructor(private modalService: BsModalService,
    private authService: AuthService,
    private alertify: AlertifyService,
    private exportService: ExportService) { }

  ngOnInit() {
    this.pageNumber = 1;
    this.pageSize = 10;
    this.loadUsers(this.pageNumber,this.pageSize);
    this.loadRoles();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }


  openEditModal(template: TemplateRef<any>, id: number) {
   
    if(id != null) {
      this.authService.getUserById(id).subscribe(
        (user: any) => {
          this.userForm.id = user.id;
          this.userForm.username = user.username;
          this.userForm.role = user.roleId;
        
        }, error => {
          this.alertify.error('Unable to Load User');
        }
      );
    }
    this.modalRef = this.modalService.show(template);
  }
  openDeleteModal(template: TemplateRef<any>, id: number) {
    this.modalRef = this.modalService.show(template);
    this.userToDelete = id;
  }
  submitAddUserForm() {
    if(this.userForm.password === this.userForm.confirmPassword) {
      console.log("UserData",this.userForm);
      this.authService.register(this.userForm).subscribe(
        () => {
          this.alertify.success('New User Successfully Added');
          this.loadUsers(this.pageNumber,this.pageSize);
        }, error => {
          this.alertify.error('Unable to Add new User');
        }
      );
    } else {
      this.alertify.error('Password Does not Match');
    }
  }

  submitEditUserForm() {
    if(this.userForm.username != null || this.userForm.role != null) {
    
      //passing dummy datas
      this.userForm.password = "nullnull";
      this.authService.updateUser(this.userForm).subscribe(
        () => {
          this.alertify.success('User Successfully Updated.');
          this.loadUsers(this.pageNumber,this.pageSize);
        }, error => {
          this.alertify.error('Unable to update User');
        }
      );
    } else {
      this.alertify.error('Please enter all the required fields');  
    }
  }
  deleteUser() {

    this.exportService.deleteUser(this.userToDelete).subscribe(
      () => {
        this.alertify.success('User Successfully Deleted');
        this.loadUsers(this.pageNumber,this.pageSize);
        this.modalRef.hide();
      }, error => {
        this.alertify.error(error);
      }
    );
  }
  closeAddUserForm() {
    this.modalRef.hide();
  }
  closeEditUserForm() {
    this.modalRef.hide();
  }
  loadUsers(pn: any, ps: any) {
    this.exportService.getAllAdminUsers(pn, ps).subscribe(
      (users: PaginatedResult<User[]>) => {
        this.usersList = users.result;
        this.pagination = users.pagination;
        this.enableLoader = false;
      }, error => {
        this.alertify.error('Unable to Load Users');
        this.enableLoader=false;
      }
    );
  }

   //Pagination Variables
   pagination: Pagination;
   pageNumber: number;
   pageSize: number;
   pageChanged(event: any): void {
     this.pagination.currentPage = event.page;
     this.reloadUsers();
   }
 
   reloadUsers() {
     this.exportService.getAllAdminUsers(
       this.pagination.currentPage,
       this.pagination.itemsPerPage
     ).subscribe(
       (users: PaginatedResult<User[]>) => {
        this.usersList = users.result;
        this.pagination = users.pagination;
        this.enableLoader = false;
       },
       (error) => {
         this.alertify.error("Unable to Load Users");
         this.enableLoader = false;
       }
     );
   }


   loadRoles() {
    this.authService.getUserRole().subscribe(
      (roles: UserRole[]) => {
        this.userRoles = roles;
      },
      error => {
        this.alertify.error('Unable to Load user roles');
      }
    );
  }
}
