import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  urlDate(value: Date) {
    if (value == null) {
      return null;
    } else {
      const sYY = value.getFullYear();
      const sMM = value.getMonth() + 1;
      const sDD = value.getDate();

      const sdToReturn = sYY + '/' + sMM + '/' + sDD;
      return sdToReturn;
    }
  }

  documentDate(value: Date) {
    const newDate = new Date(value);
    const dd = newDate.getDate();
    const mm = newDate.getMonth();
    const yyyy = newDate.getFullYear();
    const months = ['January', 'February', 'March', 'April', 'May', 'June'
      , 'July', 'August', 'September', 'October', 'November', 'December'];

    const returnDate = months[mm] + ' ' + dd + ', ' + yyyy;
    return returnDate;
  }

  documentDateRange(startDate: Date, endDate: Date) {
    const stDate = new Date(startDate);
    const st_dd = stDate.getDate();
    const st_mm = stDate.getMonth();
    const st_yyyy = stDate.getFullYear();

    const edDate = new Date(endDate);
    const ed_dd = edDate.getDate();
    const ed_mm = edDate.getMonth();
    const ed_yyyy = edDate.getFullYear();

    const months = ['January', 'February', 'March', 'April', 'May', 'June'
      , 'July', 'August', 'September', 'October', 'November', 'December'];

    if (st_yyyy === ed_yyyy){
      if (st_mm === ed_mm){
        return months[st_mm] + ' ' + st_dd + '-' + ed_dd + ', ' + st_yyyy;
      }else{
        return months[st_mm] + ' ' + st_dd + '-' + months[ed_mm] + ' '  + ed_dd + ', ' + st_yyyy;
      }
    }else{
      return months[st_mm] + ' ' + st_dd+ ', ' + st_yyyy + '-' + months[ed_mm] + ' ' + ed_dd + ', ' + ed_yyyy;
    }
  }
  currentDateForDocument() {
    const today = new Date();
    const dd = today.getDate();
    const mm = today.getMonth();
    const yyyy = today.getFullYear();
    const months = ['January', 'February', 'March', 'April', 'May', 'June'
      , 'July', 'August', 'September', 'October', 'November', 'December'];

    const returnDate = months[mm] + ' ' + dd + ', ' + yyyy;
    return returnDate;
  }

}
