<div class="breadcrumb">
    <div class="col-sm-4">
        <label>Vessel Name</label>
        <input [(ngModel)]="customSelected" [typeahead]="vesselNames" typeaheadOptionField="name"
        class="form-control">
  </div>
  <div class="col-sm-3">
      <label for="inputTerminal">Terminal</label>
        <select class="form-control" id="inputTerminal" name="terminalName" [(ngModel)]="terminalName"
          required>
          <option *ngFor="let terminal of terminals" value="{{terminal.name}}">{{terminal.name}}</option>
        </select>
  </div>
  <div class="col-sm-3">
      <label for="inputLifter">Lifter</label>
      <select class="form-control" id="inputLifter" name="lifterName" [(ngModel)]="lifterName"  required>
        <option *ngFor="let lifter of lifters" value="{{lifter.name}}">{{lifter.name}}</option>
      </select>
</div>
<div class="col-sm-2">
    <label for="inputProductStream">Product</label>
    <select class="form-control" id="inputProductStream" name="productName" [(ngModel)]="productName"
      required>
      <option *ngFor="let product of products" value="{{product.name}}">{{product.name}}</option>
    </select>
</div>
<div class="col-sm-3">
  <label for="productNames">Date Start</label>
  <input type="text"
    placeholder="Daterangepicker"
    class="form-control"
    [(ngModel)]="dateStart" (bsValueChange)="onDateStartChange($event)"
    name="dateStart" [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY' }"
    bsDatepicker>
</div>
<div class="col-sm-3">
  <label for="productNames">Date End</label>
  <input type="text"
    placeholder="Daterangepicker"
    class="form-control"
    [(ngModel)]="dateEnd" (bsValueChange)="onDateEndChange($event)"
    name="dateEnd" [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY' }"
    bsDatepicker>
</div>
  <div class="col-sm-3">
          <label for="inputProductStream">Cargo Number</label>
          <input [(ngModel)]="selectedCargoNumber" [typeahead]="nessPayableArray" typeaheadOptionField="cargoNumber"
                  class="form-control"
                    (typeaheadOnSelect)="onSelectCargoNumber($event)">
  </div>


</div>
<div class="mt-4 d-flex justify-content-end">
    <button class="btn btn-outline-primary mr-2" (click)="runQuery()"><i class="fa fa-search"></i> Search</button>
    <button class="btn btn-outline-info mr-2" (click)="clearFileter()"><i class="fa fa-search"></i> Clear</button>
    <button class="btn btn-outline-danger"><i class="fa fa-save"></i> Save Filter</button>
</div>
<div *ngIf="enableLoader" class="container">
    <div class="row">
      <div id="loader">
          <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="lading"></div>
      </div>
    </div>
</div>

<div *ngIf="!enableLoader" class="mt-3">
  <table class="table table-hover">
    <thead>
      <tr class="table-primary">
        <th scope="col">#</th>
        <th scope="col">Cargo#</th>
        <th scope="col">Vessel - Terminal</th>
        <!-- <th scope="col">Terminal</th> -->
        <!-- <th scope="col">Product</th> -->
        <th scope="col">Lifter</th>
        <th scope="col">BOL Figure</th>
        <th scope="col">BOL Date</th>
        <th scope="col">Fiscal Price</th>
        <th scope="col">NESS Rate</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let nessObj of nessPayableArray"
        [class.table-default]= "nessObj?.fiscalPrice == 0"
        [class.table-success]= "nessObj?.fiscalPrice > 0 && nessObj?.actualCargo > 0 && nessObj?.nxpNumber != null"
        >
        <th scope="row">{{nessObj.id}}</th>
        <td>{{nessObj.cargoNumber}}</td>
        <td>{{nessObj.vessel.name}} | {{nessObj.terminals?.name}}</td>
        <!-- <td></td> -->
        <!-- <td>{{nessObj.products?.name}}</td> -->
        <td>{{nessObj.lifters?.name}} 
          <p>{{nessObj?.nxpNumber}}</p>
        </td>
        <td>{{nessObj.actualCargo | number}}</td>
        <td>{{nessObj.bolDate | date}}</td>
        <td>{{nessObj.fiscalPrice | currency}}</td>
        <td>{{nessTax?.rate}}%</td>
        <td>
          <div class="btn-group" dropdown>
            <button id="button-basic" dropdownToggle type="button" class="btn btn-outline-danger btn-sn dropdown-toggle" aria-controls="dropdown-basic">
              Action <span class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem"><a class="dropdown-item" (click)="openModal(upDateFiscalPriceModal,nessObj)">Update Fiscal Price</a></li>
              <li role="menuitem"><a class="dropdown-item" (click)="openBOLModal(updateBOLModal,nessObj)">Update BOL Details</a></li>
              <li role="menuitem"><a class="dropdown-item" (click)="openNXPModal(updateNXPModal,nessObj)">Update NXP</a></li>
              <!-- <li role="menuitem"><a class="dropdown-item" href="#">Update Exchange Rate</a></li> -->
              <li *ngIf="nessObj?.fiscalPrice > 0 && nessObj?.actualCargo > 0 && nessObj?.nxpNumber != null" class="divider dropdown-divider"></li>
              <li *ngIf="nessObj?.fiscalPrice > 0 && nessObj?.actualCargo > 0 && nessObj?.nxpNumber != null" role="menuitem"><a class="dropdown-item" (click)="GenerateNess(nessObj)">Generate NESS</a>
              </li>
            </ul>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="d-flex justify-content-end" *ngIf="!enableLoader">
    <pagination [boundaryLinks]="true" 
      [totalItems]="pagination.totalItems" 
      [itemsPerPage]="pagination.itemsPerPage"
      [(ngModel)]="pagination.currentPage"
      (pageChanged)="pageChanged($event)"
      previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;" [maxSize]="10">
    </pagination>
  </div>
</div>

<div class="mt-4 ml-1" id="contentToConvert" *ngIf="initalNessGenerated">
  <h5>NESS Batch Summary</h5>
    <table class="table table-bordered">
        <thead>
          <tr class="font-weight-bold table-primary">
            <th scope="col align-middle">Lifter</th>
            <!-- <th scope="col align-middle">Vessel Name</th> -->
            <!-- <th scope="col align-top">BOL Date</th> -->
            <th scope="col align-center">BOL Volume</th>
            <!-- <th scope="col align-middle">Fiscal Price($)</th> -->
            <th scope="col align-middle">Fx Rate</th>
            <th scope="col align-middle">NESS Rate</th>
            <!-- <th scope="col align-top">Total(USD)</th> -->
            <!-- <th scope="col align-middle">Total(NGN)</th> -->
            <th scope="col align-middle"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let calcNessObj of calculatedNESSArray">
            <td class="font-weight-light">{{calcNessObj.lifter.name | uppercase}} | {{calcNessObj.vessel.name | uppercase}} |  {{calcNessObj.bolDate | date: 'MMM dd, yyy'}} 
              NXP :  {{calcNessObj.nxpNumber}}</td>
            <!-- <td class="font-italic"></td> -->
            <!-- <td></td> -->
            <td>{{calcNessObj.bolFigure | number}} BBL.  @{{calcNessObj.fiscalPrice | currency}} = {{calcNessObj.totalCargoValueUSD | currency}}</td>
            <!-- <td></td> -->
            <td>@{{calcNessObj.exchangeRate | currency: 'N'}} = {{calcNessObj.totalCargoValueNGN | currency: 'N'}}</td>
            <td>@{{calcNessObj.nessRate }} = {{calcNessObj.nessPayable | currency: 'N'}}</td>
            <!-- <td></td> -->
            <!-- <td></td> -->
            <!-- <td></td> -->
            <td (click)="DeleteNessRow(calcNessObj)"><i class="fa fa-trash"></i></td>
          </tr>
        </tbody>
      </table>
</div>
<div>
  <button *ngIf="initalNessGenerated" class="btn btn-outline-danger mr-2" (click)="processNESS(calculatedNESSArray)">Process Ness</button>
  <button *ngIf="initalNessGenerated" class="btn btn-outline-danger ml-2" (click)="captureScreen()">Download</button>
</div>

<ng-template #upDateFiscalPriceModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Update Fiscal Price</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
          <label for="staticEmail" class="col-sm-4 col-form-label">Cargo Number</label>
          <div class="col-sm-8">
            <input type="text" readonly="" class="form-control-plaintext" id="staticEmail" value="{{exportNumber}}">
          </div>
    </div>
    <div class="form-group row">
          <label for="staticEmail" class="col-sm-4 col-form-label">Vessel Name</label>
          <div class="col-sm-8">
            <input type="text" readonly="" class="form-control-plaintext" id="staticEmail" value="{{vesselName}}">
          </div>
    </div>
    <div class="form-row">
        <label for="exampleInputEmail1">Enter Fiscal Price for Cargo</label>
        <input type="text" class="form-control" [(ngModel)]="fiscalPrice" name="fiscalPrice"  placeholder="Enter Fiscal Price">
    </div>
    <button type="button" class="btn btn-outline-danger mt-2" (click)="updateFiscalPrice()">Update Fiscal Price</button>
  </div>
</ng-template>

<ng-template #updateBOLModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Update BOL Figures</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-row">
        <label for="exampleInputEmail1">BOL Date</label>
        <input type="text" class="form-control" [(ngModel)]="bolModel.bolDate" name="bolModel.bolDate"
        [bsConfig]="{ dateInputFormat: 'YYYY/MM/DD' }" required
        bsDatepicker>
    </div>
    <div class="form-row">
        <label for="exampleInputEmail1">BOL Figures</label>
        <input type="text" class="form-control" [(ngModel)]="bolModel.bolFigure" name="bolModel.bolFigure">
    </div>
    <button type="button" class="btn btn-outline-danger mt-2" (click)="updateBOLFigures()">Update BOL Figures</button>
  </div>
</ng-template>

<ng-template #updateNXPModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Update NXP Number</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-row">
        <label for="exampleInputEmail1">NXP Number</label>
        <input type="text" class="form-control" [(ngModel)]="selectedNXP" name="selectedNXP">
    </div>
    <button type="button" class="btn btn-outline-danger mt-2" (click)="updateNXPValue()">Update NXP</button>
  </div>
</ng-template>
