import { Component, OnInit } from "@angular/core";
import { AuthService } from "../_services/auth.service";
import { AlertifyService } from "../_services/alertify.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-nav-chevron",
  templateUrl: "./nav-chevron.component.html",
  styleUrls: [
    "./nav-chevron.component.css",
    // "./../../assets/fav/apple-touch-icon-precomposed.png",
    "./../../assets/fonts/Gotham/gotham-fonts.css",
    "./../../assets/css/bootstrap-3.3.4.chevron.css",
    "./../../assets/css/rte-shared.css",
    "./../../assets/css/site.css",
    './../../assets/css/rte-shared.css',
    "./../../assets/css/modules/alert-shim.css",
    "./../../assets/css/modules/link-bar.css",
    "./../../assets/css/modules/navbar.css",
    "./../../assets/css/modules/footer.css",
    "./../../assets/css/modules/search-bar.css",
    "./../../assets/css/modules/side-nav.css",
    "./../../assets/css/modules/tabbed-content-control.css",
    "./../../assets/css/modules/table.css",
    "./../../assets/css/modules/tag.css",
    "./../../assets/css/modules/thumbnail-promo.css"
  ]
})
export class NavChevronComponent implements OnInit {
  model: any = {};

  constructor(public authService: AuthService, private alertify: AlertifyService, private router: Router) { }

  ngOnInit() {
  }

  login() {
    this.authService.login(this.model)
      .subscribe(next => {
        this.alertify.success('Login was successful');
      }, error => {
        this.alertify.error(error);
      }, () => {
        this.router.navigate(['/dash-board']);
      });
  }

  loggedIn() {
    return this.authService.loggedIn();
  }

  logout() {
    localStorage.removeItem('token'); // remove token from application
    this.alertify.message('You have logged Out');
    this.router.navigate(['/home']);
  }
}
