import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { ExportService } from '../_services/export.service';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DocumentService } from '../_services/document.service';

@Injectable()
export class DocumentListResolver implements Resolve<Document[]> {
    pageNumber = 1;
    pageSize = 10;

    constructor(private exportService: ExportService, private alertify: AlertifyService
        , private route: Router, private documentService: DocumentService) {}
    resolve(route: ActivatedRouteSnapshot): Observable<Document[]> {
        return this.documentService.getAllExportDocuments(this.pageNumber, this.pageSize).pipe(
            catchError( error => {
                this.alertify.error('Unable to load Document Details');
                this.route.navigate(['dashboard']);
                return of(null);
            })
        );

    }
}