<div class="mt-2">
  <div class="row">
    <div class="col-md-2">
        <div class="row">
          <div class="input-group">
              <input type="text" class="form-control" placeholder="Terminal Name"
               [(ngModel)]="enteredTerminal" name="enteredTerminal" (keyup.enter)="addNewTerminal(enteredTerminal)">
               <div class="input-group-append">
                  <button class="btn btn-info" (click)="addNewTerminal(enteredTerminal)"> Save</button>
               </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="list-group">
                  <a  *ngFor="let temrinal of terminals" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                      {{temrinal.name | uppercase}} 
                      <span class="badge badge-danger badge-pill"><i class="fa fa-trash"></i> Delete</span>
              </a>
                  
            </div>
        </div>
    </div>
    <div class="col-md-3 ml-2">
      <div class="row">
        <div class="input-group">
            <input [(ngModel)]="selectedLifter"
            [typeahead]="lifters"
            typeaheadOptionField="name"
            (typeaheadOnSelect)="onSelectLifter($event)"
            placeholder="Lifter Name"
            class="form-control">
        </div>
      </div>
      <div *ngIf="showLifter" class="row mt-2">
          <!-- <pre class="card card-block card-header mb-3">{{selectedOption.name != null}}</pre> -->
          <div class="card">
            <div class="card-header">
              Selected Lifter : {{selectedOption.name}}
            </div>
            <div class="card-body">
              <p>Name : {{selectedOption.name}}</p>
              <p>Label : {{selectedOption.label}}</p>
              <p>Terminal : {{selectedOption.terminals?.name}}</p>
            </div>
            <div class="card-footer">
              <div class="btn-group d-flex">
                  <button (click)="openUpdateLifterModal(updateLifterModal)" type="button" class="btn btn-info w-100">Update</button>
                  <button (click)="closeLifterModal()" type="button" class="btn btn-danger w-100">Close</button>
              </div>
            </div>
          </div>
      </div>
    </div>

    <div class="col-md-3 ml-2">
        <div class="row">
          <div class="input-group">
              <input [(ngModel)]="cargoTypeName"
              [typeahead]="cargotypes"
              typeaheadOptionField="name"
              (typeaheadOnSelect)="onSelectCargoType($event)"
              placeholder="Cargo Types"
              class="form-control">
          </div>
        </div>
        <div *ngIf="showCargoType" class="row mt-2">
            <!-- <pre class="card card-block card-header mb-3">{{selectedOption.name != null}}</pre> -->
            <div class="card">
              <div class="card-header">
                Selected Lifter : {{selectedOption.name}}
              </div>
              <div class="card-body">
                <p>Name : {{selectedCargoType.name}}</p>
                <p>Label : {{selectedCargoType.description}}</p>
                <p>Lifter : {{selectedCargoType.lifter?.name}}</p>
              </div>
              <div class="card-footer">
                <div class="btn-group d-flex">
                    <button type="button" (click)="openUpdateLifterModal(updateCargoTypeModal)"  class="btn btn-info w-100">Update</button>
                    <button type="button" class="btn btn-danger w-100">Close</button>
                </div>
              </div>
            </div>
        </div>
      </div>
  </div>
</div>

<ng-template #updateLifterModal>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Update Lifter</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form #updateLIfterForm="ngForm">
        <fieldset>
            <div class="form-group">
                <input type="text" class="form-control" placeholder="Short Name"
                [(ngModel)]="lifterForm.name" name="lifterForm.name"
                >
              </div>
              <div class="form-group">
                  <input type="text" class="form-control" placeholder="Long Name"
                  [(ngModel)]="lifterForm.label" name="lifterForm.label"
                  >
              </div>
              <div class="form-group">
                  <select class="form-control" name="terminalName" [(ngModel)]="lifterForm.terminalId"
                  required>
                  <option *ngFor="let terminal of terminals" value="{{terminal.id}}">{{terminal.name}}</option>
                </select>
              </div>
              <div class="form-group">
                  <select class="form-control" name="omlName" [(ngModel)]="lifterForm.omlId"
                  required>
                  <option *ngFor="let oml of omls" value="{{oml.id}}">{{oml.name}}</option>
                </select>
              </div>
        </fieldset>
      </form>
    </div>
    <div class="modal-footer">
      <div class="btn-group d-flex">
          <button (click)="updateLifterInformation()" type="button" class="btn btn-info w-100">Save</button>
          <button (click)="closeUpateLifterModal()" type="button" class="btn btn-danger w-100">Close</button>
      </div>
    </div>
  </ng-template>

  <ng-template #updateCargoTypeModal>
      <div class="modal-header">
        <h4 class="modal-title pull-left">Update CargoType</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #updateLIfterForm="ngForm">
          <fieldset>
              <div class="form-group">
                  <input type="text" class="form-control" placeholder="Short Name"
                  [(ngModel)]="cargoTypeForm.name" name="cargoTypeForm.name"
                  >
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Long Name"
                    [(ngModel)]="cargoTypeForm.description" name="cargoTypeForm.description"
                    >
                </div>
                <div class="form-group">
                    <select class="form-control" name="selectedLifter" [(ngModel)]="cargoTypeForm.lifterId"
                    required>
                    <option *ngFor="let lifter of lifters" value="{{lifter.id}}">{{lifter.name}}</option>
                  </select>
                </div>
                <!-- <div class="form-group">
                    <select class="form-control" name="omlName" [(ngModel)]="lifterForm.omlId"
                    required>
                    <option *ngFor="let oml of omls" value="{{oml.id}}">{{oml.name}}</option>
                  </select>
                </div> -->
          </fieldset>
        </form>
      </div>
      <div class="modal-footer">
        <div class="btn-group d-flex">
            <button (click)="updateCargoTypeInformation()" type="button" class="btn btn-info w-100">Save</button>
            <button (click)="closeUpateLifterModal()" type="button" class="btn btn-danger w-100">Close</button>
        </div>
      </div>
    </ng-template>
