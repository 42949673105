import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Export } from '../../_models/export';
import { ExportService } from '../../_services/export.service';
import { AlertifyService } from '../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '../../_services/auth.service';
import { DocumentService } from '../../_services/document.service';
import {saveAs} from 'file-saver';
import { DateService } from 'src/app/_services/date.service';
import { ExcessWater } from 'src/app/_models/excesswater';

@Component({
  selector: 'app-excess-water-detail',
  templateUrl: './excess-water-detail.component.html',
  styleUrls: ['./excess-water-detail.component.css']
})
export class ExcessWaterDetailComponent implements OnInit {

  export: Export;
  excessWater:ExcessWater;
  modalRef: BsModalRef;
  exportToDuplicateID: number ;
  //DIModel: any = {};
  NominationModel: any = {};
  fileToDownload: any = {};
  public hasFile = false;
  public filePathToDownload: string;
  public currentTab = 0 ;
  exportId: number;
  inputExportId:number;
  constructor(private exportService: ExportService,
     private alertify: AlertifyService,
     private route: ActivatedRoute,
     private modalService: BsModalService,
     private authService: AuthService,
     private router: Router,
     private documentService: DocumentService,
     private dateService: DateService,
     private nav: ActivatedRoute) { }

  ngOnInit() {
    this.exportId = this.nav.params["value"].id;
    this.inputExportId=this.nav.params["value"].exportId;
    this.loadExcessWaterDetail();
  }

  loadExcessWaterDetail(){
    this.exportService.getExcessWaterDetail(this.exportId).subscribe(
      (excesswater: ExcessWater) => {
        this.excessWater=excesswater;
        if(excesswater.exportId!=null){
          this.loadExport(excesswater.exportId);
        }
      },
      error => {
        this.alertify.error(error);
      }
    );
  }

  loadExport(exportId:any) {
    this.exportService.getExport(exportId).subscribe((exports: Export) => {
      this.export = exports;
      console.log(this.export);
    }, error => {
      this.alertify.error(error);
    });
  }

  openModal(template: TemplateRef<any>, e: any) {
    // console.log(e);
    this.exportToDuplicateID = e;
    this.modalRef = this.modalService.show(template);
  }

  closeConfirmModal() {
    this.modalRef.hide();
  }

  duplicateExport() {
    console.log(this.exportToDuplicateID);
    this.exportService.duplicateExportWithID(this.exportToDuplicateID, this.authService.decodedToken.nameid).subscribe(
      (exports: Export) => {
        this.modalRef.hide();
        this.alertify.success('Export Successfully Duplicated');
        this.router.navigate(['/dash-board']);
      }, error => {
        this.alertify.error('Problem Duplicating Export');
      }
    );
  }

  generateDocumentInstruction(lifterId: number) {
    this.getNominationValues();
    console.log('Generting Document....');

    this.documentService.generateDocumentInstructionStardeep(lifterId, this.NominationModel).subscribe(
      data => {
        console.log(data);
        saveAs(data, this.NominationModel.cargoNumber + ' DI.docx');
        this.alertify.success('Download Successful');
      }, error => {
        this.alertify.error('Unable to load files');
      }
    );
  }

  getNominationValues() {
    // console.log(this.export);
    this.NominationModel.letterDate = this.dateService.currentDateForDocument();
    this.NominationModel.dateRange = this.dateService.documentDateRange(this.export.dateRangeStart,this.export.dateRangeEnd);
    this.NominationModel.vesselName = this.export.vessel.name;
    this.NominationModel.ETA = this.dateService.documentDate(this.export.eta);
    this.NominationModel.volume = this.export.norminatedCargo;
    this.NominationModel.volumeMetricTons = this.export.norminatedCargo;
    this.NominationModel.terminal = this.export.terminals.name;
    this.NominationModel.DWT = this.export.vessel.dwt;
    this.NominationModel.NXPNumber = this.export.nxpNumber;
    this.NominationModel.destination = this.export.destinations.name;
    this.NominationModel.consignor = this.export.consignor.name;
    this.NominationModel.consignee = this.export.consignees.name;
    this.NominationModel.inspector = this.export.inspector.name;
    this.NominationModel.vesselAgent = this.export.vesselAgents.name;
    this.NominationModel.comment = this.export.comment;
    this.NominationModel.cargoNumber = this.export.cargoNumber;
    this.NominationModel.cargoType = this.export.cargoTypes['name'];
    this.NominationModel.flagOfVessel = this.export.vessel.flag;
    this.NominationModel.nxpNumber = this.export.nxpNumber;
    this.NominationModel.vesselIMO = this.export.vessel.imoNumber;
    this.NominationModel.productId = this.export.products.id;
    this.NominationModel.terminalId = this.export.terminals.id;
    console.log(this.NominationModel);
  }

  downloadFile() {
    this.fileToDownload.path = this.filePathToDownload;
    // console.log(this.filePathToDownload);
    this.documentService.downloadFileWithPath(this.fileToDownload).subscribe(
      data => {
        console.log(data);
        saveAs(data, 'Download.docx');
        this.alertify.success('Download Successful');
      }, error => {
        this.alertify.error('Unable to load files');
      }
    );
  }
  setCurrentTab(id: number){
    this.currentTab = id;
    console.log(this.currentTab);
  }
}
