import { Component, OnInit, TemplateRef } from '@angular/core';
import { ExportService } from 'src/app/_services/export.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { Ness } from 'src/app/_models/ness';
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";

import * as jspdf from "jspdf";
import html2canvas from "html2canvas";


@Component({
  selector: "app-ness-payreq",
  templateUrl: "./ness-payreq.component.html",
  styleUrls: ["./ness-payreq.component.css"]
})
export class NessPayreqComponent implements OnInit {
  modalRef: BsModalRef;
  nessArray: Ness[];
  payReqNumber: number;
  nessId: number;
  totalNessValue: number;

  constructor(
    private exportService: ExportService,
    private alertify: AlertifyService,
    private authService: AuthService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.loadNessPendingPayReq();
  }

  openModal(template: TemplateRef<any>, e: any) {
    this.nessId = e.id;
    this.modalRef = this.modalService.show(template);
  }

  loadNessPendingPayReq() {
    this.exportService.getNessPendingPayReq().subscribe(
      (ness: Ness[]) => {
        this.nessArray = ness;
        this.calculateTotalNessValue(this.nessArray);
      },
      error => {
        this.alertify.error('Unable to retrieve ness details');
      }
    );
  }
  updatePayReqNumber() {
    this.exportService.updatePayReqForNessWith(this.nessId, this.payReqNumber).subscribe(
      () => {
        this.alertify.success('PayReq Number Updated');
        this.ngOnInit();
        this.modalRef.hide();
      },
      error => {
        this.alertify.error('Unable to Update PayReq Details');
      }
    );
  }

  calculateTotalNessValue(arrayObj: Ness[]) {
    let accumulator = 0.00;
    for (let index = 0; index < arrayObj.length; index++) {
      accumulator = accumulator + arrayObj[index].nessPayable;
      console.log(accumulator);
    }
    this.totalNessValue = accumulator;
  }

  public captureScreen() {
    const data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      const pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      const position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      pdf.save('MYPdf.pdf'); // Generated PDF
    });
  }
}
