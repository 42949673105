<ol class="breadcrumb">
  <li class="breadcrumb-item">
    <a [routerLink]="['/dash-board']">Home</a>
  </li>
</ol>
<ul class="nav nav-pills">
  <li class="nav-item mr-2">
    <a class="nav-link active" (click)="lgModal.show()"><i class="fa fa-plus"></i> Create Export</a>
  </li>
    <li class="nav-item mr-2">
    <a class="nav-link active" [routerLink]="['/export/list']" ><i class="fa fa-list-ul"></i> List Export</a>
  </li>
</ul>

<div bsModal #lgModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h3 id="dialog-sizes-name1" class="modal-title pull-left">Create New Export</h3>
        <button type="button" class="close pull-right" (click)="lgModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #addExportForm="ngForm" (ngSubmit)="addExport()">
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="inputTerminal">Export Terminal</label>
              <select class="form-control" id="inputTerminal" name="terminalID" [(ngModel)]="model.terminalID" #terminalID="ngModel" (change)="updateValues()" required>
                <option *ngFor="let terminal of terminals" value="{{terminal.id}}">{{terminal.name}}</option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label for="inputCargoNumber">Unique Cargo Numger</label>
              <input type="text" class="form-control" id="inputCargoNumber" placeholder="AGB-098" name="cargoNumber" [(ngModel)]="model.cargoNumber" #cargoNumber="ngModel" required>
            </div>
            <div class="form-group col-md-4">
              <label for="inputProductStream">Select Product Stream</label>
              <select class="form-control" id="inputProductStream" name="productID" [(ngModel)]="model.productID" #productID="ngModel" required>
                <option *ngFor="let product of products" value="{{product.id}}">{{product.name}}</option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label for="inputLifter">Select Lifter</label>
              <select class="form-control" id="inputLifter" name="lifterID" [(ngModel)]="model.lifterID" #lifterID="ngModel" required>
                <option *ngFor="let lifter of lifters" value="{{lifter.id}}">{{lifter.name}}</option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label for="inputNominatedVolume">Nominated Volume</label>
              <input type="text" class="form-control" id="inputNominatedVolume" placeholder="Nominated Volume (MB)" [(ngModel)]="model.nominatedVolume" name="nominatedVolume" #nominatedVolume="ngModel" required>
            </div>
            <div class="form-group col-md-4">
              <label for="inputVesselName">Vessel Name</label>
              <input type="text" class="form-control" id="inputVesselName" placeholder="vessel Name"
                 [(ngModel)]="model.vesselName" name="vesselName" #vesselName="ngModel"
                [typeahead]="vessels"
                typeaheadOptionField="name"
                (typeaheadOnSelect)="onSelectVessel($event)"
                 >
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="inputProductStream">Cargo Type</label>
              <select class="form-control" id="inputProductStream" #cargoTypeID="ngModel" name="cargoTypeID" [(ngModel)]="model.cargoTypeID" required>
                <option *ngFor="let cargotype of cargotypes" value="{{cargotype.id}}">{{cargotype.name}}</option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label for="inputDateRange">Date Range</label>
              <input class="form-control" id="inputDateRange"  name="dateRange" #dateRange="ngModel" [(ngModel)]="model.dateRange" required  placeholder="Daterangepicker" bsDaterangepicker>
            </div>
            <div class="form-group col-md-4">
              <label for="inputETA">ETA</label>
              <input class="form-control" id="inputETA" [(ngModel)]="model.ETA"  placeholder="Datepicker" #ETA="ngModel" name="ETA" bsDatepicker>
            </div>
          </div>
          
          <div class="form-row ">
            <div class="form-group col-md-6">
              <label for="inputInspector">Inspector</label>
              <input type="text" class="form-control" id="inputInspector" [(ngModel)]="model.inspector" 
              placeholder="Enter Inspector" #inspector="ngModel" name="inspector"
               [typeahead]="InspectorList" typeaheadOptionField="name" (typeaheadOnSelect)="onSelectInspector($event)"
              >
            </div>
            <div class="form-group col-md-6">
              <label for="inputDWTofVessel">Vessel Agent</label>
              <input type="text" class="form-control" id="inputVesselAgent" [(ngModel)]="model.VesselAgentID" placeholder="Select Vessel Agent"
                #vesselAgent="ngModel" name="vesselAgent">
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputConsignee">Consignee</label>
              <select class="form-control" id="inputConsignee" #consigneeID="ngModel" name="consigneeID" [(ngModel)]="model.consigneeID">
                <option *ngFor="let consignee of consignees" value="{{consignee.id}}">{{consignee.name}}</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputDWTofVessel">Consignor</label>
              <input type="text" class="form-control" id="inputConsignor" [(ngModel)]="model.ConsignorID" placeholder="Select Consignor"
                #consignor="ngModel" name="consignor">
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputDWTofVessel">DWT of Vessel</label>
              <input type="text" class="form-control" id="inputDWTofVessel" [(ngModel)]="model.DWTofVessel" placeholder="Enter DWT of Vessel" #DWTofVessel="ngModel" name="DWTofVessel">
            </div>
            <div class="form-group col-md-6">
              <label for="inputFLAFofVessel">FLAG of Vessel</label>
              <input type="text" class="form-control" id="inputFLAFofVessel" [(ngModel)]="model.flagOfVessel" placeholder="Vessel ETA"
                #flagOfVessel="ngModel" name="flagOfVessel">
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputDestination">Destination</label>
              <input type="text" class="form-control" id="inputDestination" [(ngModel)]="model.destination" placeholder="Enter Destination"
                #destination="ngModel" name="destination" [typeahead]="destinationList" typeaheadOptionField="name" (typeaheadOnSelect)="onSelectDestination($event)">
            </div>
          </div>

          <div class="form-group">
            <label for="inputComment">Export Comment</label>
            <textarea class="form-control" id="inputComment" rows="4" #comment="ngModel" name="comment" [(ngModel)]="model.comment"></textarea>
          </div>
          
          
          <div class="modal-footer">
            <div class="form-row">
              <button type="button" class="btn btn-outline-danger btn-lg mr-2" (click)="lgModal.hide()">Close</button>
              <!-- <button type="button" class="btn btn-outline-default btn-lg mr-2" id="butClearDailyProduction">Clear Field</button> -->
              <!-- <button [disabled]="!addExportForm.valid" type="submit" class="btn btn-danger mr-1">Save changes</button> -->
              <button type="submit" class="btn btn-outline-primary btn-lg mr-2">Save changes</button>
              <!-- <button type="submit" class="btn btn-danger mr-1" (click)="confirmSave()">Save changes</button> -->
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
