<div class="container mt-2">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dash-board']">Demurrage Home</a>
    </li>
  </ol>
  <ul class="nav nav-pills">
    <li class="nav-item mr-2">
      <a class="nav-link active" (click)="toggelCreatedActivated()">
        <i class="fa fa-plus"></i> Create</a>
    </li>
    <!-- <li class="nav-item mr-2">
      <a class="nav-link active">
        <i class="fa fa-plus"></i> View Status</a>
    </li> -->
  </ul>
  <h6 class="mt-2">Demurrage Claim List</h6>
  <div>
    <app-open-demurrage-list *ngIf="!createdActivated"></app-open-demurrage-list>
  </div>
  <form class="form-group mt-3" #demurrageForm="ngForm" *ngIf="!calculationAvailable && createdActivated" >
    <!-- <pre class="card card-block card-header mb-3">Model: {{selectedValue | json}} </pre>
    <pre class="card card-block card-header mb-3">Selected option: {{selectedOption | json}}</pre> -->
    <div class="tab-panel">
      <tabset class="member-tabset">
        <tab heading="Export Details" id="tab1">
          <div class="form-row mt-3">
            <div class="form-group col-md-4">
              <label for="inputEmail4">Cargo ID</label>
              <input [(ngModel)]="selectedValue" [typeahead]="exports" typeaheadOptionField="cargoNumber" (typeaheadOnSelect)="onSelect($event)"
                #cargoID name="cargoID" class="form-control">
                <a *ngIf="hasExporRecord" [routerLink]="['/export-detail',exportID]">View Cargo Page</a>
            </div>
            <div *ngIf="hasExporRecord" class="form-group col-md-4">
                <img src="{{lifterImage}}" alt="" height="70"
                width="70">
            </div>
            <!-- <div class="form-group col-md-4">
              <button type="submit" class="btn btn-info mr-2"><i class="fa fa-plus">
                </i></button>
            </div> -->
          </div>
          <div *ngIf="hasExporRecord" class="form-row">
            <div class="form-group col-md-4">
              <label for="inputEmail4">Vessel Name</label>
              <pre class="card card-block card-header mb-2">{{vesselName | uppercase}}</pre>
            </div>
            <div class="form-group col-md-4">
              <label for="inputEmail4">BOL Date</label>
              <pre class="card card-block card-header mb-2">{{BOLDate | date}}</pre>
            </div>
            <div class="form-group col-md-4">
              <label for="inputEmail4">Lifter</label>
              <pre class="card card-block card-header mb-2">{{lifter | uppercase}}</pre>
            </div>
          </div>
          <div *ngIf="hasExporRecord" class="form-row">
            <div class="form-group col-md-4">
              <label for="inputEmail4">Terminal</label>
              <pre class="card card-block card-header mb-2">{{terminalName | uppercase}}</pre>
            </div>
            <div class="form-group col-md-6">
              <label for="inputEmail4">Laycan</label>
              <pre class="card card-block card-header mb-2">{{laycanTime | date:'medium'}}</pre>
            </div>
          </div>
          <app-demurrage-document-upload *ngIf="hasExporRecord" [export] = "export"></app-demurrage-document-upload>
          </tab>
          <tab heading="Claim">
            <app-demurrage-claim [export]="export"></app-demurrage-claim>
          </tab>
        <tab heading="Financial Detail">
          <div class="form-row mt-3">
            <div class="form-group col-md-4">
              <label for="inputEmail4">AFRA</label>
              <input type="text" class="form-control" [(ngModel)]="afraValue" placeholder="AFRA Value"
               name="afraValue" required>
            </div>
            <div class="form-group col-md-4">
              <label for="inputEmail4">WS</label>
              <input type="text" class="form-control" [(ngModel)]="wsValue" placeholder="AFRA Value" 
              name="wsValue" required>
            </div>
            <div class="form-group col-md-4">
              <label for="inputEmail4">Ship Rate</label>
              <input type="text" class="form-control" [(ngModel)]="shipRate" placeholder="Ship Rate" name="shipRate">
            </div>
          </div>
        </tab>
        <tab heading="Harbor Report Details">
          <div class="form-row mt-3">
            <div class="form-group col-md-4">
              <label for="inputEmail4">Total Volume Loaded</label>
              <input type="text" class="form-control" [(ngModel)]="totalVolumeLoaded" placeholder="Total Volume Loaded" name="totalVolumeLoaded">
            </div>
          </div>
          <div class="form-row mt-3">
            <div class="form-group col-md-4">
              <label for="inputEmail4">First Laycan Day</label>
              <input type="text" [(ngModel)]="laycanDate" placeholder="Datepicker" class="form-control" bsDatepicker 
                name="laycanDate" required
                (bsValueChange)="onLaycanValueChange($event)">
              <timepicker [(ngModel)]="laycanTime" name="laycanTime" ></timepicker>
            </div>
                        
            <div class="form-group col-md-4">
              <label for="inputEmail4">Notification of Arrival</label>
              <input type="text" [(ngModel)]="notificationOfArrivalDate" placeholder="Datepicker" class="form-control"
                bsDatepicker name="notificationOfArrivalDate" required
                 (bsValueChange)="onNotificationOfArrivalValueChange($event)">
              <timepicker [(ngModel)]="notificationOfArrivalTime" name="notificationOfArrivalTime" ></timepicker>
            </div>

            <div class="form-group col-md-4">
              <label for="inputEmail4">Laytime Start</label>
              <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker [(ngModel)]="layTimeStartDate"
                 name="layTimeStartDate" required
                (bsValueChange)="onLayTimeStartValueChange($event)">
              <timepicker name="layTimeStartTime" [(ngModel)]="layTimeStartTime" ></timepicker>
            </div>
          </div>
          <div class="form-row">
          
            <div class="form-group col-md-4">
              <label for="inputEmail4">Port Authority On Board</label>
              <input type="text" placeholder="Datepicker" class="form-control" [(ngModel)]="portAuthorityOnBoardDate"
                bsDatepicker name="portAuthorityOnBoardDate" (bsValueChange)="onPortAthourityOnBoardValueChange($event)">
              <timepicker [(ngModel)]="portAuthorityOnBoardTime" name="portAuthorityOnBoardTime" ></timepicker>
            </div>
            <div class="form-group col-md-4">
              <label for="inputEmail4">Free Pratique On Board</label>
              <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker [(ngModel)]="freePratiqueOnBoardDate"
                name="freePratiqueOnBoardDate" (bsValueChange)="onfreePratigueOnBoardValueChange($event)">
              <timepicker name="freePratigueOnBoardTime" [(ngModel)]="freePratigueOnBoardTime" ></timepicker>
            </div>
          </div>
          
          <div class="form-row">    
            <div class="form-group col-md-4">
              <label for="inputEmail4">Cargo Paper Start</label>
              <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker [(ngModel)]="cargoPaperDocStartDate" name="cargoPaperDocStartDate"
                (bsValueChange)="onCargoPaperDocStartValueChange($event)" >
              <timepicker [(ngModel)]="cargoPaperDocStartTime" name="cargoPaperDocStartTime"></timepicker>
            </div>
            <div class="form-group col-md-4">
              <label for="inputEmail4">Complete Cargo Paper</label>
              <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker [(ngModel)]="cargoPaperDocEndDate" name="cargoPaperDocEndDate"
                (bsValueChange)="onCargoPaperDocEndValueChange($event)" >
              <timepicker name="cargoPaperDocEndTime" [(ngModel)]="cargoPaperDocEndTime"></timepicker>
            </div>
          </div>
          
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="inputEmail4">Pilot on Board</label>
              <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker [(ngModel)]="pilotOnBoardDate" name="pilotOnBoardDate"
                (bsValueChange)="onPilotOnBoardValueChange($event)" required>
              <timepicker name="pilotOnBoardTime" [(ngModel)]="pilotOnBoardTime" ></timepicker>
            </div>
            <div class="form-group col-md-4">
              <label for="inputEmail4">Completed Mooring</label>
              <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker [(ngModel)]="completedMooringDate"
                name="completedMooringDate" (bsValueChange)="onCompletedMooringValueChange($event)" required>
              <timepicker name="completedMooringTime" [(ngModel)]="completedMooringTime" ></timepicker>
            </div>
            <div class="form-group col-md-4">
              <label for="inputEmail4">Completed Disconnection of Hose</label>
              <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker [(ngModel)]="completedDisconnectionOfHoseDate"
                name="completedDisconnectionOfHoseDate" (bsValueChange)="onCompletedDisconnectionOfHoseValueChange($event)" required>
              <timepicker name="completedDisconnectionOfHoseTime" [(ngModel)]="completedDisconnectionOfHoseTime" ></timepicker>
            </div>
          </div>
          <div class="form-row">
            <button type="submit" class="btn btn-outline-danger mr-2" (click)="toggelHasEquipmentFailure()"><i class="fa fa-plus"></i>Equipment
              Failure</button>
            <button type="submit" class="btn btn-outline-danger ml-2" (click)="toggleHasWeatherDelays()"><i class="fa fa-plus"></i>Weather
              Delays</button>
          </div>
          
          <div *ngIf="hasEquipmentBreakDown" class="form-row">
            <div class="form-group col-md-4 mt-2">
              <label for="inputEmail4">Equipment BreakDown Start</label>
              <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker [(ngModel)]="equipmentBreakDownStartDate"
                name="equipmentBreakDownStartDate" (bsValueChange)="onEquipmentBreakDownStartValueChange($event)">
              <timepicker name="equipmentBreakDownStartTime" [(ngModel)]="equipmentBreakDownStartTime" ></timepicker>
            </div>
            <div class="form-group col-md-4 mt-2">
              <label for="inputEmail4">Equipment BreakDown End</label>
              <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker [(ngModel)]="equipmentBreakDownEndsDate"
                name="equipmentBreakDownEndsDate" (bsValueChange)="onEquipmentBreakEndValueChange($event)">
              <timepicker name="equipmentBreakDownEndsTime" [(ngModel)]="equipmentBreakDownEndsTime" ></timepicker>
            </div>
          </div>
          <div *ngIf="hasWeatherDelay" class="form-row">
            <div class="form-group col-md-4 mt-2">
              <label for="inputEmail4">Weather Delay Start</label>
              <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker [(ngModel)]="weatherDelayStartDate"
                name="weatherDelayStartDate" (bsValueChange)="onWeatherDelayStartValueChange($event)">
              <timepicker name="weatherDelayStartTime" [(ngModel)]="weatherDelayStartTime" ></timepicker>
            </div>
            <div class="form-group col-md-4 mt-2">
              <label for="inputEmail4">Weather Delay End</label>
              <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker [(ngModel)]="weatherDelayEndDate"
                name="weatherDelayEndDate" (bsValueChange)="onWeatherDelayEndValueChange($event)">
              <timepicker name="weatherDelayEndTime" [(ngModel)]="weatherDelayEndTime" ></timepicker>
            </div>
          </div>
        </tab>
      </tabset>
    </div>
    
    <div class="form-row">
      <button type="submit"  (click)="calculateDemurrage()" [disabled]="!demurrageForm.valid" class="btn btn-outline-primary btn-lg mt-3">Calculate Demurrage</button>
    </div>
  </form>
</div>
<div *ngIf="calculationAvailable" class="container">
  <app-demurrage-worksheet-modal [returnedDemurrageModel]= "returnedDemurrageModel" [export] = "export"></app-demurrage-worksheet-modal>
</div>
