import { Component, OnInit, Input } from '@angular/core';
import { Export } from '../_models/export';

@Component({
  selector: 'app-dash-export-card',
  templateUrl: './dash-export-card.component.html',
  styleUrls: ['./dash-export-card.component.css']
})
export class DashExportCardComponent implements OnInit {
  @Input() export: Export;
  constructor() { }

  ngOnInit() {
  }

}
