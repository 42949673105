<nav class="navbar navbar-default navbar-default-subsite centered" role="navigation">
  <!-- Global navigation -->
  <div class="navbar-collapse js-navbar-collapse centered row font-gotham" id="navbar-brand-centered">
    <div class="navbar-brand navbar-brand-centered">
      <div class="hallmark-container">
        <a title="Chevron" class="hallmark" [routerLink]="['/home']"><img width="42" height="46" alt="Chevron" src="../../assets/images/hallmark.png"></a>
      </div>
      <div class="sitename-container vertical-align">
        <a class="sitename light-blue font-gotham" [routerLink]="['/home']">Lifting Operations Management System</a>
      </div>
    </div>
  </div>
</nav>

<nav class="navbar navbar-subsite centered" role="navigation" style="min-height: 46px;">
  <!-- Site-specific navigation -->
  <div class="container-fluid">
    <div class="collapse navbar-collapse js-navbar-collapse centered row font-gotham navbar-mobile dropdown-menu" id="navbar-brand-centered-subsite">
      <div class="mobile-nav-container vertical-align flex-center centered">
        <!--width-1280-->
        <div class="container">
        
          <ul class="navbar-nav mr-auto">
            <li *ngIf="loggedIn()" class="nav-item" routerLinkActive="active">
              <a class="nav-link" [routerLink]="['/export']">Export | </a>
            </li>
            <li *ngIf="loggedIn()" class="nav-item" routerLinkActive="active">
              <a class="nav-link" [routerLink]="['/forecast']"> Lifting Forecast | </a>
            </li>
            <li *ngIf="loggedIn()" class="nav-item" routerLinkActive="active">
              <a class="nav-link" [routerLink]="['/document']"> Document | </a>
            </li>
            <li *ngIf="loggedIn()" class="nav-item" routerLinkActive="active">
              <a class="nav-link" [routerLink]="['/document']"> Notifications | </a>
            </li>
            <li *ngIf="loggedIn()" class="nav-item" routerLinkActive="active">
              <a class="nav-link" [routerLink]="['/document']"> Demurrage | </a>
            </li>
          </ul>
        
          <div *ngIf="loggedIn()" class="dropdown" dropdown>
            <a class="dropdown-toggle text-light" dropdownToggle>Welcome {{authService.decodedToken.unique_name | titlecase}}</a>
            <div class="dropdown-menu mt-3" *dropdownMenu>
              <a class="dropdown-item" href="#">
                <i class="fa fa-user"></i> Edit Profile</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="logout()">
                <i class="fa fa-sign-out"></i> Logout</a>
            </div>
          </div>
        
          <form *ngIf="!loggedIn()" #loginForm="ngForm" class="form-inline my-2 my-lg-0" (ngSubmit)="login()">
            <input class="form-control mr-sm-2" name="username" #username="ngModel" type="text" placeholder="UserName" required
              [(ngModel)]="model.username">
            <input class="form-control mr-sm-2" name="password" #password="ngModel" type="password" placeholder="Password"
              required [(ngModel)]="model.password">
            <button [disabled]="!loginForm.valid" class="btn btn-success my-2 my-sm-0" type="submit">Login</button>
          </form>
        </div>

      </div>
      <div class="mobile-nav-backdrop visible-xs"></div>
    </div>
  </div>
</nav>
