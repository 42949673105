<div class="tab-panel">
  <tabset class="member-tabset">
    <tab heading="Export Work Flow">
      <form ng-form="addCommentForm">
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputConsignee">Status</label>
          <select class="form-control" (change)="updateActivityStatus()" [(ngModel)]="statusId" name="statusId">
            <option *ngFor="let status of statuses"  value="{{status?.id}}" >{{status?.name}}</option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <label for="exampleTextarea">Comment</label>
        <textarea class="form-control" id="exampleTextarea" (keyup.enter)="Checking()" rows="3" data-gramm="true" name="model.comment" [(ngModel)]="model.comment" #model.comment ></textarea>
      </div>
      <button type="submit" class="btn btn-primary" (click)="Checking()">Comment</button>
      <br>
    <div class="list-group">
      <br>
      <a href="#" class="list-group-item list-group-item-action active">
        {{ exportnode?.nodeElements.title }} - {{ exportnode?.nodeStatus.name }}
      </a>
      <a *ngFor="let comment of nodecomment" class="list-group-item list-group-item-action">{{comment['comment']}}  <span class="badge badge-pill badge-warning">{{comment['users'].username}}</span>   <span class="badge badge-pill badge-info">   {{comment['createDate'] | timeAgo}}  </span>
      </a>
    </div>
    <br>
    </form>
    </tab>
  </tabset>
</div>
