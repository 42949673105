<div class="container mt-4">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dash-board']">Home</a>
    </li>
    <li class="breadcrumb-item">
      <a [routerLink]="['/export']">Export</a>
    </li>
    <li class="breadcrumb-item">Edit</li>
    <li class="breadcrumb-item active">Action</li>
  </ol>

  <div class="row">
    <div class="col-md 4">
      <h2 class="mt-2">{{export?.vessel['name']}}</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4">
      <div class="card">
        <img class="card-img-top img-thumbnail rounded" src="{{export?.lifters.lifterPhotoUrl || '/LaycanAPP/src/assets/Chevron-logo.png'}}" alt="">
        <div class="card-body">
          <div>
            <strong>Date Range</strong>
            <p>{{export?.dateRangeStart | date:'mediumDate'}} | {{export?.dateRangeEnd | date:'mediumDate'}}</p>
          </div>
          <div>
            <strong>Lifter</strong>
            <p>{{export?.lifters.name}}</p>
          </div>
          <div>
            <strong>ETA</strong>
            <p>{{export?.eta | date:'mediumDate'}}</p>
          </div>
          <div>
            <strong>STATUS</strong>
            <p>{{ export['status'].label }}</p>
          </div>
        </div>
        <div class="card-footer">
          <div class="btn-group d-flex">
            <button class="btn btn-primary w-100" [routerLink]="['/export/update/',export['id']]" >Edit</button>
            <button class="btn btn-danger w-100" [routerLink]="['/export']" >Close</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-8">
       <app-export-update-activity-comment [exportnode]="exportnode"></app-export-update-activity-comment>
    </div>
  </div>
</div>