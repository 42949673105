import { Component, OnInit, Input } from '@angular/core';
import { Export } from '../../_models/export';
import { ExportService } from '../../_services/export.service';
import { AlertifyService } from '../../_services/alertify.service';
import { AuthService } from '../../_services/auth.service';
import { Demurrage } from '../../_models/demurrage';

@Component({
  selector: 'app-demurrage-claim',
  templateUrl: './demurrage-claim.component.html',
  styleUrls: ['./demurrage-claim.component.css']
})
export class DemurrageClaimComponent implements OnInit {
  @Input() export: any;
  demurrageModel: any = {};

  totalUsedLayTime: any;
  totalExcessLaytime: any;
  revisedClaimValue: number;
  initialClaimRecievedDate: any;
  createdDemurrage: any = {};

  constructor(
    private exportService: ExportService,
    private alertify: AlertifyService,
    public authService: AuthService,
  ) { }

  ngOnInit() {
  }

  saveInitialDemurrageCalculation() {
    this.demurrageModel.initialClaimRecievedDate = this.initialClaimRecievedDate;
    this.demurrageModel.totalUsedLayTime = 1.23;
    this.demurrageModel.totalExcessLayTime = 1.23;
    this.demurrageModel.revisedClaimValue = this.revisedClaimValue;
    this.demurrageModel.exportId = this.export.id;
    console.log(this.demurrageModel.exportId);

    this.exportService.createDemmurage(this.demurrageModel)
    .subscribe(
      (demurrageData: any) => {
        this.createdDemurrage = demurrageData;
        this.alertify.success('Demurrage Data Successfully Added');
      },
      error => {
        this.alertify.error('Error Creating Demurrage Data');
      }
    );
  }

}
