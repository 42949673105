import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lifting-program',
  templateUrl: './lifting-program.component.html',
  styleUrls: ['./lifting-program.component.css']
})
export class LiftingProgramComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
