import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Terminal } from '../../_models/terminal';
import { Lifter } from '../../_models/lifter';
import { ExportService } from '../../_services/export.service';
import { AlertifyService } from '../../_services/alertify.service';
import { Router } from '@angular/router';
import { AgbamiLiftingProgram } from '../../_models/agbamiliftingprogram';
import { AuthService } from '../../_services/auth.service';

@Component({
  selector: "app-forecast-nav",
  templateUrl: "./forecast-nav.component.html",
  styleUrls: ["./forecast-nav.component.css"]
})
export class ForecastNavComponent implements OnInit {
  isCollapsed = false;
  model: any = {};
  batchNumber: string;

  constructor(
    private modalService: BsModalService,
    private exportService: ExportService,
    private alertify: AlertifyService,
    private route: Router,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.model.dailyProduction = 250000;
    this.model.starDeepBOH = 20000;
    this.model.famfaBOH = 20000;
    this.model.petroBrasBOH = 20000;
    this.model.oML127TaxOilBOH = 20000;
    this.model.statoilBOH = 20000;
    this.model.tnosBOH = 20000;
    this.model.nNPCBOH = 20000;
    this.model.startDate = new Date("2018-01-01");
    this.model.duration = 20000;
    this.model.openingBalance = 20000;
    this.model.comment = "This is a test batch";
    this.model.userId = this.authService.decodedToken.nameid;
    this.model.batchNumber = Math.floor(Date.now() / 1000);
  }

  submit() {
    console.log(this.model);
    this.exportService.createLiftingProgramBatch(this.model).subscribe(
      () => {
        this.alertify.success("Lifting Program Successfully Created");
      },
      error => {
        this.alertify.error("Error Creating Lifting Progra");
      }
    );
  }
}
