import { Component, OnInit, Input } from '@angular/core';
import { Export } from '../_models/export';
import { Analyst } from '../_models/Analyst';
import { ExportService } from '../_services/export.service';
import { AlertifyService } from '../_services/alertify.service';

@Component({
  selector: "app-export-detail-analyst",
  templateUrl: "./export-detail-analyst.component.html",
  styleUrls: ["./export-detail-analyst.component.css"]
})
export class ExportDetailAnalystComponent implements OnInit {
  @Input()
  export: Export[];
  analyst: Analyst ;
  constructor(private exportService: ExportService,
    private alertify: AlertifyService) {}

  ngOnInit() {
    this.loadAnalyst();
  }

  loadAnalyst() {
    this.exportService.getExportAnalyst(1).subscribe(
      (analyst: Analyst) => {
        this.analyst = analyst;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
}
