<!-- <div class="row">
  <div *ngFor="let exportDoc of exportDocs" class="col-sm-3 mt-2">
    <div (click)="downloadDocument(exportDoc?.id,exportDoc?.fileName)">
      <img class="pdf-images" src="../../assets/{{exportDoc?.fileExtension}}.jpg" width="100" height="100" class="rounded" alt="../../assets/xls.jpg">
    </div>
    <a (click)="downloadDocument(exportDoc?.id,exportDoc?.fileName)"><i class="fa fa-download"></i>
      <small class="text-muted"> {{ exportDoc?.fileName}}</small>
      <div class="alert alert-warning" role="alert">
        {{ exportDoc?.documentCategory.name}}
      </div>
    </a>
    <span class="badge badge-pill badge-light">{{exportDoc?.users.username | lowercase}}</span>
    <span class="badge badge-pill badge-primary">{{exportDoc?.dateCreated | timeAgo}}</span>
  </div>
</div>
<br>
<div>
  <button type="button" class="btn btn-seconday mt-4"><i class="fa fa-download"></i> Download All</button>
</div> -->

<div *ngFor="let exportDoc of exportDocs" class="row">
  <div class="col-sm-2">
    <div (click)="downloadDocument(exportDoc?.id,exportDoc?.fileName)">
      <img class="pdf-images" src="../../assets/{{exportDoc?.fileExtension}}.jpg" width="100" height="100" class="rounded" alt="../../assets/xls.jpg">
    </div>
  </div>
  <div class="col-sm-8">
    <a (click)="downloadDocument(exportDoc?.id,exportDoc?.fileName)"><i class="fa fa-download"></i>
      <small class="text-muted">  {{ exportDoc?.documentCategory.name}} </small>
      <div class="alert alert-warning" role="alert">
       {{ exportDoc?.fileName}}
      </div>
    </a>
    <span class="badge badge-pill badge-light">{{exportDoc?.users.username | lowercase}}</span>
    <span class="badge badge-pill badge-primary">{{exportDoc?.dateCreated | timeAgo}}</span>
  </div>
</div>

<br>
<div>
  <button type="button" (click)="downloadAll(exportDocs)" class="btn btn-seconday mt-4"><i class="fa fa-download"></i> Download All</button>
</div>