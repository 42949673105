<div class="container mt-2">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dash-board']">Excess Water Home</a>
    </li>
  </ol>
  <ul class="nav nav-pills">
    <li class="nav-item mr-2">
      <a class="nav-link active" (click)="toggelCreatedActivated()">
        <i class="fa fa-plus"></i> Create</a>
    </li>
    <!-- <li class="nav-item mr-2">
      <a class="nav-link active">
        <i class="fa fa-plus"></i> View Status</a>
    </li> -->
  </ul>
  <h6 class="mt-2">Excess Water Claim List</h6>
  <div>
    <app-open-excess-water-list *ngIf="!createdActivated"></app-open-excess-water-list>
  </div>
  <form class="form-group mt-3" #excessWaterClaimForm="ngForm" *ngIf="createdActivated">
    <!-- <pre class="card card-block card-header mb-3">Model: {{selectedValue | json}} </pre>
    <pre class="card card-block card-header mb-3">Selected option: {{selectedOption | json}}</pre> -->
    <div class="tab-panel">
      <tabset class="member-tabset">
        <tab heading="Excess Water Details" id="tab1">
          <div class="form-row mt-3">
            <div class="form-group col-md-4">
              <label for="inputEmail4">Cargo ID</label>
              <input [(ngModel)]="selectedValue" [typeahead]="exports" typeaheadOptionField="cargoNumber"
                (typeaheadOnSelect)="onSelect($event)" #cargoID name="cargoID" class="form-control">
              <a *ngIf="hasExporRecord" [routerLink]="['/export-detail',exportID]">View Cargo Page</a>
            </div>
            <div *ngIf="hasExporRecord" class="form-group col-md-4">
              <img src="{{lifterImage}}" alt="" height="70" width="70">
            </div>
            <!-- <div class="form-group col-md-4">
              <button type="submit" class="btn btn-info mr-2"><i class="fa fa-plus">
                </i></button>
            </div> -->
          </div>
          <div *ngIf="hasExporRecord" class="form-row">
            <div class="form-group col-md-4">
              <label for="inputEmail4">Vessel Name</label>
              <pre class="card card-block card-header mb-2">{{vesselName | uppercase}}</pre>
            </div>
            <div class="form-group col-md-4">
              <label for="inputEmail4">BOL Date</label>
              <pre class="card card-block card-header mb-2">{{BOLDate | date}}</pre>
            </div>
            <div class="form-group col-md-4">
              <label for="inputEmail4">Lifter</label>
              <pre class="card card-block card-header mb-2">{{lifter | uppercase}}</pre>
            </div>
          </div>
          <div *ngIf="hasExporRecord" class="form-row">
            <div class="form-group col-md-4">
              <label for="inputEmail4">Terminal</label>
              <pre class="card card-block card-header mb-2">{{terminalName | uppercase}}</pre>
            </div>
            <div class="form-group col-md-4">
              <label for="inputEmail4">Laycan</label>
              <pre class="card card-block card-header mb-2">{{laycanTime | date:'medium'}}</pre>
            </div>
            <div class="form-group col-md-4">
              <label for="inputEmail4">Bill Of Volume</label>
              <pre class="card card-block card-header mb-2">{{landingNetVolumeInBBLS | number}} BBL.</pre>
            </div>
          </div>
          <div *ngIf="hasExporRecord" class="form-row">
            <div class="form-group col-md-4">
              <label for="inputEmail4">Claim Received</label>
              <input type="text" class="form-control" [(ngModel)]="claimReceived" name="claimReceivedValue"
                (input)="decimalInputValidations($event,'ClaimReceived')"
                (keypress)="decimalKeyPressValidations($event)">
              <small class="text-primary">Enter upto 3 decimal points</small>
            </div>
            <div class="form-group col-md-4">
              <label for="inputEmail4">Vessel Experience Factor</label>
              <input type="text" class="form-control" [(ngModel)]="vesselExperienceFactor" name="vesselExperienceFactor"
                (keypress)="decimalKeyPressValidations($event)">
            </div>
            <div class="form-group col-md-4">
              <label for="inputEmail4">Free Water Measured On Arrival</label>
              <input type="text" class="form-control" [(ngModel)]="freeWaterMeasuredOnArrival"
                name="freeWaterMeasuredOnArrival" (input)="decimalInputValidations($event,'FreeWaterMeasuredOnArrival')"
                (keypress)="decimalKeyPressValidations($event)" required>
              <small class="text-primary">Enter upto 3 decimal points</small>
            </div>
          </div>
          <div *ngIf="hasExporRecord" class="form-row">
            <div class="form-group col-md-4">
              <label for="inputEmail4">Terminal BSMW</label>
              <input type="text" class="form-control" [(ngModel)]="terminalBsmw" name="terminalBsmw"
                (input)="decimalInputValidations($event,'TerminalBsmw')" (keypress)="decimalKeyPressValidations($event)"
                required>
              <small class="text-primary">Enter upto 3 decimal points</small>
            </div>
            <div class="form-group col-md-4">
              <label for="inputEmail4">Sediment Water on Arrival Ship Composite</label>
              <input type="text" class="form-control" [(ngModel)]="sedimentWaterArrival" name="sedimentWaterArrival"
                (input)="decimalInputValidations($event,'SedimentWaterArrival')"
                (keypress)="decimalKeyPressValidations($event)" required>
              <small class="text-primary">Enter upto 3 decimal points</small>
            </div>
            <div class="form-group col-md-4">
              <label for="inputEmail4">Total Excess Free Water</label>
              <input type="text" class="form-control" readonly
                [(ngModel)]="totalExcessFreeWater!=null?calculateExcessFreeWaterValue():totalExcessFreeWater"
                name="totalExcessFreeWater" (input)="decimalInputValidations($event,'TotalExcessFreeWater')">
            </div>
          </div>
          <div *ngIf="hasExporRecord" class="form-row">
            <div class="form-group col-md-4">
              <label for="inputEmail4">Unit Sales</label>
              <input type="text" class="form-control" [(ngModel)]="unitSalesValue" name="unitSalesValue"
                (keypress)="decimalKeyPressValidations($event)" required>
            </div>
            <div class="form-group col-md-4">
              <label for="inputEmail4">Total Loss</label>
              <input type="text" class="form-control" readonly
                [(ngModel)]="totalValueLoss!=null?calTotalExcessFreeWaterLossValue():totalValueLoss"
                name="totalValueLoss" (input)="decimalInputValidations($event,'totalValueLoss')">
            </div>
          </div>
          <app-excess-water-document-upload *ngIf="hasExporRecord" [export]="export"
            [exportId]="exportID"></app-excess-water-document-upload>
        </tab>
      </tabset>
    </div>

    <div class="form-row">
      <button type="submit" (click)="saveInitialExcessWaterDetail()" [disabled]="!excessWaterClaimForm.valid"
        class="btn btn-outline-primary btn-lg mt-3">Submit Excess Water</button>
    </div>
  </form>
</div>