<!-- <form #registerForm="ngForm" (ngSubmit)="register()">
  <h2 class="text-center text-primary">Register New User</h2>
  <hr>

  <div class="form-group">
    <input type="text" class="form-control" required placeholder="First Name" [(ngModel)]="model.firstname" name="firstname">
  </div>
  <div class="form-group">
    <input type="text" class="form-control" required name="username" [(ngModel)]="model.username" placeholder="UserName">
  </div>

  <div>
    <input type="password" class="form-control" name="password" required [(ngModel)]="model.password">
  </div>

  <div class="form-group text-center">
    <button class="btn btn-outline-primary btn-lg mt-2 mr-1" type="submit">Register</button>
    <button class="btn btn-outline-default btn-lg mt-2" type="button" (click)="cancel()">Cancel</button>
  </div>
</form> -->
