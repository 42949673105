import { Component, OnInit, TemplateRef } from '@angular/core';
import { ExportService } from '../../_services/export.service';
import { AlertifyService } from '../../_services/alertify.service';
import { AuthService } from '../../_services/auth.service';
import { DocumentCategory } from '../../_models/documentcategory';
import { environment } from '../../../environments/environment';
import { DocumentTemplateCategory } from '../../_models/documenttemplatecategory';
import { FileUploader } from 'ng2-file-upload';
import { Terminal } from '../../_models/terminal';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: "app-document-template-upload",
  templateUrl: "./document-template-upload.component.html",
  styleUrls: ["./document-template-upload.component.css"]
})
export class DocumentTemplateUploadComponent implements OnInit {
  modalRef: BsModalRef;
  uploader: FileUploader;
  hasBaseDropZoneOver = false;
  baseUrl = environment.apiUrl;
  id = 1;
  userId: number;
  documentTemplateName: string;
  documentTemplateDescription: string;

  documentCategoryList: DocumentTemplateCategory[];
  model: any = {};
  documentCategoryModel: any = {};

  createMode = false;
  terminals: Terminal[];
  newDocumentTemplate: any;
  newDocumentTemplateLabel: any;


  constructor(
    private exportService: ExportService,
    private alertify: AlertifyService,
    public authService: AuthService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.loadDocumentTemplateCategory();
    this.loadTerminals();
    this.initializeUploader();
    this.userId = this.authService.decodedToken.nameid;
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  initializeUploader() {

    this.uploader = new FileUploader({
      url:
        this.baseUrl +
        "documenttemplate/create/name/" +
        this.model.name +
        "/desc/" +
        this.model.description +
        "/terminal/" +
        this.model.terminalId +
        "/user/" +
        this.model.userId +
        "/docTempCat/" +
        this.model.id,
      authToken: "Bearer " + localStorage.getItem("token"),
      isHTML5: true,
      allowedFileType: [
        "pdf",
        "image",
        "csv",
        ".docx",
        ".doc",
        ".ppt",
        ".xls",
        ".xlsx",
        "docx",
        "doc",
        "ppt",
        "xls",
        "xlsx"
      ],
      removeAfterUpload: true,
      autoUpload: false,
      maxFileSize: 10 * 1024 * 1024 // File Size
    });

    this.uploader.onCompleteItem = (item: any, response: any, status: any, header: any) => {
      this.alertify.success('File Upload Successful');
    };

  }
  loadDocumentTemplateCategory() {
    this.exportService.getDocumentTemplateCategory().subscribe(
      (docTemplateCat: DocumentTemplateCategory[]) => {
        this.documentCategoryList = docTemplateCat;
        // this.model = docTemplateCat;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadTerminals() {
    this.exportService.getTerminals().subscribe(
      (terminals: Terminal[]) => {
        this.terminals = terminals;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  createNewDocumentTemplate() {
    this.exportService.createDocumentTemplate(this.model).subscribe(() => {
        this.alertify.success("Document Template Created");
      }, error => {
        this.alertify.error("Unable to Create Document Tamplet");
      });

    this.uploader.onAfterAddingFile = file => {
      file.withCredentials = false;
    };
  }
  getDC() {
    this.model.name = this.documentTemplateName;
    this.model.description = this.documentTemplateDescription;
    this.model.userId = this.userId;
    console.log(this.model);
    this.initializeUploader();
  }
  getModelReady() {
    this.model.name = this.documentTemplateName;
    this.model.description = this.documentTemplateDescription;
    console.log(this.model);
    this.initializeUploader();
  }
  createModeToggel() {
    this.createMode = !this.createMode;
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  addDocumentTemplate() {
    console.log(this.newDocumentTemplate);
    this.documentCategoryList.push(this.newDocumentTemplate);
    this.documentCategoryModel.name = this.newDocumentTemplate;
    this.documentCategoryModel.label = this.newDocumentTemplateLabel;

    this.exportService.createDocumentTemplateCategroy(this.documentCategoryModel)
    .subscribe(
      () => {
        this.alertify.success('New Document Categroy Created');
        this.loadDocumentTemplateCategory();
      }, error => {
        this.alertify.error('Error Creating Document Category');
      }
    );

    this.modalRef.hide();
  }

}
