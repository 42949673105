<div class="container mt-4">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dash-board']">Home</a>
    </li>
    <li class="breadcrumb-item">
      <a [routerLink]="['/export']">Export</a>
    </li>
    <li class="breadcrumb-item active">Edit</li>
  </ol>

  <div class="row">
    <div class="col-md 4">
      <h1 class="mt-2">{{export?.vessel['name']}}</h1>
    </div>
    <div class="col-md-8">
      <div *ngIf="editExportForm.dirty" class="alert alert-info">
        <strong>Information</strong>You have made some changes. Any unsaved changes would be lost
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4">
      <div class="card">
        <img class="card-img-top img-thumbnail rounded" src="{{export.lifters.lifterPhotoUrl}}" alt="">
        <div class="card-body">
          <div>
            <strong>Date Range</strong>
            <p>{{export?.dateRangeStart | date:'mediumDate'}} | {{export?.dateRangeEnd | date:'mediumDate'}}</p>
          </div>
          <div>
            <strong>Lifter</strong>
            <p>{{export?.lifters.name}}</p>
          </div>
          <div>
            <strong>ETA</strong>
            <p>{{export?.eta | date:'mediumDate'}}</p>
          </div>
          <div>
            <strong>STATUS</strong>
            <p>{{ export?.status.label }}</p>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button [disabled]="!editExportForm.dirty" form="editExportForm" type="submit" class="btn btn-success w-100"
          (ngSubmi)="updateExport()">Save Changes</button>
      </div>
    </div>
    <div class="col-sm-8">
      <form #editExportForm="ngForm" (ngSubmit)="updateExport()" id="editExportForm">
        <div class="tab-panel">
          <tabset class="member-tabset" #updateTab>
            <tab heading="Basic Details">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputTerminal">Export Terminal</label>
                  <select class="form-control" name="terminals.id" [(ngModel)]="model.terminals.id"
                    #terminals.id="ngModel" (change)="onTerminalChange(model.terminals.id)" required>
                    <option *ngFor="let terminal of terminals" value="{{terminal.id}}">{{terminal.name}}</option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label for="inputCargoNumber">Unique Cargo Numger</label>
                  <input type="text" class="form-control" id="inputCargoNumber" name="cargoNumber"
                    [(ngModel)]="model.cargoNumber" #cargoNumber="ngModel" required>
                </div>

                <div class="form-group col-md-6">
                  <label for="inputLifter">Select Lifter</label>
                  <select class="form-control" name="lifters.id" [(ngModel)]="model.lifters.id" #lifters.id="ngModel"
                    (change)="onSelectLifter(model.lifters.id)" required>
                    <option *ngFor="let lifter of lifters" value="{{lifter.id}}">{{lifter.name}}</option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label for="inputProductStream">Select Product Stream</label>
                  <select class="form-control" id="inputProductStream" name="products.id"
                    [(ngModel)]="model?.products.id" #products.id="ngModel" (change)="onProductChange(model?.product.id)" required>
                    <option *ngFor="let product of products" value="{{product.id}}">{{product.name}}</option>
                  </select>
                </div>

              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputProductStream">Cargo Type</label>
                  <select class="form-control" #cargoTypes.id="ngModel" name="cargoTypes.id"
                    [(ngModel)]="model?.cargoTypes.id" required>
                    <option *ngFor="let cargotype of cargotypes" value="{{cargotype.id}}">{{cargotype.name}}</option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label for="inputNominatedVolume">Nominated Volume</label>

                  <input type="text" (keypress)="onNominationValPress($event)" (input)="onNomibationInput($event)"  class="form-control" [(ngModel)]="model.norminatedCargo" name="norminatedCargo"
                    #nominatedCargo="ngModel" required>

                    <small class="text-primary">{{ model?.terminals.id == 5 && model.products != null ? model.products?.id == 7 || model.products?.id == 8 ? 'Enter upto 3 decimal points.' : showNomintationInfo == true ? 'Enter upto 3 decimal points.':'' :''}}</small>
                    
                </div>

   <!-- NEW TRANSMIX VOLUME -->
   
   <ng-template [ngIf]="showProductCom">
   
   <div class="form-group col-md-6">
     <label for="inputProductCombination">Select Product Combination</label>
     <select class="form-control" id="inputProductCombination" name="productCombinationId" 
     [(ngModel)]="model?.productCombinations.id" #productCombinations.id="ngModel"
       required>
       <option *ngFor="let product of productCombinations" value="{{product.id}}">{{product.name}}</option>
     </select>
   </div>
   <div class="form-group col-md-6">
     <label for="inputTransmixVolume">Transmix Volume</label>
     <input type="text" (keypress)="onNominationValPress($event)" (input)="onNomibationInput($event)" class="form-control" id="inputTransmixVolume" placeholder="Transmix Volume" [(ngModel)]="model.transmixVolume"
       name="transmixVolume" #transmixVolume="ngModel" required>

       <small class="text-primary">Enter upto 3 decimal points.</small>

   </div>   
 </ng-template>

              </div>
              <div class="row">
                <div class="form-group col-md-10">
                  <label for="inputVesselName">Vessel Name</label>
                  <input type="text" class="form-control" [(ngModel)]="model.vessel.name" name="vessel.name"
                    #vesselName="ngModel" [typeahead]="vessels" typeaheadOptionField="name"
                    (typeaheadOnSelect)="onSelectVessel($event)">
                </div>
                <div class="col-md-2">
                  <button type="button" class="btn btn-outline-primary" (click)="openModal(template)">
                    <i class="fa fa-plus-circle"></i>
                  </button>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="inputDateRange">Date Range Start</label>
                  <input class="form-control" name="dateRangeStart" #dateRangeStart="ngModel"
                    [(ngModel)]="model.dateRangeStart" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD hh:mm' }"
                    (bsValueChange)="onDateRangeStartValueChange($event)" required bsDatepicker>
                </div>
                <div class="form-group col-md-4">
                  <label for="inputDateRange">Date Range End</label>
                  <input class="form-control" name="dateRangeEnd" #dateRangeEnd="ngModel"
                    [(ngModel)]="model.dateRangeEnd" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD hh:mm' }"
                    (bsValueChange)="onDateRangeEndValueChange($event)" required bsDatepicker>
                </div>
                <div class="form-group col-md-4">
                  <label for="inputETA">ETA</label>
                  <input class="form-control" [(ngModel)]="model.eta" #eta="ngModel" name="eta"
                    [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD hh:mm' }" (bsValueChange)="onDateETAValueChange($event)"
                    bsDatepicker>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-10">
                  <label for="inputDestination">Destination</label>
                  <input type="text" class="form-control" id="inputDestination" [(ngModel)]="model.destinations.name"
                    placeholder="Enter Destination" #destination="ngModel" name="destination"
                    [typeahead]="destinationList" typeaheadOptionField="name"
                    (typeaheadOnSelect)="onSelectDestination($event)">
                </div>
                <div class="col-md-2">
                  <button type="button" class="btn btn-outline-primary" (click)="openModal(newDestinationModal)">
                    <i class="fa fa-plus-circle"></i>
                  </button>
                </div>
              </div>
              <div class="form-row ">
                <div class="form-group col-md-6">
                  <div class="row">
                    <div class="col-md-10">
                      <label for="inputInspector">Inspector</label>
                      <input type="text" class="form-control" id="inputInspector" [(ngModel)]="model.inspector.name"
                        placeholder="Enter Inspector" #inspector.name="ngModel" name="inspector.name"
                        [typeahead]="InspectorList" typeaheadOptionField="name"
                        (typeaheadOnSelect)="onSelectInspector($event)">
                    </div>
                    <div class="col-md-2">
                      <button type="button" class="btn btn-outline-primary" (click)="openModal(newInspectorModal)">
                        <i class="fa fa-plus-circle"></i>
                      </button>
                    </div>
                  </div>

                </div>
                <div class="form-group col-md-6">
                  <label for="inputFLAFofVessel">FLAG of Vessel</label>
                  <input type="text" class="form-control" [(ngModel)]="model.vessel.flag" #model.vessel.flag="ngModel"
                    name="model.vessel.flag" [typeahead]="vessels" typeaheadOptionField="name"
                    (typeaheadOnSelect)="onSelectVessel($event)" disabled>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputDWTofVessel">DWT of Vessel</label>
                  <input type="text" class="form-control" [(ngModel)]="model.vessel.dwt" #model.vessel.dwt="ngModel"
                    name="model.vessel.dwt" disabled>
                </div>
                <div class="form-group col-md-6">
                  <div class="row">
                    <div class="col-md-10">
                      <label for="inputDWTofVessel">Vessel Agent</label>
                      <input type="text" class="form-control" id="inputVesselAgent"
                        [(ngModel)]="model.vesselAgents.name" placeholder="Select Vessel Agent"
                        #model.vesselAgents.name="ngModel" name="model.vesselAgents.name" [typeahead]="vesselAgentList"
                        typeaheadOptionField="name" (typeaheadOnSelect)="onSelectVesselAgent($event)">
                    </div>
                    <div class="col-md-2">
                      <button type="button" class="btn btn-outline-primary" (click)="openModal(newAgentModal)">
                        <i class="fa fa-plus-circle"></i>
                      </button>
                    </div>
                  </div>

                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <div class="row">
                    <div class="col-md-10">
                      <label for="inputConsignee">Consignee</label>
                      <select class="form-control" #model.consignees.name="ngModel" name="model.consignees.id"
                        [(ngModel)]="model.consignees.id" required>
                        <option *ngFor="let consignee of consignees" value="{{consignee.id}}">{{consignee.name}}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <button type="button" class="btn btn-outline-primary" (click)="openModal(newConsigneeModal)">
                        <i class="fa fa-plus-circle"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-10">
                  <label for="inputDWTofVessel">Consignor</label>
                  <input type="text" class="form-control" id="inputConsignor" [(ngModel)]="model.consignor.name"
                    placeholder="Select Consignor" #model.consignor.name="ngModel" name="model.consignor.name"
                    [typeahead]="consignorsList" typeaheadOptionField="name"
                    (typeaheadOnSelect)="onSelectConsignor($event)">
                </div>
                <div class="col-md-2">
                  <button type="button" class="btn btn-outline-primary" (click)="openModal(newConsignorModal)">
                    <i class="fa fa-plus-circle"></i>
                  </button>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="inputConsignee">Status</label>
                  <select class="form-control" #status.id="ngModel" name="status.id" [(ngModel)]="model.status.id">
                    <option *ngFor="let status of statuses" value="{{status.id}}">{{status?.label}}</option>
                  </select>
                </div>
              </div>

            </tab>
            <tab heading="Action">
              <app-export-detail-update-actions [export]="export"></app-export-detail-update-actions>
            </tab>
            <tab heading="Export Details">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="inputVesselName">Vessel Agent</label>
                  <input type="text" class="form-control" [(ngModel)]="model.vesselAgents.name" name="vesselAgents.name"
                    #vesselAgents.name="ngModel">
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputVesselName">BOL NET Figure</label>
                  <input type="text" class="form-control" [(ngModel)]="model.actualCargo" name="actualCargo"
                    #actualCargo="ngModel">
                </div>
                <div class="form-group col-md-6">
                  <label for="inputETA">BOL Date : {{model.bolDate | date}}</label>
                  <input type="text" class="form-control" [(ngModel)]="model.bolDate" #bolDate="ngModel" name="bolDate"
                    [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }" (bsValueChange)="onBOLDateValueChange($event)"
                    bsDatepicker>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputVesselName">Ship Figures</label>
                  <input type="text" class="form-control" [(ngModel)]="model.shipFigures" name="shipFigures"
                    #shipFigures="ngModel">
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputVesselName">Loading Start 2018-01-31 10:23</label>
                  <input type="text" class="form-control" [(ngModel)]="model.loadingStart" name="loadingStart"
                    #loadingStart="ngModel" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD hh:mm' }"
                    (bsValueChange)="onLoadingStartValueChange($event)" bsDatepicker>
                </div>
                <div class="form-group col-md-6">
                  <label for="inputVesselName">Loading Stop 2018-01-31 10:23</label>
                  <input type="text" class="form-control" [(ngModel)]="model.loadingStop" name="loadingStop"
                    #loadingStop="ngModel" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD hh:mm' }"
                    (bsValueChange)="onLoadingEndValueChange($event)" bsDatepicker>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputVesselName">BS & W</label>
                  <input type="text" class="form-control" [(ngModel)]="model.bsw" name="bsw" #bsw="ngModel">
                </div>
                <div class="form-group col-md-6">
                  <label for="inputVesselName">API Value</label>
                  <input type="text" class="form-control" [(ngModel)]="model.api" name="api" #api="ngModel">
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="inputComment">Notes</label>
                  <textarea class="form-control" id="inputComment" rows="4" #comment="ngModel" name="comment"
                    [(ngModel)]="model.comment"></textarea>
                </div>
              </div>

            </tab>
            <tab heading="Documentation">
              <app-document-upload [exportid]="export.id"></app-document-upload>
            </tab>
            <tab heading="Financial">
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="inputForHasDemurrage mr-2">Has Demurrage</label>
                  <div class="btn-group ml-2">
                    <label class="btn btn-primary" name="hasDemurrage.state" #hasDemurrage.state="ngModel"
                      [(ngModel)]="hasDemurrage.state" btnCheckbox role="button">{{hasDemurrage.state}}</label>
                  </div>
                  <input type="text" class="form-control" #model.demurrageCost [(ngModel)]="model.demurrageCost"
                    name="model.demurrageCost">
                </div>
                <div class="form-group col-md-4">
                  <label for="inputForHasDemurrage mr-2">Has Outturn</label>
                  <div class="btn-group ml-2">
                    <label class="btn btn-primary" name="hasOutturn.state" #hasOutturn.state="ngModel"
                      [(ngModel)]="hasOutturn.state" btnCheckbox role="button">{{hasOutturn.state}}</label>
                  </div>
                  <input type="text" class="form-control" #model.outterunCost [(ngModel)]="model.outterunCost"
                    name="model.outterunCost">
                </div>
                <div class="form-group col-md-4">
                  <label for="inputForHasDemurrage mr-2">Has Loss Claim</label>
                  <div class="btn-group ml-2">
                    <label class="btn btn-primary" name="hasLossClaim.state" #hasLossClaim.state="ngModel"
                      [(ngModel)]="hasLossClaim.state" btnCheckbox role="button">{{hasLossClaim.state}}</label>
                  </div>
                  <input type="text" class="form-control" #model.lossClaimCost [(ngModel)]="model.lossClaimCost"
                    name="model.lossClaimCost">
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="fiscalPrice">Fiscal Price (USD)</label>
                  <input type="text" class="form-control" [(ngModel)]="model.fiscalPrice" name="fiscalPrice"
                    #fiscalPrice="ngModel">
                </div>
                <div class="form-group col-md-4">
                  <label for="inputForNMASalesPrice">NMA Sales Price (USD)</label>
                  <input type="text" class="form-control" [(ngModel)]="model.NMASalesPrice" name="NMASalesPrice"
                    #NMASalesPrice="ngModel">
                </div>
                <div class="form-group col-md-4">
                  <label for="inputForCPCSalesPrice">CPC SaLes Price (USD)</label>
                  <input type="text" class="form-control" [(ngModel)]="model.CPCSalesPrice" name="CPCSalesPrice"
                    #CPCSalesPrice="ngModel">
                </div>
              </div>
              <hr>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="fiscalPrice">NXP Number</label>
                  <input type="text" class="form-control" [(ngModel)]="model.nxpNumber" name="model.nxpNumber"
                    #model.nxpNumber>
                </div>
                <div class="form-group col-md-4">
                  <label for="fiscalPrice">NES PayReq Number</label>
                  <input type="text" class="form-control" [(ngModel)]="model.nessNumber" name="model.nessNumber"
                    #model.nessNumber>
                </div>
                <div class="form-group col-md-4">
                  <label for="fiscalPrice">CCI Number</label>
                  <input type="text" class="form-control" [(ngModel)]="model.cciNumber" name="model.cciNumber"
                    #model.cciNumber>
                </div>
              </div>


            </tab>
            <!-- <tab heading="Others">
            <h5>Analyst</h5>
            <div class="row">
              <div class="col-sm-6">
                <ul class="list-group">
                  <li class="list-group-item">Name : AGBOGO Michael</li>
                  <li class="list-group-item">Email: michael.agbogo@chevron.com</li>
                  <li class="list-group-item">Phone:08024928263</li>
                  <li class="list-group-item">Extension: 6645676</li>
                  <li class="list-group-item">Manager: FAMOSE Augustus</li>
                </ul>
              </div>
              <div class="col-sm-6">
                <div class="card" style="width: 18rem;">
                  <img class="card-img-top img-thumbnail" src="../../assets/user-male-icon.png" width="100" height="250" alt="Card image cap">
                  <div class="card-body"></div>
                  <h6 class="card-title text-center">Analyst : Mr. AGBOGO Michael</h6>
                </div>
              </div>
            </div>
          </tab> -->
          </tabset>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add New Vessel</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #addVesselForm="ngForm">
    <div class="modal-body">
      <div class="form-row">
        <input type="text" class="form-control" placeholder="Vessel Name" [(ngModel)]="vesselToAddModel.name"
          name="vesselToAddModel.name" required>
      </div>
      <div class="form-row mt-2">
        <input type="text" class="form-control" placeholder="IMO Number" [(ngModel)]="vesselToAddModel.IMONumber"
          name="vesselToAddModel.IMONumber" required>
      </div>
      <div class="form-row mt-2">
        <input type="text" class="form-control" placeholder="DWT of Vessel" [(ngModel)]="vesselToAddModel.dwt"
          name="vesselToAddModel.dwt" required>
      </div>
      <div class="form-row mt-2">
        <input type="text" class="form-control" placeholder="Vessel FLAG" [(ngModel)]="vesselToAddModel.flag"
          name="vesselToAddModel.flag" required>
      </div>
      <button type="button" class="btn btn-primary mt-2" [disabled]="!addVesselForm.valid"
        (click)="addNewVessel()">Save</button>
    </div>
  </form>
</ng-template>

<ng-template #newAgentModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add Agent</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #addAgentForm="ngForm">
    <div class="modal-body">
      <div class="form-row">
        <input type="text" class="form-control" autofocus placeholder="Vessel Name"
          [(ngModel)]="vesselAgentToAddModel.name" name="vesselAgentToAddModel.vesselName" required>
      </div>
      <button type="button" class="btn btn-primary mt-2" [disabled]="!addAgentForm.valid"
        (click)="addNewVesselAgent()">Save</button>
    </div>
  </form>
</ng-template>

<ng-template #newInspectorModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add Inspector</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #addInspectorForm="ngForm">
    <div class="modal-body">
      <div class="form-row">
        <input type="text" class="form-control" placeholder="Inspector Name" [(ngModel)]="inspeectorToAddModel.name"
          name="inspeectorToAddModel.name" required>
      </div>
      <button type="button" class="btn btn-primary mt-2" [disabled]="!addInspectorForm.valid"
        (click)="addNewInspector()">Save</button>
    </div>
  </form>
</ng-template>

<ng-template #newConsigneeModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add Consignee</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #addConsigneeForm="ngForm">
    <div class="modal-body">
      <div class="form-row">
        <input type="text" class="form-control" placeholder="Consignee Name" [(ngModel)]="consigneeToAddModel.name"
          name="consigneeToAddModel.name" required>
      </div>
      <div class="form-row mt-2 mb-2">
        <select class="form-control" id="inputTerminal" name="consigneeToAddModel.terminalId"
          [(ngModel)]="consigneeToAddModel.terminalId" required>
          <option>Select A Terminal</option>
          <option *ngFor="let terminal of terminals" value="{{terminal.id}}">{{terminal.name}}</option>
        </select>
      </div>
      <button type="button" class="btn btn-primary mt-2" [disabled]="!addConsigneeForm.valid"
        (click)="addNewConsignee()">Save</button>
    </div>
  </form>
</ng-template>

<ng-template #newConsignorModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add Consignor</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #addConsignorForm="ngForm">
    <div class="modal-body">
      <div class="form-row">
        <input type="text" class="form-control" placeholder="Consignor Name" [(ngModel)]="consignorToAddModel.name"
          name="consignorToAddModel" required>
      </div>
      <div class="form-row mt-2 mb-2">
        <select class="form-control" id="inputLifter" name="consignorToAddModel.lifterId"
          [(ngModel)]="consignorToAddModel.lifterId" required>
          <option *ngFor="let lifter of lifters" value="{{lifter.id}}">{{lifter.name}}</option>
        </select>
      </div>
      <button type="button" class="btn btn-primary mt-2" [disabled]="!addConsignorForm.valid"
        (click)="addNewConsignor()">Save</button>
    </div>
  </form>
</ng-template>

<ng-template #newDestinationModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add Destination</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #addDestinationForm="ngForm">
    <div class="modal-body">
      <div class="form-row">
        <input type="text" class="form-control" placeholder="Destination" [(ngModel)]="destinationToAddModel.name"
          name="destinationToAddModel.name" required>
      </div>
      <button type="button" class="btn btn-primary mt-2" [disabled]="!addDestinationForm.valid"
        (click)="addNewDestination()">Save</button>
    </div>
  </form>

</ng-template>