import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.css']
})
export class TerminalComponent implements OnInit {

  baseUrl = environment.apiUrl;
  terminals: any;
  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.getTerminals();
  }

  getTerminals(){
    this.http.get(this.baseUrl + "/api/terminals").subscribe(response => {
      this.terminals = response;
    }, error => {
      console.log(error);
    });
  }
}
