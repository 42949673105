import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AlertifyService } from './alertify.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnergycomponentService {
  baseUrl = environment.apiUrl;
constructor(
  private http: HttpClient,
   private alertify: AlertifyService) { }

   getExportDataForID(id: number): Observable<any> {
     return this.http.get<any>('http://ws-prod-ec.dev.lagnr.chevron.com/api/export/' + id + '/CN');
   }

}
