import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentComponent implements OnInit {

  createMode = false;
  constructor() { }

  ngOnInit() {
  }
  createModeToggel(){
    this.createMode = !this.createMode;
    console.log(this.createMode);
  }
}
