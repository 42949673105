import { Component, OnInit } from '@angular/core';
import { ExchangeRate } from '../../_models/exchangerate';
import { ExportService } from '../../_services/export.service';
import { AlertifyService } from '../../_services/alertify.service';
import { ActivatedRoute, Router } from '../../../../node_modules/@angular/router';
import { AuthService } from '../../_services/auth.service';
import { BsModalService } from '../../../../node_modules/ngx-bootstrap/modal';

@Component({
  selector: 'app-exchange-rate',
  templateUrl: './exchange-rate.component.html',
  styleUrls: ['./exchange-rate.component.css']
})
export class ExchangeRateComponent implements OnInit {

  model: any ={};
  exchangeRateArray: ExchangeRate[];
  isLoading = false;

  constructor(
    private exportService: ExportService,
    private alertify: AlertifyService,
    private route: ActivatedRoute,
    private nav: Router,
    private authService: AuthService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.getExchangeRates();
  }

  getExchangeRates() {
    this.isLoading = true;
    this.exportService.getExchangeRate().subscribe(
      (exchangeRate: ExchangeRate[]) => {
        this.isLoading = false ;
        this.exchangeRateArray = exchangeRate;
      },
      error => {
        this.alertify.error('Unable to Retrieve Exchange Rate Data');
      }
    );
  }

  addExchangeRate() {
    this.model.userId = this.authService.decodedToken.nameid ;
    this.exchangeRateArray.push(this.model);
    this.exportService.createExchangeRate(this.model).subscribe(
      () => {
        this.alertify.success('Exchange Entry Created');
        this.getExchangeRates();
      },
      error => {
        this.alertify.error('Unable To Create Exchange Rate');
      }
    );
  }
  deleteExchangeRate(id: number) {
    console.log(id);

    // Web Service to delete exchange
    this.exportService.deleteExchangeRate(id).subscribe(
      () => {
        this.alertify.success('Exchange Rate Deleted');
        this.getExchangeRates();
      }, error => {
        console.log(error);
        this.alertify.error('Could not delete exchange rate');
      }
    );


  }

}
