import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ExportService } from '../../_services/export.service';
import { AlertifyService } from '../../_services/alertify.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import { DemurrageCalculation } from '../../_models/demurragecalculation';
import { Export } from '../../_models/export';

@Component({
  selector: "app-demurrage-list-detail",
  templateUrl: "./demurrage-list-detail.component.html",
  styleUrls: ["./demurrage-list-detail.component.css"]
})
export class DemurrageListDetailComponent implements OnInit {
  demurrageCalculationsArray: DemurrageCalculation[];
  exportId: number;
  detailPanelOpen = false;

  notificationOfArrivalTime: Date = new Date();
  notificationOfArrivalDate: Date;

  portAuthorityOnBoardTime: Date = new Date();
  portAuthorityOnBoardDate: Date;

  freePratigueOnBoardTime: Date = new Date();
  freePratiqueOnBoardDate: Date;

  pilotOnBoardTime: Date = new Date();
  pilotOnBoardDate: Date;

  completedMooringTime: Date = new Date();
  completedMooringDate: Date;

  completedDisconnectionOfHoseTime: Date = new Date();
  completedDisconnectionOfHoseDate: Date;

  laycanTime: Date = new Date();
  laycanDate: Date;

  cargoPaperDocStartTime: Date = new Date();
  cargoPaperDocStartDate: Date;

  cargoPaperDocEndTime: Date = new Date();
  cargoPaperDocEndDate: Date;

  totalVolumeLoaded: number;

  layTimeStartTime: Date = new Date();
  layTimeStartDate: Date;

  equipmentBreakDownStartTime: Date = new Date();
  equipmentBreakDownStartDate: Date;

  equipmentBreakDownEndsTime: Date = new Date();
  equipmentBreakDownEndsDate: Date;

  weatherDelayStartTime: Date = new Date();
  weatherDelayStartDate: Date;

  weatherDelayEndTime: Date = new Date();
  weatherDelayEndDate: Date;

  hasEquipmentBreakDown = false;
  hasWeatherDelay = false;

  selectedValue: string;
  selectedOption: any;
  vesselName: any;
  BOLDate: any;
  lifter: any;
  terminalName: any;
  vesselArrived: any;
  exportID = 1;
  selectedObjectId = 0;
  lifterImage: any;

  afraValue: number;
  wsValue: number;
  shipRate: number;

  exports: Export[];
  export: any;

  model: any = {};

  calculationAvailable = false;

  returnedDemurrageModel: any = {};

  hasExporRecord = false;

  createdActivated = false;

  enableLoader = true;

  constructor(
    private modalService: BsModalService,
    private exportService: ExportService,
    private alertify: AlertifyService,
    private route: Router,
    private authService: AuthService,
    private nav: ActivatedRoute
  ) {}

  ngOnInit() {
    this.exportId = this.nav.params["value"].exportId;
    this.loadDemurrageCalculations();
  }

  loadDemurrageCalculations() {
    this.exportService
      .getDemurrageCalculationsForExport(this.exportId)
      .subscribe(
        (demurrageCalcs: DemurrageCalculation[]) => {
          this.demurrageCalculationsArray = demurrageCalcs;
          this.enableLoader = false;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }
  //:DemurrageCalculation
  openDetailToggle(selectedObject) {
    this.detailPanelOpen = !this.detailPanelOpen;

    this.selectedObjectId = selectedObject.id;

    this.exportID = this.exportId;
    this.afraValue = selectedObject.afraRate;
    this.wsValue = selectedObject.wsRate;
    this.shipRate = selectedObject.shipRate;
    this.laycanDate = selectedObject.firstDayOfLaycan;
    this.laycanTime = selectedObject.firstDayOfLaycan;
    this.totalVolumeLoaded = selectedObject.totalVolumeLoaded;

    this.cargoPaperDocStartDate = selectedObject.cargoPaperStart;
    this.cargoPaperDocStartTime = selectedObject.cargoPaperStart;
    this.cargoPaperDocEndDate = selectedObject.cargoPaperCompleted;
    this.cargoPaperDocEndTime = selectedObject.cargoPaperCompleted;
    
    this.layTimeStartDate = selectedObject.layTimeStart;
    this.layTimeStartTime = selectedObject.layTimeStart;
    this.notificationOfArrivalTime = selectedObject.notificationOfArrival;
    this.notificationOfArrivalDate = selectedObject.notificationOfArrival;
    this.portAuthorityOnBoardDate = selectedObject.portAuthorityOnBoard;
    this.portAuthorityOnBoardTime = selectedObject.portAuthorityOnBoard;
    this.freePratiqueOnBoardDate = selectedObject.freePratiqueGranted;
    this.freePratigueOnBoardTime = selectedObject.freePratiqueGranted;
    this.pilotOnBoardDate = selectedObject.pilotOnBoard;
    this.pilotOnBoardTime = selectedObject.pilotOnBoard;
    this.completedMooringDate = selectedObject.completedMooring;
    this.completedMooringTime = selectedObject.completedMooring;
    this.completedDisconnectionOfHoseDate = selectedObject.completeDisconnectingHose;
    this.completedDisconnectionOfHoseTime = selectedObject.completeDisconnectingHose;
    this.equipmentBreakDownStartDate = selectedObject.equipmentBreakDownStart;
    this.equipmentBreakDownStartTime = selectedObject.equipmentBreakDownStart;
    this.equipmentBreakDownEndsDate = selectedObject.equipmentBreakDownEnds;
    this.equipmentBreakDownEndsTime = selectedObject.equipmentBreakDownEnds;
    this.weatherDelayStartDate = selectedObject.weatherDelayStart;
    this.weatherDelayStartTime = selectedObject.weatherDelayStart;
    this.weatherDelayEndDate = selectedObject.weatherDelayEnd;
    this.weatherDelayEndTime = selectedObject.weatherDelayEnd;

    this.hasEquipmentBreakDown = selectedObject.hasEquipmentBreakDown;
    this.hasWeatherDelay = selectedObject.hasWeatherDelay;

    this.export = selectedObject.export;
    // console.log(selectedObject);
    this.calculationAvailable = false;
  }

  toggelCreatedActivated() {
    this.createdActivated = !this.createdActivated;
  }
  toggelHasEquipmentFailure() {
    this.hasEquipmentBreakDown = !this.hasEquipmentBreakDown;
  }
  toggleHasWeatherDelays() {
    this.hasWeatherDelay = !this.hasWeatherDelay;
  }

  onNotificationOfArrivalValueChange(value: Date): void {
    this.notificationOfArrivalTime = value;
  }
  onPortAthourityOnBoardValueChange(value: Date): void {
    this.portAuthorityOnBoardTime = value;
  }
  onfreePratigueOnBoardValueChange(value: Date): void {
    this.freePratigueOnBoardTime = value;
  }
  onPilotOnBoardValueChange(value: Date): void {
    this.pilotOnBoardTime = value;
  }
  onCompletedMooringValueChange(value: Date): void {
    this.completedMooringTime = value;
  }
  onCompletedDisconnectionOfHoseValueChange(value: Date): void {
    this.completedDisconnectionOfHoseTime = value;
  }
  onLaycanValueChange(value: Date): void {
    this.laycanTime = value;
  }
  onLayTimeStartValueChange(value: Date): void {
    this.layTimeStartTime = value;
  }
  onEquipmentBreakDownStartValueChange(value: Date): void {
    this.equipmentBreakDownStartTime = value;
  }
  onEquipmentBreakEndValueChange(value: Date): void {
    this.equipmentBreakDownEndsTime = value;
  }
  onWeatherDelayStartValueChange(value: Date): void {
    this.weatherDelayStartTime = value;
  }
  onWeatherDelayEndValueChange(value: Date): void {
    this.weatherDelayEndTime = value;
  }
  onCargoPaperDocStartValueChange(value: Date): void {
    this.cargoPaperDocStartTime = value;
  }
  onCargoPaperDocEndValueChange(value: Date): void {
    this.cargoPaperDocEndTime = value;
  }

  calculateDemurrage() {
    this.model.id = this.selectedObjectId;

    this.model.exportId = this.exportID;
    this.model.firstDayOfLaycan = this.laycanTime;

    this.model.totalVolumeLoaded = this.totalVolumeLoaded;
    this.model.notificationOfArrival = this.notificationOfArrivalTime;
    this.model.hoseConnected = this.layTimeStartTime;
    this.model.layTimeStart = this.layTimeStartTime;
    this.model.portAuthorityOnBoard = this.portAuthorityOnBoardTime;
    this.model.freePratiqueGranted = this.freePratigueOnBoardTime;
    this.model.pilotOnBoard = this.pilotOnBoardTime;
    this.model.completeDisconnectingHose = this.completedDisconnectionOfHoseTime;
    this.model.completedMooring = this.completedMooringTime;
      
    this.model.cargoPaperStart = this.cargoPaperDocStartTime ;
    this.model.cargoPaperCompleted = this.cargoPaperDocEndTime ;

    this.model.afraRate = this.afraValue;
    this.model.wsRate = this.wsValue;
    this.model.shipRate = this.shipRate;
    this.model.hasEquipmentBreakDown = this.hasEquipmentBreakDown;
    this.model.hasWeatherDelay = this.hasWeatherDelay;
    this.model.equipmentBreakDownStart = this.equipmentBreakDownStartTime;
    this.model.equipmentBreakDownEnds = this.equipmentBreakDownEndsTime;
    this.model.WeatherDelayStart = this.weatherDelayStartTime;
    this.model.WeatherDelayEnd = this.weatherDelayEndTime;

    // console.log(this.model);
    this.enableLoader = true;
    this.exportService.createDemmurageCulculation(this.model).subscribe(
      (demurrageData: any) => {
        this.returnedDemurrageModel = demurrageData;
        this.alertify.success('Demurrage Instance Successfully Created');
        this.calculationAvailable = true;
        this.enableLoader = false;
        this.loadDemurrageCalculations();
      },
      error => {
        this.alertify.error('Error Posting Demmurage');
      }
    );
  }
}
