<h4 class="mt-2">Pending Action</h4>
<div class="row">
  <div class="alert  alert-danger col-md-4 mr-1">
    <strong>Document Instruction!</strong> DI for AGB-008
    <a href="#" class="alert-link">Need to be sent to terminal
      
    </a>
    <button type="button" class="btn btn-primary btn-sm">Take Action</button>
  </div>
  <div class="alert alert-warning col-md-4 mr-1">
    <strong>Well done!</strong> You successfully read
    <a href="#" class="alert-link">this important alert message</a>
  </div>
  <div class="alert  alert-success col-md-3">
    <strong>Well done!</strong> You successfully read
    <a href="#" class="alert-link">this important alert message</a>.
  </div>
  <div class="alert  alert-success col-md-3 mr-1">
    <strong>Well done!</strong> You successfully read
    <a href="#" class="alert-link">this important alert message</a>.
  </div>
  <div class="alert  alert-danger col-md-4 mr-1">
    <strong>Well done!</strong> You successfully read
    <a href="#" class="alert-link">this important alert message</a>.
  </div>
  <div class="alert alert-warning col-md-4">
    <strong>Well done!</strong> You successfully read
    <a href="#" class="alert-link">this important alert message</a>
  </div>
</div>
