import { Component, OnInit, TemplateRef } from '@angular/core';
import { TypeaheadMatch } from "ngx-bootstrap/typeahead/typeahead-match.class";
import { ExportService } from '../../_services/export.service';
import { AlertifyService } from '../../_services/alertify.service';
import { Export } from '../../_models/export';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Router } from "@angular/router";
import { FormGroup } from "@angular/forms";
import { AuthService } from '../../_services/auth.service';

@Component({
  selector: "app-excess-water-home",
  templateUrl: "./excess-water-home.component.html",
  styleUrls: ["./excess-water-home.component.css"]
})
export class ExcessWaterHomeComponent implements OnInit {
  modalRef: BsModalRef;
  selectedValue: string;
  selectedOption: any;
  vesselName: any;
  BOLDate: any;
  lifter: any;
  terminalName: any;
  vesselArrived: any;
  exportID = 1;
  lifterImage: any;
  landingNetVolumeInBBLS: any;

  afraValue: number;
  wsValue: number;
  shipRate: number;

  exports: Export[];
  export: any;

  model: any = {};
  calculationAvailable = false;
  returnedDemurrageModel: any = {};
  hasExporRecord = false;
  createdActivated = false;
  laycanTime: Date = new Date();
  laycanDate: Date;

  constructor(
    private exportService: ExportService,
    private alertify: AlertifyService,
    private route: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.loadExports();
  }

  toggelCreatedActivated() {
    this.createdActivated = !this.createdActivated;
  }

  onSelect(event: TypeaheadMatch): void {
    this.selectedOption = event.item;
    this.export = event.item;
    this.exportID = this.selectedOption.id;
    this.vesselName = this.selectedOption.vessel.name;
    this.BOLDate = this.selectedOption.bolDate;
    this.lifter = this.selectedOption.lifters.name;
    this.terminalName = this.selectedOption.lifters.terminals.name;
    this.vesselArrived = this.selectedOption.eta;
    this.lifterImage = '../' + this.selectedOption.lifters.lifterPhotoUrl;
    this.landingNetVolumeInBBLS = this.selectedOption.actualCargo;
    this.hasExporRecord = true;
  }

  loadExports() {
    this.exportService.getAllExports().subscribe(
      (exports: Export[]) => {
        this.exports = exports;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }

  excessWaterModel: any = {};
  createdExcessWater: any = {};
  claimReceived: number;
  vesselExperienceFactor: number;
  freeWaterMeasuredOnArrival: number;
  terminalBsmw: number;
  sedimentWaterArrival: number;
  totalExcessFreeWater: number = 0;
  unitSalesValue: number;
  totalValueLoss: number = 0;
  saveInitialExcessWaterDetail() {
    if (this.isNullOrEmptyOrUndefined(this.totalExcessFreeWater)) {
      this.alertify.error('Error Calculate the Excess Water Volume (in barrels or metric tons) Can not be Zero.');
      return;
    }
    this.excessWaterModel.claimReceived = this.claimReceived;
    this.excessWaterModel.vesselExperienceFactor = this.vesselExperienceFactor;
    this.excessWaterModel.freeWaterMeasuredOnArrival = this.freeWaterMeasuredOnArrival;
    this.excessWaterModel.terminalBsmw = this.terminalBsmw;
    this.excessWaterModel.sedimentWaterArrival = this.sedimentWaterArrival;
    this.excessWaterModel.totalExcessFreeWater = this.totalExcessFreeWater;
    this.excessWaterModel.unitSalesValue=this.unitSalesValue;
    this.excessWaterModel.totalValueLoss=this.totalValueLoss;
    this.excessWaterModel.exportId = this.export.id;
    this.excessWaterModel.dateCreated = new Date();
    this.excessWaterModel.dateUpdated = new Date();
    console.log(this.excessWaterModel.exportId);

    this.exportService.createExcessWater(this.excessWaterModel)
      .subscribe(
        (excessWaterData: any) => {
          this.createdExcessWater = excessWaterData;
          this.alertify.success('Excess Water Data Successfully Added');
          this.createdActivated = false;
        },
        error => {
          this.alertify.error('Error Creating Excess Water Data');
        }
      );
  }


  //Restrict input to numbers and a single decimal points 
  decimalKeyPressValidations(event: KeyboardEvent) {
    const allowedKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.'];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
    //prevent more then one decimal point
    const inputElement = event.target as HTMLInputElement;
    if (event.key === '.' && inputElement.value.includes('.')) {
      event.preventDefault();
    }
  }

  decimalInputValidations(event: any, modelInputType: any) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    //check if the num has more than 3 decimal places
    const decimalMatch = value.match(/^\d+(\.\d{0,3})?/);
    if (decimalMatch) {
      switch (modelInputType) {
        case "ClaimReceived":
          inputElement.value = decimalMatch[0];
          this.claimReceived = parseFloat(inputElement.value);
          break;
        case "FreeWaterMeasuredOnArrival":
          inputElement.value = decimalMatch[0];
          this.freeWaterMeasuredOnArrival = parseFloat(inputElement.value);
          break;
        case "TerminalBsmw":
          inputElement.value = decimalMatch[0];
          this.terminalBsmw = parseFloat(inputElement.value);
          break;
        case "SedimentWaterArrival":
          inputElement.value = decimalMatch[0];
          this.sedimentWaterArrival = parseFloat(inputElement.value);
          break;
        case "TotalExcessFreeWater":
          inputElement.value = decimalMatch[0];
          this.totalExcessFreeWater = parseFloat(inputElement.value);
      }
    }
  }

  calculateExcessFreeWaterValue() {
    this.totalExcessFreeWater = 0;
    if (!this.isNullOrEmptyOrUndefined(this.freeWaterMeasuredOnArrival) || !this.isNullOrEmptyOrUndefined(this.terminalBsmw)
      || !this.isNullOrEmptyOrUndefined(this.sedimentWaterArrival)) {
      return this.totalExcessFreeWater = (((!this.isNullOrEmptyOrUndefined(this.terminalBsmw) ? (parseFloat(this.terminalBsmw.toString())) : 0)
        + (!this.isNullOrEmptyOrUndefined(this.sedimentWaterArrival) ? (parseFloat(this.sedimentWaterArrival.toString())) : 0))
        - (!this.isNullOrEmptyOrUndefined(this.freeWaterMeasuredOnArrival) ? (parseFloat(this.freeWaterMeasuredOnArrival.toString())) : 0));
    }
  }

  isNullOrEmptyOrUndefined(value: any) {
    if (value == null || value == '' || value == undefined || value == 0) {
      return true;
    } else {
      return false;
    }
  }

  calTotalExcessFreeWaterLossValue() {
    this.totalValueLoss = 0;
    if (!this.isNullOrEmptyOrUndefined(this.unitSalesValue) || !this.isNullOrEmptyOrUndefined(this.totalExcessFreeWater)) {
      return this.totalValueLoss = (((!this.isNullOrEmptyOrUndefined(this.unitSalesValue) ? (parseFloat(this.unitSalesValue.toString())) : 0)
        * (!this.isNullOrEmptyOrUndefined(this.totalExcessFreeWater) ? (parseFloat(this.totalExcessFreeWater.toString())) : 0)));
    }
  }
}
