<h5>Analyst</h5>
              <div class="row">
                <div class="col-sm-6">
                  <ul class="list-group">
                    <li class="list-group-item">Name : {{ analyst?.firstName }} {{ analyst?.middleName }} {{ analyst?.lastName | uppercase }}</li>
                    <li class="list-group-item">Email: {{ analyst?.email}}</li>
                    <li class="list-group-item">Phone:{{ analyst?.phoneNumber}}</li>
                    <li class="list-group-item">Extension: {{analyst?.mobile}}</li>
                    <li class="list-group-item">Manager: {{ analyst?.managerId}}</li>
                  </ul>
                </div>
                <div class="col-sm-6">
                  <div class="card" style="width: 18rem;">
                    <img class="card-img-top img-thumbnail" src="../../assets/user-male-icon.png" width="100" height="250" alt="Card image cap">
                    <div class="card-body"></div>
                      <h6 class="card-title text-center">Analyst : {{ analyst?.firstName }} {{ analyst?.middleName }} {{ analyst?.lastName | uppercase }}</h6>
                </div>
  </div>
</div>      
