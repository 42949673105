import { Component, OnInit, Input } from '@angular/core';
import { ExportService } from '../_services/export.service';
import { AlertifyService } from '../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportNode } from '../_models/exportnode';
import { Export } from '../_models/export';

@Component({
  selector: 'app-export-detail-action',
  templateUrl: './export-detail-action.component.html',
  styleUrls: ['./export-detail-action.component.css']
})
export class ExportDetailActionComponent implements OnInit {
  @Input() export: Export;
  nodes: Node[];
  exportNodes: ExportNode[];

  constructor(private exportService: ExportService, private alertify: AlertifyService,
     private route: ActivatedRoute, private nav: Router) {}

  ngOnInit() {
    this.loadNodes();
    this.loadExportNodes(this.export['id']);
  }

  loadNodes() {
    this.exportService.getNodes().subscribe((nodes: Node[]) => {
      this.nodes = nodes;
      }, error => {
        this.alertify.error(error);
      });
  }
  loadExportNodes(eId) {
    this.exportService.getExportNodes(eId).subscribe((exportnodes: ExportNode[]) => {
      this.exportNodes = exportnodes;
      }, error => {
        this.alertify.error(error);
      });
  }

}
