import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dash-issue-board',
  templateUrl: './dash-issue-board.component.html',
  styleUrls: ['./dash-issue-board.component.css']
})
export class DashIssueBoardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
