import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ExportService } from '../../_services/export.service';
import { AlertifyService } from '../../_services/alertify.service';
import { Router } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import { Lifter } from '../../_models/lifter';
import { Terminal } from '../../_models/terminal';
import { OML } from '../../_models/oml';
import { OMLEquity } from '../../_models/omlequity';

@Component({
  selector: "app-admin-financial-tab",
  templateUrl: "./admin-financial-tab.component.html",
  styleUrls: ["./admin-financial-tab.component.css"]
})
export class AdminFinancialTabComponent implements OnInit {

  allocationDate: Date;
  allocationPercent: number;
  terminals: Terminal[];
  lifters: Lifter[];
  OMLs: OML[];
  omlLifterEquities: OMLEquity[];
  lifterID: number;
  terminalID: number;
  hasOML = false;
  hasLifter = false;
  model: any = {};

  constructor(
    private modalService: BsModalService,
    private exportService: ExportService,
    private alertify: AlertifyService,
    private route: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.allocationPercent = 0.2345676767;
    this.loadTerminals();
  }
  loadTerminals() {
    this.exportService.getTerminals().subscribe(
      (terminals: Terminal[]) => {
        this.terminals = terminals;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadLifters(omlId) {
    this.exportService.getOMLLifters(omlId).subscribe(
      (lifters: Lifter[]) => {
        this.lifters = lifters;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadOMLLiftersByLifter(omlId, lifterId) {
    this.exportService
      .getOMLLiftersByLifter(omlId, lifterId)
      .subscribe(
        (omlLifterEquities: OMLEquity[]) => {
          this.omlLifterEquities = omlLifterEquities;
          // console.log(this.omlLifterEquities);
        },
        error => {
          this.alertify.error(error);
        }
      );
  }
  loadOMLs(id) {
    this.exportService.getTerminalOML(id).subscribe(
      (omls: OML[]) => {
        this.OMLs = omls;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  onSelectTerminal(e) {
    this.loadOMLs(e.id);
    this.hasOML = true;
    console.log(e);
  }
  onSelectOML(e) {
    console.log(e);
    this.model.omlId = e.id;
    this.loadLifters(e.id);
    console.log(this.model);
  }
  onSelectLifter(e) {
    this.hasLifter = true;
    this.loadOMLLiftersByLifter(this.model.omlId, e.id);
    this.model.lifterId = e.id;
    this.model.userId = this.authService.decodedToken.nameid;
    // console.log(this.model);
  }
  saveAllocation() {
    this.model.allocationPeriod = this.allocationDate;
    this.model.allocationPercent = this.allocationPercent;
    console.log(this.model);
    this.exportService.createOMLLifterAllocation(this.model).subscribe(
      () => {
        this.alertify.success('Allocation Successfully Created');
      },
      error => {
        this.alertify.error('Could not create allocation');
      }
    );
    this.omlLifterEquities.push(this.model);
    // this.loadOMLLiftersByLifter(this.model.omlId, this.model.lifterId);
  }
}
