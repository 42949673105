import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Export } from '../_models/export';
import { Terminal } from '../_models/terminal';
import { Product } from '../_models/product';
import { Lifter } from '../_models/lifter';
import { CargoType } from '../_models/cargotype';
import { Consignee } from '../_models/consignee';
import { Status } from '../_models/status';
import { DocumentCategory } from '../_models/documentcategory';
import { ExportNode } from '../_models/exportnode';
import { NodeComment } from '../_models/nodecomment';
import { PaginatedResult } from '../_models/pagination';
import { map } from 'rxjs/operators';
import { NodeStatus } from '../_models/nodestatus';
import { Vessel } from '../_models/Vessel';
import { Analyst } from '../_models/Analyst';
import { Destination } from '../_models/destination';
import { Inspector } from '../_models/inspector';
import { Demurrage } from '../_models/demurrage';
import { DemurrageCalculation } from '../_models/demurragecalculation';
import { DemurrageStatus } from '../_models/demurragestatus';
import { DocumentTemplateCategory } from '../_models/documenttemplatecategory';
import { DocumentTemplate } from '../_models/documenttemplate';
import { OML } from '../_models/oml';
import { OMLEquity } from '../_models/omlequity';
import { VesselAgent } from '../_models/vesselagent';
import { Tax } from '../_models/tax';
import { ExchangeRate } from '../_models/exchangerate';
import { Ness } from '../_models/ness';
import { Consignor } from '../_models/consignor';
import { User } from '../_models/user';
import { ExcessWater } from '../_models/excesswater';


@Injectable({
  providedIn: 'root'
})
export class ExportService {
  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  // EXPORT API
  getExports(page?, itemsPerPage?, vesselName?, terminalName?, lifterName?, productName?, startDate?, endDate?
    , statusId?, userId?, cargoNumber?
  ): Observable<PaginatedResult<Export[]>> {
    const paginatedResult: PaginatedResult<Export[]> = new PaginatedResult<Export[]>();

    let params = new HttpParams();

    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }
    if (vesselName != null) {
      params = params.append('vesselName', vesselName);
    }
    if (terminalName != null) {
      params = params.append('terminalName', terminalName);
    }
    if (lifterName != null) {
      params = params.append('lifterName', lifterName);
    }
    if (productName != null) {
      params = params.append('productName', productName);
    }
    if (startDate != null && endDate != null) {
      params = params.append('startDate', startDate);
      params = params.append('endDate', endDate);
    }
    if (statusId != null) {
      params = params.append('statusId', statusId);
    }
    if (userId != null) {
      params = params.append('usersId', userId);
    }
    if (cargoNumber != null) {
      params = params.append('cargoNumber', cargoNumber);
    }

    return this.http
      .get<Export[]>(this.baseUrl + 'exports', { observe: 'response', params })
      .pipe(
        map(response => {
          paginatedResult.result = response.body;
          if (response.headers.get('Pagination') != null) {
            paginatedResult.pagination = JSON.parse(
              response.headers.get('Pagination')
            );
          }
          return paginatedResult;
        })
      );
  }

  getInQueueExports(): Observable<Export[]> {
    return this.http.get<Export[]>(this.baseUrl + 'exports/active');
  }
  getAllExports(): Observable<Export[]> {
    return this.http.get<Export[]>(this.baseUrl + 'exports/all');
  }

  getExport(id): Observable<Export> {
    return this.http.get<Export>(this.baseUrl + 'exports/' + id);
  }
  getExportWithOpenNESS(page?, itemsPerPage?, vesselName?, terminalName?, lifterName?, productName?, startDate?, endDate?
  ): Observable<PaginatedResult<Export[]>> {
    const paginatedResult: PaginatedResult<Export[]> = new PaginatedResult<Export[]>();
    let params = new HttpParams();

    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }
    if (vesselName != null) {
      params = params.append('vesselName', vesselName);
    }
    if (terminalName != null) {
      params = params.append('terminalName', terminalName);
    }
    if (lifterName != null) {
      params = params.append('lifterName', lifterName);
    }
    if (productName != null) {
      params = params.append('productName', productName);
    }
    if (startDate != null && endDate != null) {
      params = params.append('startDate', startDate);
      params = params.append('endDate', endDate);
    }
    // if (cargoNumber != null) {
    //   params = params.append('cargoNumber', cargoNumber);
    // }

    // return this.http.get<Export[]>(this.baseUrl + 'exports/ness/open/');

    return this.http
      .get<Export[]>(this.baseUrl + 'exports/ness/open/', { observe: 'response', params })
      .pipe(
        map(response => {
          paginatedResult.result = response.body;
          if (response.headers.get('Pagination') != null) {
            paginatedResult.pagination = JSON.parse(
              response.headers.get('Pagination')
            );
          }
          return paginatedResult;
        })
      );
  }
  getExchangeRateForExport(bolDate: string): Observable<ExchangeRate> {
    return this.http.get<ExchangeRate>(this.baseUrl + 'exchangerate/start/' + encodeURIComponent(bolDate));
  }
  getExchangeRate(): Observable<ExchangeRate[]> {
    return this.http.get<ExchangeRate[]>(this.baseUrl + 'exchangerate/');
  }
  getOMLs(): Observable<OML[]> {
    return this.http.get<OML[]>(this.baseUrl + 'oml/');
  }
  getTerminalOML(id): Observable<OML[]> {
    return this.http.get<OML[]>(this.baseUrl + 'oml/' + id);
  }

  getTerminals(): Observable<Terminal[]> {
    return this.http.get<Terminal[]>(this.baseUrl + 'terminals/');
  }

  getProducts(): Observable<Product[]> {
    return this.http.get<Product[]>(this.baseUrl + 'products/');
  }
  getProductCombination(): Observable<Product[]> {
    return this.http.get<Product[]>(this.baseUrl + 'products/getProductCombinations');
  }
  getLifters(): Observable<Lifter[]> {
    return this.http.get<Lifter[]>(this.baseUrl + 'lifter/');
  }
  getLiftersForTerminal(id): Observable<Lifter[]> {
    return this.http.get<Lifter[]>(this.baseUrl + 'lifter/terminal/' + id);
  }
  getCargoTypeForLifer(id): Observable<CargoType[]> {
    return this.http.get<CargoType[]>(this.baseUrl + 'cargotype/lifter/' + id);
  }
  getOMLLifters(omlId): Observable<Lifter[]> {
    return this.http.get<Lifter[]>(this.baseUrl + 'lifter/oml/' + omlId);
  }
  getOMLLiftersByLifter(omlId, lifterId): Observable<OMLEquity[]> {
    return this.http.get<OMLEquity[]>(
      this.baseUrl + 'omlequity/oml/' + omlId + '/lifter/' + lifterId
    );
  }
  getCargoTypes(): Observable<CargoType[]> {
    return this.http.get<CargoType[]>(this.baseUrl + 'cargotype/');
  }
  getConsignee(): Observable<Consignee[]> {
    return this.http.get<Consignee[]>(this.baseUrl + 'consignee/');
  }
  getConsignor(): Observable<Consignor[]> {
    return this.http.get<Consignor[]>(this.baseUrl + 'consignor/');
  }
  getDestinations(): Observable<Destination[]> {
    return this.http.get<Destination[]>(this.baseUrl + 'destination/');
  }
  getInspectors(): Observable<Inspector[]> {
    return this.http.get<Inspector[]>(this.baseUrl + 'inspectors/');
  }
  getStatusList(): Observable<Status[]> {
    return this.http.get<Status[]>(this.baseUrl + 'status/');
  }
  getNodeStatusList(): Observable<NodeStatus[]> {
    return this.http.get<NodeStatus[]>(this.baseUrl + 'nodestatus/');
  }
  getDocumentCategoryList(): Observable<DocumentCategory[]> {
    return this.http.get<DocumentCategory[]>(
      this.baseUrl + 'DocumentCategory/'
    );
  }
  getNodes(): Observable<Node[]> {
    return this.http.get<Node[]>(this.baseUrl + 'Node/');
  }
  getVessels(): Observable<Vessel[]> {
    return this.http.get<Vessel[]>(this.baseUrl + 'vessel/');
  }
  getVesselAgents(): Observable<VesselAgent[]> {
    return this.http.get<VesselAgent[]>(this.baseUrl + 'vesselagent/');
  }

  getDemurrages(page?, itemsPerPage?, vesselName?, terminalName?, lifterName?, productName?, statusId?): Observable<PaginatedResult<Demurrage[]>> {
    const paginatedResult: PaginatedResult<Demurrage[]> = new PaginatedResult<Demurrage[]>();
    let params = new HttpParams();

    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }
    if (vesselName != null) {
      params = params.append('vesselName', vesselName);
    }
    if (terminalName != null) {
      params = params.append('terminalName', terminalName);
    }
    if (lifterName != null) {
      params = params.append('lifterName', lifterName);
    }
    if (productName != null) {
      params = params.append('productName', productName);
    }
    if (statusId != null) {
      params = params.append('statusId', statusId);
    }

    return this.http
      .get<Demurrage[]>(this.baseUrl + 'demurrage/open/claims/', { observe: 'response', params })
      .pipe(
        map(response => {
          paginatedResult.result = response.body;
          if (response.headers.get('Pagination') != null) {
            paginatedResult.pagination = JSON.parse(
              response.headers.get('Pagination')
            );
          }
          return paginatedResult;
        })
      );
  }

  getDemurrageStatus(): Observable<DemurrageStatus[]> {
    return this.http.get<DemurrageStatus[]>(this.baseUrl + 'DemurrageStatus/');
  }
  getExportNodes(id): Observable<ExportNode[]> {
    return this.http.get<ExportNode[]>(
      this.baseUrl + 'exportnode/' + id + '/export'
    );
  }
  getExportNode(exportid, nodeelementid): Observable<ExportNode> {
    return this.http.get<ExportNode>(
      this.baseUrl +
      'exportnode/' +
      exportid +
      '/export/' +
      nodeelementid +
      '/node'
    );
  }

  getNodeComment(id): Observable<NodeComment[]> {
    return this.http.get<NodeComment[]>(this.baseUrl + 'nodecomment/' + id);
  }
  getExportDocuments(id): Observable<Document[]> {
    return this.http.get<Document[]>(this.baseUrl + 'document/export/' + id);
  }
  getExportAnalyst(id): Observable<Analyst> {
    return this.http.get<Analyst>(this.baseUrl + 'userprofile/' + id);
  }
  getDestination(id): Observable<Destination> {
    return this.http.get<Destination>(this.baseUrl + 'destination/' + id);
  }
  getProductsForTerminal(id): Observable<Product[]> {
    return this.http.get<Product[]>(this.baseUrl + 'products/terminal/' + id);
  }
  getInspector(id): Observable<Inspector> {
    return this.http.get<Inspector>(this.baseUrl + 'inspectors/' + id);
  }
  getDemurrageCalculationsForExport(
    exportid
  ): Observable<DemurrageCalculation[]> {
    return this.http.get<DemurrageCalculation[]>(
      this.baseUrl + 'DemurrageCalculator/export/' + exportid
    );
  }
  getDocumentTemplateCategory(): Observable<DocumentTemplateCategory[]> {
    return this.http.get<DocumentTemplateCategory[]>(
      this.baseUrl + 'documenttemplatecategory'
    );
  }
  getDocumentTemplate(): Observable<DocumentTemplate[]> {
    return this.http.get<DocumentTemplate[]>(this.baseUrl + 'documenttemplate');
  }
  getNessTax(): Observable<Tax> {
    return this.http.get<Tax>(this.baseUrl + 'tax/1');
  }
  getTaxes(): Observable<Tax[]> {
    return this.http.get<Tax[]>(this.baseUrl + 'tax');
  }
  getNessPendingPayReq(): Observable<Ness[]> {
    return this.http.get<Ness[]>(this.baseUrl + 'tax/ness/payreq/');
  }
  getNessWithCompletedPayReq(): Observable<Ness[]> {
    return this.http.get<Ness[]>(this.baseUrl + 'tax/ness/completed/');
  }

  getAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + 'user');
  }
  
  getAllAdminUsers(page?, itemsPerPage?,userId?): Observable<PaginatedResult<User[]>> {
    const paginatedResult: PaginatedResult<User[]> = new PaginatedResult<User[]>();
    let params = new HttpParams();

    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }
    if (userId != null) {
      params = params.append('userId', userId);
    }

    return this.http
      .get<User[]>(this.baseUrl + 'user/List/', { observe: 'response', params })
      .pipe(
        map(response => {
          paginatedResult.result = response.body;
          if (response.headers.get('Pagination') != null) {
            paginatedResult.pagination = JSON.parse(
              response.headers.get('Pagination')
            );
          }
          return paginatedResult;
        })
      );
  }

  getFiltersForUser(id: number): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl + 'filters/user/' + id);
  }

  updateTaxRate(model: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'tax/update', model);
  }
  updateLifter(model: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'lifter/update', model);
  }
  updatePayReqForNessWith(id, payreq): Observable<Ness> {
    return this.http.get<Ness>(this.baseUrl + 'tax/ness/' + id + '/payreq/' + payreq);
  }
  updateFiscalPriceForExport(id, price): Observable<Export> {
    return this.http.get<Export>(this.baseUrl + 'exports/' + id + '/update/' + price + '/fiscal-price');
  }
  updateNXPForExport(id, nxpNumber): Observable<Export> {
    return this.http.get<Export>(this.baseUrl + 'exports/' + id + '/update/' + nxpNumber + '/nxp');
  }
  updateDateVolumeForExport(id, bolDate, volume): Observable<Export> {
    return this.http.get<Export>(this.baseUrl + 'exports/' + id + '/update/' + bolDate + '/date/' + volume + '/volume/');
  }
  /*
  POST SERVICES
  */
  createExport(model: any, id): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'exports/create/' + id + '/user/',
      model
    );
  }
  UpdateExportBOL(model: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'exports/update/bol',
      model
    );
  }
  createComment(model: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'nodecomment/create', model);
  }
  createTerminal(model: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'terminals', model);
  }
  createVessel(model: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'vessel', model);
  }
  createConsignee(model: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'consignee', model);
  }
  createConsignor(model: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'consignor', model);
  }
  createInspector(model: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'inspectors', model);
  }
  createDestination(model: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'destination', model);
  }
  createVesselAgent(model: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'vesselagent', model);
  }
  createExchangeRate(model: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'exchangerate', model);
  }
  createDocumentTemplateCategroy(model: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'documenttemplatecategory/create',
      model
    );
  }
  createDocumentTemplate(model: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'documenttemplate/create', model);
  }
  createLiftingProgramBatch(model: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'liftingprogram', model);
  }
  createDemmurageCulculation(model: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'DemurrageCalculator/calc',
      model
    );
  }
  createDemmurage(model: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'demurrage/', model);
  }
  processNess(model: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'tax/ness/process/',
      model
    );
  }
  createOMLLifterAllocation(model: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'omlequity/', model);
  }
  createFilter(model: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'filters/', model);
  }
  updateExport(id: number, model: any): Observable<any> {
    return this.http.put(this.baseUrl + 'exports/' + id, model);
  }
  // Http Service to Update the Status of an Export Node
  updateExportNodeStatus(model: any): Observable<any> {
    return this.http.put(this.baseUrl + 'exportnode/updatestatus', model);
  }

  updateUserProfile(model: any): Observable<any> {
    return this.http.post(this.baseUrl + 'userprofile/update', model);
  }
  updateDemurrageStatus(
    demurrageId: number,
    statusId: number,
    model: any
  ): Observable<any> {
    return this.http.put(
      this.baseUrl + 'demurrage/' + demurrageId + '/status/' + statusId,
      model
    );
  }

  duplicateExportWithID(id: number, userId: number): Observable<Export> {
    return this.http.get<Export>(this.baseUrl + 'exports/' + id + '/duplicate/user/' + userId);
  }
  deleteUser(id: number): Observable<any> {
    return this.http.delete<any>(this.baseUrl + 'user/' + id);
  }
  deleteExchangeRate(id: number): Observable<any> {
    return this.http.delete<any>(this.baseUrl + 'exchangeRate/' + id);
  }
  deleteExport(id: number): Observable<any> {
    const token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.http.delete<any>(this.baseUrl + 'exports/deleteExport/' + id, { headers });
  }

  createExcessWater(model: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'excesswater/', model);
  }

  deleteExcessWaterClaim(id: number): Observable<any> {
    return this.http.delete<any>(this.baseUrl + 'excesswater/' + id);
  }

  getExcessWaterDetail(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'excesswater/' + id);
  }

  updateExcessWater(id: number, model: any): Observable<any> {
    return this.http.put(this.baseUrl + 'excesswater/' + id, model);
  }

  getExcessWaterWithOpenClaims(page?, itemsPerPage?, vesselName?, terminalName?, lifterName?, productName?): Observable<PaginatedResult<ExcessWater[]>> {
    const paginatedResult: PaginatedResult<ExcessWater[]> = new PaginatedResult<ExcessWater[]>();
    let params = new HttpParams();

    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }
    if (vesselName != null) {
      params = params.append('vesselName', vesselName);
    }
    if (terminalName != null) {
      params = params.append('terminalName', terminalName);
    }
    if (lifterName != null) {
      params = params.append('lifterName', lifterName);
    }
    if (productName != null) {
      params = params.append('productName', productName);
    }

    return this.http
      .get<ExcessWater[]>(this.baseUrl + 'excesswater/open/claims/', { observe: 'response', params })
      .pipe(
        map(response => {
          paginatedResult.result = response.body;
          if (response.headers.get('Pagination') != null) {
            paginatedResult.pagination = JSON.parse(
              response.headers.get('Pagination')
            );
          }
          return paginatedResult;
        })
      );
  }

}
