import { Component, OnInit } from '@angular/core';
import { ExportService } from 'src/app/_services/export.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Ness } from 'src/app/_models/ness';

import * as jspdf from "jspdf";
import html2canvas from "html2canvas";

@Component({
  selector: 'app-completed-ness',
  templateUrl: './completed-ness.component.html',
  styleUrls: ['./completed-ness.component.css']
})
export class CompletedNessComponent implements OnInit {

  nessArray: Ness[];
  payReqNumber: number;
  nessId: number;
  totalNessValue: number;

  constructor(
    private exportService: ExportService,
    private alertify: AlertifyService,
    private authService: AuthService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.loadNessPendingPayReq();
  }

  loadNessPendingPayReq() {
    this.exportService.getNessWithCompletedPayReq().subscribe(
      (ness: Ness[]) => {
        this.nessArray = ness;
        this.calculateTotalNessValue(this.nessArray);
      },
      error => {
        this.alertify.error('Unable to retrieve ness details');
      }
    );
  }

  calculateTotalNessValue(arrayObj: Ness[]) {
    let accumulator = 0.00;
    for (let index = 0; index < arrayObj.length; index++) {
      accumulator = accumulator + arrayObj[index].nessPayable;
    }
    this.totalNessValue = accumulator;
  }

  public captureScreen() {
    const data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      const pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      const position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      pdf.save('MYPdf.pdf'); // Generated PDF
    });
  }

}
