import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.css']
})
export class AdminHomeComponent implements OnInit {

  constructor(  private authService: AuthService,
    private alertify: AlertifyService,
    private route: Router
  ) { 
    
  }

  ngOnInit() {

    var validate = this.authService.decodedToken.role === 'Admin' ? true : false;
    if(!validate){
      console.log("You are not authorized to view this page");
      this.alertify.error('You are not authorized to view this page');
      this.route.navigate(["/dash-board"]);
    }
  }

}
