import { Component, OnInit, Input } from '@angular/core';
import { Export } from '../_models/export';

@Component({
  selector: 'app-export-detail-financial',
  templateUrl: './export-detail-financial.component.html',
  styleUrls: ['./export-detail-financial.component.css']
})
export class ExportDetailFinancialComponent implements OnInit {

  @Input() export: Export;

  constructor() { }

  ngOnInit() {
    this.showData();
  }
  showData() {
    // console.log(this.export);
  }

}
