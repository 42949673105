import { Component, OnInit, TemplateRef } from '@angular/core';
import { ExportService } from '../_services/export.service';
import { AlertifyService } from '../_services/alertify.service';
import { Export } from '../_models/export';
import { PaginatedResult, Pagination } from '../_models/pagination';
import { Vessel } from '../_models/Vessel';
import { CargoType } from '../_models/cargotype';
import { Terminal } from '../_models/terminal';
import { Product } from '../_models/product';
import { Lifter } from '../_models/lifter';
import { DateService } from '../_services/date.service';
import { Status } from '../_models/status';
import { User } from '../_models/user';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { AuthService } from '../_services/auth.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-dash-board',
  templateUrl: './dash-board.component.html',
  styleUrls: ['./dash-board.component.css']
})
export class DashBoardComponent implements OnInit {
  exports: Export[];
  pagination: any = {};
  pageNumber = 1;
  pageSize = 24;
  customSelected: string;
  vesselNames: any[];
  enableLoader = true;
  terminals: Terminal[];
  products: Product[];
  lifters: Lifter[];
  cargotypes: CargoType[];
  model: any = {};
  terminalName: string;
  lifterName: string;
  productName: string;
  dateStart: any;
  dateEnd: any;
  dateStartToSend: any;
  dateEndToSend: any;
  userId: number;
  statusId: number;
  statusList: Status[];
  usersList: User[];
  selectedCargoNumber: any;

  filterModel: any = {};
  filterList: any[];
  modalRef: BsModalRef;
  public filterName: string;

  constructor(
    private exportService: ExportService,
    private alertify: AlertifyService,
    private dateService: DateService,
    private authService: AuthService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    // this.pageNumber = 1;
    // this.pageSize = 12;

    this.loadVessels();
    this.loadTerminals();
    this.loadLifters();
    this.loadProducts();
    this.loadStatus();
    this.loadUsers();
    this.loadFilterForUser();

    this.loadExportsFromCache(this.pageNumber, this.pageSize);
  }
  loadExportsFromCache(pn, ps) {
    const fitString = localStorage.getItem('current-filter');

    if (fitString) {
      console.log('fitter: ' + fitString);
      var currentFilter = JSON.parse(fitString);

      this.customSelected = currentFilter.vesselName;
      this.terminalName = currentFilter.terminalName;
      this.productName = currentFilter.productName;
      this.lifterName = currentFilter.lifterName;
      if (currentFilter.startDate !== undefined) {
        this.dateStartToSend = currentFilter.startDate;
      }

      if (currentFilter.endDate != undefined) {
        this.dateEndToSend = currentFilter.endDate;
      }
      if (currentFilter.usersId > 0) {
        this.userId = currentFilter.usersId;
      }
      if (currentFilter.statusId) {
        this.statusId = currentFilter.statusId;
      }
      if (currentFilter.cargoNumber != undefined) {
        this.selectedCargoNumber = currentFilter.cargoNumber;
      }

      var currentPage = this.pageNumber;
      var curPage = localStorage.getItem("current-filter-page");
      if (curPage) {
        currentPage = parseInt(curPage);
      }
      var currentPageSize = this.pageSize;
      var curPage = localStorage.getItem("current-filter-pageSize");
      if (curPage) {
        currentPageSize = parseInt(curPage);
      }

      this.pagination.currentPage = currentPage;
      this.pagination.itemsPerPage = currentPageSize;
      this.pagination.totalItems = 1000;
      this.reloadExports();
      this.enableLoader = false;
    }
    else {
      console.log('fitter: no cache');
      this.loadExports(pn, ps);
    }
  }
  loadExports(pn, ps) {
    this.exportService.getExports(pn, ps).subscribe(
      (exports: PaginatedResult<Export[]>) => {
        this.enableLoader = false;
        this.exports = exports.result;
        this.pagination = exports.pagination;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  reloadExports() {
    this.exportService
      .getExports(
        this.pagination.currentPage,
        this.pagination.itemsPerPage,
        this.customSelected,
        this.terminalName,
        this.lifterName,
        this.productName,
        this.dateStartToSend,
        this.dateEndToSend,
        this.statusId,
        this.userId,
        this.selectedCargoNumber
      )
      .subscribe(
        (res: PaginatedResult<Export[]>) => {
          this.exports = res.result;
          this.pagination = res.pagination;
        },
        error => {
          this.alertify.error(error);
        }
      );
  }

  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    localStorage.setItem("current-filter-page", this.pagination.currentPage);
    localStorage.setItem("current-filter-pageSize", this.pagination.itemsPerPage);
    this.reloadExports();
  }

  loadVessels() {
    this.exportService.getVessels().subscribe(
      (vessels: Vessel[]) => {
        this.vesselNames = vessels;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  onSelect() {
  }
  loadCargoTypes() {
    this.exportService.getCargoTypes().subscribe(
      (cargotypes: CargoType[]) => {
        this.cargotypes = cargotypes;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadTerminals() {
    this.exportService.getTerminals().subscribe(
      (terminals: Terminal[]) => {
        this.terminals = terminals;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadProducts() {
    this.exportService.getProducts().subscribe(
      (products: Product[]) => {
        this.products = products;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadLifters() {
    this.exportService.getLifters().subscribe(
      (lifters: Lifter[]) => {
        this.lifters = lifters;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadStatus() {
    this.exportService.getStatusList().subscribe(
      (statusList: Status[]) => {
        this.statusList = statusList;
      }, error => {
        this.alertify.error('Unable to load Status');
      }
    );
  }
  loadUsers() {
    this.exportService.getAllUsers().subscribe(
      (users: User[]) => {
        this.usersList = users;
      }, error => {
        this.alertify.error('Unable to Load Users');
      }
    );
  }
  loadFilterForUser() {
    this.exportService.getFiltersForUser(this.authService.decodedToken.nameid).subscribe(
      (filters: any[]) => {
        this.filterList = filters;
      }, error => {
        this.alertify.error('Unable to Load Filters');
      }
    );
  }

  runQuery() {
    this.pagination.currentPage = this.pageNumber;

    this.reloadExports();

    var currentFilter = this.getFilterModel();
    localStorage.setItem("current-filter", JSON.stringify(currentFilter));
    localStorage.setItem("current-filter-page", this.pagination.currentPage);
    localStorage.setItem("current-filter-pageSize", this.pagination.itemsPerPage);
  }

  clearFilter() {
    // this.ngOnInit();
    this.terminalName = null;
    this.productName = null;
    this.lifterName = null;
    this.customSelected = null;
    this.productName = null;
    this.dateStartToSend = null;
    this.dateEndToSend = null;
    this.statusId = null;
    this.userId = null;
    this.selectedCargoNumber = null;
    this.loadExports(this.pageNumber, this.pageSize);
  }

  onDateStartChange(value: Date): void {
    this.dateStartToSend = this.dateService.urlDate(value);
    const today = new Date(Date.now());
    this.dateEndToSend = this.dateService.urlDate(today);
  }
  onDateEndChange(value: Date): void {
    this.dateEndToSend = this.dateService.urlDate(value);
  }
  onSelectCargoNumber(event: TypeaheadMatch): void {
    this.selectedCargoNumber = event.item.cargoNumber;
  }

  getFilterModel() {
    var fitModel: any = {};
    fitModel.name = this.filterName;
    fitModel.vesselName = this.customSelected;
    fitModel.terminalName = this.terminalName;
    fitModel.productName = this.productName;
    fitModel.lifterName = this.lifterName;
    if (this.dateStartToSend != null) {
      fitModel.startDate = this.dateStartToSend;
    }
    if (this.dateEndToSend != null) {
      fitModel.endDate = this.dateEndToSend;
    }
    fitModel.usersId = this.userId;
    fitModel.statusId = this.statusId;
    if (this.selectedCargoNumber != null) {
      fitModel.cargoNumber = this.selectedCargoNumber;
    }
    return fitModel;
  }

  saveFilter() {
    this.filterModel = this.getFilterModel();
    this.filterModel.creatorId = this.authService.decodedToken.nameid;
    console.log(this.filterModel);

    this.exportService.createFilter(this.filterModel).subscribe(
      () => {
        this.alertify.success('Filter save');
        this.loadFilterForUser();
        this.modalRef.hide();
      }, error => {
        this.alertify.error('Error saving filter');
      }
    );
  }
  openFilterNameModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  loadSelectedFilter(filter: any) {
    this.terminalName = filter.terminalName;
    this.productName = filter.productName;
    this.lifterName = filter.lifterName;
    this.customSelected = filter.vesselName;
    this.productName = filter.productName;

    // due to being lazy, excluded setting dateStart and dateEnd while loading filter 
    //this.dateStartToSend = null;
    //this.dateEndToSend = null;

    if (filter.statusId > 0) {
      this.statusId = filter.statusId;
    }
    if (filter.userId > 0) {
      this.userId = filter.userId;
    }
    if (filter.cargoNumber != null) {
      this.selectedCargoNumber = filter.cargoNumber;
    }
    this.runQuery();
  }
}
