<div class="container mt-2">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dash-board']">Financial Home</a>
    </li>
  </ol>
  <ul class="nav nav-pills mt-3 mb-2">
    <li class="nav-item mr-2">
      <a class="nav-link active"><i class="fa fa-plus"></i>Process NESS</a>
    </li>
    <li class="nav-item mr-2">
      <a class="nav-link active" [routerLink]="['/ness-payreq']" routerLinkActive="router-link-active" ><i class="fa fa-plus"></i> Update PayReq #</a>
    </li>
    <li class="nav-item mr-2">
      <a class="nav-link active" [routerLink]="['/exchange-rate']" routerLinkActive="router-link-active" ><i class="fa fa-plus"></i> Enter Exchange Rate</a>
    </li>
    <li class="nav-item mr-2">
      <a class="nav-link active" [routerLink]="['/completed-ness']" routerLinkActive="router-link-active" ><i class="fa fa-plus"></i> Completed NESS</a>
    </li>
    <li class="nav-item mr-2">
        <a class="nav-link active" (click)="openModal(updateTaxModal)"><i class="fa fa-plus"></i> Taxes</a>
      </li>
  </ul>
  <app-ness *ngIf="nessMode"></app-ness>
  <app-exchange-rate *ngIf="exchangeRateMode && !nessMode"></app-exchange-rate>
</div>

<ng-template #updateTaxModal>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Add Consignee</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form #updateForm="ngForm">
      <div class="modal-body">
        <div class="form-row">
          <input type="text" class="form-control" placeholder="New Tax Rate" [(ngModel)]="taxForm.rate" name="rate" required>
        </div>
        <div class="form-row mt-2 mb-2">
          <select class="form-control" id="inputTerminal" name="id" [(ngModel)]="taxForm.id"
            required>
            <option *ngFor="let tax of taxArray" value="{{tax.id}}">{{tax.name}}</option>
          </select>
        </div>
        <button type="button" class="btn btn-outline-primary mt-2" [disabled]="!updateForm.valid" (click)="updateTax()">Save</button>
      </div>
    </form>
  </ng-template>
