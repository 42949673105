import { Component, OnInit } from '@angular/core';
import { EnergycomponentService } from 'src/app/_services/energycomponent.service';

@Component({
  selector: 'app-report-home',
  templateUrl: './report-home.component.html',
  styleUrls: ['./report-home.component.css']
})
export class ReportHomeComponent implements OnInit {

  constructor(private ecService: EnergycomponentService) { }

  ngOnInit() {
    this.loadECDataForExport(114);
  }
  loadECDataForExport(id: number) {
    this.ecService.getExportDataForID(id).subscribe(
      (ecExport: any) => {
        console.log(ecExport);
      }
    );
  }

}
