import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css',
    // "./../../assets/fav/apple-touch-icon-precomposed.png",
    "./../../assets/fonts/Gotham/gotham-fonts.css",
    "./../../assets/css/bootstrap-3.3.4.chevron.css",
    "./../../assets/css/rte-shared.css",
    "./../../assets/css/site.css",
    './../../assets/css/rte-shared.css',
    "./../../assets/css/modules/alert-shim.css",
    "./../../assets/css/modules/link-bar.css",
    "./../../assets/css/modules/navbar.css",
    "./../../assets/css/modules/footer.css",
    "./../../assets/css/modules/search-bar.css",
    "./../../assets/css/modules/side-nav.css",
    "./../../assets/css/modules/tabbed-content-control.css",
    "./../../assets/css/modules/table.css",
    "./../../assets/css/modules/tag.css",
    "./../../assets/css/modules/thumbnail-promo.css"
]
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
