import { Component, OnInit, Input } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { ExportService } from '../../_services/export.service';
import { AlertifyService } from '../../_services/alertify.service';
import { AuthService } from '../../_services/auth.service';
import { environment } from '../../../environments/environment';
import { Export } from '../../_models/export';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-excess-water-document-upload',
  templateUrl: './excess-water-document-upload.component.html',
  styleUrls: ['./excess-water-document-upload.component.css']
})
export class ExcessWaterDocumentUploadComponent implements OnInit {

  @Input() export: any;
  //  uploader: FileUploader = new FileUploader({ url: URL });
  uploader: FileUploader;
  hasBaseDropZoneOver = false;
  baseUrl = environment.apiUrl;
  id = 1 ;
  documentId = 15;
  userId = 1;
  @Input() exportId:number;
  constructor(
    private exportService: ExportService,
    private alertify: AlertifyService,
    public authService: AuthService,
    private nav:ActivatedRoute
  ) { }

  ngOnInit() {
    this.initializeUploader();
  }
  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  initializeUploader() {
    console.log(this.exportId);
    this.userId = this.authService.decodedToken.nameid;
    this.uploader = new FileUploader({
      url: this.baseUrl + 'document/' + this.exportId + '/upload/' + this.documentId + '/type/' + this.userId + '/user/',
      authToken: 'Bearer ' + localStorage.getItem('token'),
      isHTML5: true,
      allowedFileType: [
        'pdf',
        'image',
        'csv',
        '.docx',
        '.doc',
        '.ppt',
        '.xls',
        '.xlsx',
        'docx',
        'doc',
        'ppt',
        'xls',
        'xlsx'
      ],
      removeAfterUpload: true,
      autoUpload: false,
      maxFileSize: 10 * 1024 * 1024 // File Size
    });

    this.uploader.onAfterAddingFile = file => {
      file.withCredentials = false;
    };

    this.uploader.onCompleteItem = (item: any, response: any, status: any, header: any) => {
      this.alertify.success('File Upload Successful');
    };
  }

}
