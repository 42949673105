import { Component, OnInit } from '@angular/core';
import { Demurrage } from '../_models/demurrage';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ExportService } from '../_services/export.service';
import { AlertifyService } from '../_services/alertify.service';
import { Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { DocumentTemplate } from '../_models/documenttemplate';
import { DocumentCategory } from '../_models/documentcategory';

@Component({
  selector: 'app-document-home',
  templateUrl: './document-home.component.html',
  styleUrls: ['./document-home.component.css']
})
export class DocumentHomeComponent implements OnInit {
  isCollapsed = false;
  documentTemplate: DocumentTemplate[];


  constructor(
    private modalService: BsModalService,
    private exportService: ExportService,
    private alertify: AlertifyService,
    private route: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.loadDocumentTemplate();
  }
  loadDocumentTemplate(){
    this.exportService.getDocumentTemplate().subscribe(
      (documentTemplates: DocumentTemplate[]) => {
        this.documentTemplate = documentTemplates;
        console.log("DOC",this.documentTemplate);
      },
      error => {
        this.alertify.error('Unable to Retrieve Document Categories')
      }
    );
  }



}
