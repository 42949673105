import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../environments/environment';
import { FileUploader } from 'ng2-file-upload';
import { ExportService } from '../_services/export.service';
import { AlertifyService } from '../_services/alertify.service';
import { DocumentCategory } from '../_models/documentcategory';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: "app-document-upload",
  templateUrl: "./document-upload.component.html",
  styleUrls: ["./document-upload.component.css"]
})
export class DocumentUploadComponent implements OnInit {
  @Input() exportid: any;
  //  uploader: FileUploader = new FileUploader({ url: URL });
  uploader: FileUploader;
  hasBaseDropZoneOver = false;
  baseUrl = environment.apiUrl;
  id = 1 ;
  userId: number;

  documentCategoryList: DocumentCategory[];
  model: any = {};

  constructor(
    private exportService: ExportService,
    private alertify: AlertifyService,
    public authService: AuthService,
  ) {}

  ngOnInit() {
    this.loadDocumentCategory();
    this.initializeUploader();
    this.userId = this.authService.decodedToken.nameid;
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  initializeUploader() {
    this.uploader = new FileUploader({
      url: this.baseUrl + "document/" + this.exportid + "/upload/" + this.model.id + "/type/" + this.userId +"/user/",
      authToken: "Bearer " + localStorage.getItem("token"),
      isHTML5: true,
      allowedFileType: [
        "pdf",
        "image",
        "csv",
        ".docx",
        ".doc",
        ".ppt",
        ".xls",
        ".xlsx",
        "docx",
        "doc",
        "ppt",
        "xls",
        "xlsx"
      ],
      removeAfterUpload: true,
      autoUpload: false,
      maxFileSize: 10 * 1024 * 1024 // File Size
    });

    this.uploader.onAfterAddingFile = file => {
      file.withCredentials = false;
    };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, header: any) => {
      this.alertify.success('File Upload Successful');
    };
  }
  loadDocumentCategory() {
    this.exportService.getDocumentCategoryList().subscribe(
      (docCat: DocumentCategory[]) => {
        this.documentCategoryList = docCat;
        this.model = docCat;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  getDC() {
    this.initializeUploader();
  }
}
