import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { DocumentInstruction } from '../_models/documentinstruction';
import { Download } from '../_models/download';
import { PaginatedResult } from '../_models/pagination';
import { AlertifyService } from './alertify.service';
import { NominationDocument } from '../_models/nominationdocument';



@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  baseUrl = environment.apiUrl;
  // documentUrl = environment.apiUrlDocument;
  // urlForDocument = environment.apiDocumentUrl;

  constructor(private http: HttpClient, private alertify: AlertifyService) {}

  download(documentId: number) {
    return this.http.get(this.baseUrl + 'document/download/' + documentId,{
      responseType : 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  // Generate a StarDepp Nomination Letter
  // generateDocumentInstructionStardeep(id: number, model: DocumentInstruction): Observable<any> {
  //   return this.http.post<any>(this.documentUrl + 'generate/lifter/' + id, model);
  // }
  // generateNominationDocument(id: number, model: NominationDocument): Observable<any> {
  //   return this.http.post<any>(this.urlForDocument + 'nomination/generate/lifter/' + id, model);
  // }
  // generateDPRNominationDocument(id: number, model: NominationDocument): Observable<any> {
  //   return this.http.post<any>(this.urlForDocument + 'nomination/generate/dpr/lifter/' + id, model);
  // }
  // generateNNPCNominationDocument(id: number, model: NominationDocument): Observable<any> {
  //   return this.http.post<any>(this.urlForDocument + 'nomination/generate/nnpc/lifter/' + id, model);
  // }
  // generateRFIDocument(id: number, model: NominationDocument): Observable<any> {
  //   return this.http.post<any>(this.urlForDocument + 'nomination/generate/rfi/lifter/' + id, model);
  // }

  // Methods do get Files from Service as a blob
  generateDocumentInstructionStardeep(id: number, model: DocumentInstruction) {
    return this.http.post(this.baseUrl + 'nomination/documentInstruction/generate/lifter/' + id, model, {
      responseType : 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  generateNominationDocument(id: number, model: NominationDocument){
    return this.http.post(this.baseUrl + 'nomination/generate/lifter/' + id, model, {
      responseType : 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  generateDPRNominationDocument(id: number, model: NominationDocument){
    return this.http.post(this.baseUrl + 'nomination/generate/dpr/lifter/' + id, model, {
      responseType : 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  generateNNPCNominationDocument(id: number, model: NominationDocument){
    return this.http.post(this.baseUrl + 'nomination/generate/nnpc/lifter/' + id, model, {
      responseType : 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  generateRFIDocument(id: number, model: NominationDocument){
    return this.http.post(this.baseUrl + 'nomination/generate/rfi/lifter/' + id, model, {
      responseType : 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }

  downloadFileWithPath(model: Download) {
    return this.http.post(this.baseUrl + 'document/download/path/', model, {
      responseType : 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }

  getAllExportDocuments(page?, itemsPerPage?, terminalName?, documentCategoryName?
    , cargoNumber?, lIfterName?, startDate?, endDate?): Observable<PaginatedResult<Document[]>> {

    const paginatedResult: PaginatedResult<Document[]> = new PaginatedResult<Document[]>();

    let params = new HttpParams();

    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }

    if (terminalName != null) {
      params = params.append('terminalName', terminalName);
    }
    if (documentCategoryName != null) {
      params = params.append('documentCategoryName', documentCategoryName);
    }
    if (cargoNumber != null) {
      params = params.append('cargoNumber', cargoNumber);
    }
    if (lIfterName != null) {
      params = params.append('lIfterName', lIfterName);
    }
    if (startDate != null && endDate != null) {
      params = params.append('startDate', startDate);
      params = params.append('endDate', endDate);
    }

    return this.http.get<Document[]>(this.baseUrl + 'document/getall', { observe: 'response', params })
    .pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') != null) {
            paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return paginatedResult;
      })
    );
  }
}
